import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import LoginLayout from "layouts/LoginLayout";
import { Row, Col, Input, Card, Form, Button } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { handleApiSuccess, handleApiError } from "utils";
import { usersLoginService } from "services/user";

import Lottie from "react-lottie";
import * as animationData from "lottie/login.json";
import Auth from "auth";

const _auth = Auth;

function Login(props) {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const onFinish = (values) => {
    setIsLoading(true);
    usersLoginService(values.email, values.password)
      .then((response) => {
        _auth.authenticate(response.data.token, function () {
          setIsLoading(false);
          handleApiSuccess(response);
          history.push("/");
        });
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <LoginLayout>
      <Row>
        <Col xs={24} md={16} className="loginLeftside">
          {process.env.REACT_APP_THEME === "NETWORK_COMMANDER" ? (
            <img src="/network-commander-logo-light-v3.png" alt="Logo" />
          ) : (
            <Lottie options={defaultOptions} width={450} height={450} />
          )}
        </Col>
        <Col xs={24} md={8} className="loginRightside">
          <div>
            <Card className="ant-card">
              <div className="mainicon">
                <UserOutlined />
              </div>
              <h2>Account Login</h2>
              <Form
                className="login-form"
                name="loginForm"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input your login email!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    prefix={<UserOutlined style={{ fontSize: 13 }} />}
                    placeholder="Email ID"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input
                    size="large"
                    prefix={<LockOutlined style={{ fontSize: 13 }} />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    shape="round"
                    size="large"
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    loading={isLoading}
                    style={{ width: "100%" }}
                  >
                    Log in
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </LoginLayout>
  );
}

export default Login;
