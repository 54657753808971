import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import {
  Table,
  Space,
  Tooltip,
  Button,
  PageHeader,
  Input,
  Anchor,
  Badge,
} from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  TeamOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import {
  handleApiSuccess,
  handleApiError,
  MyBreadcrumb,
  accessControls,
  confirmDelete,
  msptrwStorage,
  axiosBase,
} from "utils";

import { debounce } from "lodash";

const { Search } = Input;
const { Link } = Anchor;

export default function List() {
  const axios = axiosBase.create();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [defaultPageNumber, setDefaultPageNumber] = useState(1);

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      className: "hide",
      /*
      sorter: function (a, b, sortOrder) {
        return a._id.localeCompare(b._id);
      },
      */
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      sorter: function (a, b, sortOrder) {
        return a.company.company_name.localeCompare(b.company.company_name);
      },
      render: (text, record) => record.company.company_name,
    },

    {
      title: "Branch Name",
      dataIndex: "branch_name",
      key: "branch_name",
      //defaultSortOrder: "ascend",
      sorter: function (a, b, sortOrder) {
        if (a.branch.branch_name) {
          return a.branch.branch_name.localeCompare(b.branch.branch_name);
        }
      },
      render: (text, record) =>
        record.branch ? record.branch.branch_name : null,
    },
    {
      title: "Service Provider",
      dataIndex: "service_provider",
      key: "service_provider",
      sorter: function (a, b, sortOrder) {
        return a.service_provider.localeCompare(b.service_provider);
      },
      render: (text, record) => text,
    },
    {
      title: "Equipment Location",
      dataIndex: "service_equipment_location",
      key: "service_equipment_location",
      sorter: function (a, b, sortOrder) {
        if (a.service_equipment_location.building_id) {
          return a.service_equipment_location.localeCompare(
            b.service_equipment_location
          );
        }
      },
      render: (text, record) => text,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: function (a, b, sortOrder) {
        return a.status.localeCompare(b.status);
      },
      render: (text, record) => {
        switch (text) {
          case "ACTIVE":
            return <Badge color="#52c41a" text="Active" />;
          case "INACTIVE_TERMINATED":
            return <Badge color="#ffec3d" text="Inactive/Terminated" />;
          case "TO_BE_RENEWED":
            return <Badge color="#ffec3d" text="To Be Renewed" />;
          case "TO_BE_CANCELED":
            return <Badge color="#ffec3d" text="To Be Cancelled" />;
          case "ACTIVE_PRIMARY":
            return <Badge color="#52c41a" text="Active Primary" />;
          case "ACTIVE_SECONDARY":
            return <Badge color="#52c41a" text="Active Secondary" />;
          default:
            return "NA";
        }
      },
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {accessControls.can(permissions, "UtilityProviders.read:any") && (
            <Tooltip key="view" title="View">
              <Button
                onClick={(e) => {
                  history.push("/service-providers/view/" + record._id);
                }}
                type="primary"
                shape="circle"
                icon={<EyeOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, "UtilityProviders.update:any") && (
            <Tooltip key="edit" title="Edit">
              <Button
                onClick={(e) => {
                  history.push("/service-providers/update/" + record._id);
                }}
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, "UtilityProviders.delete:any") && (
            <Tooltip key="disable" title="Disable">
              <Button
                onClick={(e) => deleteRecord(record._id)}
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    loadData();
    loadPermissions();
    msptrwStorage.get("serviceProviders.pageNumber").then((res) => {
      setDefaultPageNumber(res);
    });
  }, []);

  const loadPermissions = () => {
    //********START  ACCESS CONTROL******** */
    accessControls.fetch().then((permissions) => {
      console.log(permissions);
      setPermissions(permissions);
    });
    //********END ACCESS CONTROL******** */
  };

  const loadData = () => {
    setIsLoading(true);
    axios({
      method: "GET",
      url: "/service-provider",
    })
      .then((response) => {
        setIsLoading(false);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        } else {
          setDataSource([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const deleteRecord = (id) => {
    confirmDelete().then(() => {
      setIsLoading(true);
      axios({
        method: "DELETE",
        url: "/service-provider/" + id,
      })
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          loadData();
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    });
  };

  const searchRecord = (q) => {
    setIsLoading(true);
    axios({
      method: "GET",
      url: "/service-provider/search",
      params: { q },
    })
      .then((response) => {
        setIsLoading(false);
        handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        } else {
          setDataSource([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const delayedQuery = debounce((q) => searchRecord(q), 500);
  const onChange = (e) => {
    delayedQuery(e.target.value);
  };

  return (
    <div>
      <MyBreadcrumb items={[{ label: "Service Providers", path: "" }]} />
      <PageHeader
        ghost={false}
        avatar={{ icon: <TeamOutlined /> }}
        title="Service Providers"
        subTitle={
          <Search
            allowClear={true}
            onChange={onChange}
            loading={isLoading}
            placeholder="Filter search result"
            onSearch={(value) => searchRecord(value)}
            style={{ width: 200 }}
          />
        }
        extra={
          accessControls.can(permissions, "UtilityProviders.create:any")
            ? [
                <Button
                  shape="round"
                  onClick={(e) => history.push("/service-providers/create")}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Add New
                </Button>,
              ]
            : ""
        }
      ></PageHeader>
      <Table
        key={defaultPageNumber}
        rowKey="_id"
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
        pagination={{
          defaultCurrent: parseInt(defaultPageNumber),
          onChange: (pageNumber) => {
            msptrwStorage.set("serviceProviders.pageNumber", pageNumber);
          },
        }}
      />
    </div>
  );
}
