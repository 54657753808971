import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import {
  Table,
  Space,
  Tooltip,
  Button,
  PageHeader,
  Input,
  Modal,
  Select,
} from 'antd';

import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  UserAddOutlined,
  TeamOutlined,
  EyeOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons';

import {
  handleApiSuccess,
  handleApiError,
  MyBreadcrumb,
  accessControls,
  confirmDelete,
} from 'utils';
import {
  branchesListService,
  branchesDeleteService,
  branchesSearchService,
  branchesUpdateService,
} from 'services/branch';

import { debounce, set } from 'lodash';
import { usersListService } from 'services/user';

const { Search } = Input;

export default function BranchesList() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      key: '_id',
      //defaultSortOrder: "descend",
      className: 'hide',
      sorter: function (a, b, sortOrder) {
        return a._id.localeCompare(b._id);
      },
    },

    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      defaultSortOrder: 'ascend',
      render: (text, record) =>
        record?.company[0]
          ? record?.company[0]?.company_name
          : record?.company?.company_name,
      sorter: function (a, b, sortOrder) {
        if (a.company && b.company) {
          return a?.company[0]?.company_name.localeCompare(
            b?.company[0]?.company_name,
          );
        } else {
          return null;
        }
      },
    },
    {
      title: 'Branch',
      dataIndex: 'branch_name',
      key: 'branch_name',
      defaultSortOrder: 'ascend',
      sorter: function (a, b, sortOrder) {
        return a.branch_name.localeCompare(b.branch_name);
      },
    },
    {
      title: 'Building ID',
      dataIndex: 'building_id',
      key: 'building_id',
      sorter: function (a, b) {
        a = a.building_id || '';
        b = b.building_id || '';
        return a.localeCompare(b);
      },
    },
    {
      title: 'Market Name',
      dataIndex: 'market_name',
      key: 'market_name',
      sorter: function (a, b) {
        a = a.market_name || '';
        b = b.market_name || '';
        return a.localeCompare(b);
      },
    },

    {
      title: 'Address',
      dataIndex: 'physical_address',
      key: 'physical_address',
      render: (text, record) => {
        if (record.physical_map_url) {
          return (
            <a
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
              href={record.physical_map_url}
              title="View location map"
            >
              <EnvironmentOutlined /> {record.physical_address}
            </a>
          );
        }
      },
    },
    {
      title: 'City',
      dataIndex: 'physical_city',
      key: 'physical_city',
      sorter: function (a, b) {
        a = a.physical_city || '';
        b = b.physical_city || '';
        return a.localeCompare(b);
      },
    },
    {
      title: 'State',
      dataIndex: 'physical_state',
      key: 'physical_state',
      sorter: function (a, b) {
        a = a.physical_state || '';
        b = b.physical_state || '';
        return a.localeCompare(b);
      },
    },

    {
      title: 'Phone',
      dataIndex: 'main_phone_1',
      key: 'main_phone_1',
    },

    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {accessControls.can(permissions, 'Branch.read:any') && (
            <Tooltip key="view" title="View">
              <Button
                onClick={(e) => {
                  history.push('/branch/view/' + record._id);
                }}
                type="primary"
                shape="circle"
                icon={<EyeOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, 'Branch.update:any') && (
            <Tooltip title="Edit">
              <Button
                onClick={(e) => {
                  history.push('/branches/update/' + record._id);
                }}
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, 'Branch.delete:any') && (
            <Tooltip title="Disable">
              <Button
                onClick={(e) => deleteRecord(record._id)}
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, 'Branch.update:any') && (
            <Tooltip title="Assign Users">
              <Button
                onClick={() => assignUserButton(record)}
                shape="circle"
                icon={<UserAddOutlined />}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    loadData();
    loadPermissions();
  }, []);

  const loadPermissions = () => {
    //********START  ACCESS CONTROL******** */
    accessControls.fetch().then((permissions) => {
      setPermissions(permissions);
    });
    //********END ACCESS CONTROL******** */
  };

  const loadData = () => {
    setIsLoading(true);
    branchesListService()
      .then((response) => {
        setIsLoading(false);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });

    usersListService()
      .then((response) => {
        setUsers(response.data.items);
      })
      .catch((error) => {
        //handleApiError(error);
      });
  };

  const deleteRecord = (id) => {
    confirmDelete().then(() => {
      setIsLoading(true);
      branchesDeleteService(id)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          loadData();
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    });
  };

  const searchRecord = (q) => {
    setIsLoading(true);
    branchesSearchService(q)
      .then((response) => {
        setIsLoading(false);
        handleApiSuccess(response);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        } else {
          setDataSource([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setDataSource([]);
        handleApiError(error);
      });
  };

  const delayedQuery = debounce((q) => searchRecord(q), 500);
  const onChange = (e) => {
    delayedQuery(e.target.value);
  };

  const assignUserButton = (record) => {
    setSelectedUsers(record.users_acl);
    setSelectedBranch(record);
    setOpenModal(true);
    let _userOptions = [];
    users.map((user) => {
      if (
        user &&
        record &&
        user.company &&
        user.company._id ===
          (Array.isArray(record.company)
            ? record.company[0]._id
            : record.company._id)
      ) {
        _userOptions.push({
          label: user.first_name + ' ' + user.last_name,
          value: user._id,
        });
      }
    });
    setUserOptions(_userOptions);
  };

  const handleOk = () => {
    selectedBranch.company = selectedBranch.company[0]._id;
    selectedBranch.users_acl = selectedUsers;
    console.log(selectedBranch);
    branchesUpdateService(selectedBranch._id, selectedBranch)
      .then((response) => {
        handleApiSuccess(response);
        loadData();
      })
      .catch((error) => {
        handleApiError(error);
      });
    setSelectedUsers([]);
    setOpenModal(false);
  };

  return (
    <div>
      <Modal
        title="Assign Users"
        open={openModal}
        onOk={handleOk}
        onCancel={() => {
          setSelectedUsers([]);
          setOpenModal(false);
        }}
      >
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Please select"
          value={selectedUsers}
          onChange={(value) => {
            setSelectedUsers(value);
          }}
          options={userOptions}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        />
      </Modal>
      <MyBreadcrumb items={[{ label: 'Branches', path: '' }]} />
      <PageHeader
        ghost={false}
        avatar={{ icon: <TeamOutlined /> }}
        title="Branches"
        subTitle={
          <Search
            allowClear={true}
            onChange={onChange}
            loading={isLoading}
            placeholder="Filter search result"
            onSearch={(value) => searchRecord(value)}
            style={{ width: 200 }}
          />
        }
        extra={
          accessControls.can(permissions, 'Branch.create:any')
            ? [
                <Button
                  shape="round"
                  onClick={(e) => history.push('/branches/create')}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Add New
                </Button>,
              ]
            : ''
        }
      ></PageHeader>
      <Table dataSource={dataSource} columns={columns} loading={isLoading} />
    </div>
  );
}
