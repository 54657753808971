import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { Table, Space, Tooltip, Button, PageHeader, Tag } from "antd";

import { handleApiError, MyBreadcrumb } from "utils";
import { knowledgeBaseViewListServiceByCategoryId } from "services/knowledge_base";
import { categoriesViewListByIdService } from "services/category";
import { Helmet } from "react-helmet";

import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  ReadOutlined,
} from "@ant-design/icons";

import { handleApiSuccess, accessControls, confirmDelete } from "utils";
import { knowledgeBaseDeleteService } from "services/knowledge_base";

export default function KnowledgeBaseManage(props) {
  const history = useHistory();
  const { id } = props.match.params;
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [category, setCategory] = useState({ name: "" });
  const [tableColumns, setTableColumns] = useState([]);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    const columns = [
      {
        title: "Post Title",
        dataIndex: "title",
        key: "title",
        defaultSortOrder: "ascend",
        sorter: function (a, b, sortOrder) {
          return a.title.localeCompare(b.title);
        },
        render: (text, record) => {
          return (
            <Link to={"/knowledge-base/view/post-" + record._id}>
              <h4>{record.title}</h4>
            </Link>
          );
        },
      },
      {
        title: "ID",
        dataIndex: "_id",
        key: "_id",
        className: "hide",
        sorter: function (a, b, sortOrder) {
          return a._id.localeCompare(b._id);
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            {accessControls.can(permissions, "KBPost.update:any") && (
              <Tooltip title="Edit">
                <Button
                  size="small"
                  onClick={(e) => {
                    history.push("/knowledge-base/update/" + record._id);
                  }}
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                />
              </Tooltip>
            )}
            {accessControls.can(permissions, "KBPost.delete:any") && (
              <Tooltip title="Disable">
                <Button
                  size="small"
                  onClick={(e) => deleteRecord(record._id)}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            )}
          </Space>
        ),
      },
    ];
    setTableColumns(columns);
  }, [permissions]);

  useEffect(() => {
    loadPosts();
    loadCategory();
    loadPermissions();
  }, []);

  const loadPermissions = () => {
    //********START  ACCESS CONTROL******** */
    accessControls.fetch().then((permissions) => {
      console.log(permissions);
      setPermissions(permissions);
    });
    //********END ACCESS CONTROL******** */
  };

  const deleteRecord = (id) => {
    confirmDelete().then(() => {
      setIsLoading(true);
      knowledgeBaseDeleteService(id)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          loadPosts();
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    });
  };

  const loadPosts = () => {
    setIsLoading(true);
    knowledgeBaseViewListServiceByCategoryId(id)
      .then((response) => {
        setIsLoading(false);
        if (response.data.items && response.data.items.length > 0) {
          setPosts(response.data.items);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const loadCategory = () => {
    categoriesViewListByIdService(id)
      .then((response) => {
        if (response.data.items && response.data.items.length > 0) {
          setCategory(response.data.items[0]);
        }
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{category.name}</title>
      </Helmet>
      <MyBreadcrumb
        items={[
          { label: "Knowledge Base View", path: "/knowledge-base" },
          {
            label: category.parent ? category.parent.name : "",
            path: "/knowledge-base",
          },
          { label: category.name, path: "/knowledge-base" },
        ]}
      />
      <PageHeader
        ghost={false}
        avatar={{ icon: <ReadOutlined /> }}
        title="Knowledge Base"
        extra={
          accessControls.can(permissions, "KBPost.create:any")
            ? [
                <Button
                  key="create"
                  shape="round"
                  onClick={(e) => history.push("/knowledge-base/create")}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Add New
                </Button>,
              ]
            : ""
        }
      ></PageHeader>
      <Table
        dataSource={posts}
        columns={tableColumns}
        loading={isLoading}
        rowKey="_id"
      />
    </div>
  );
}
