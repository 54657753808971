import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Space,
  Button,
  Form,
  Input,
  Divider,
} from 'antd';

import { handleApiSuccess, handleApiError } from 'utils';

import InputMask from 'react-input-mask';

import {
  LockOutlined,
  MobileOutlined,
  PhoneOutlined,
} from '@ant-design/icons';

import {
  createSalesPersonService,
  salesPersonListByIdService,
  updateSalesPersonService
} from 'services/sales_person';

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 8 },
};
const tailLayout = {
  wrapperCol: { offset: 3, span: 8 },
};

export default function SalesForm(props) {
  const { id } = props;
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();
  const firstInput = useRef(null);

  useEffect(() => {
    firstInput.current.focus();
  }, []);

  useEffect(() => {
    // IF id exist the initialize form data
    if (id) {
      salesPersonListByIdService(id)
        .then((response) => {
          console.log(response)
          setIsLoading(false);
          if (response.data.items && response.data.items.length > 0) {
            form.setFieldsValue(response.data.items[0]);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  }, [id, form]);

  const onFinish = (values) => {
    setIsLoading(true);

    // Update
    if (id) {
      updateSalesPersonService(id, values)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          history.push('/sales');
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    } else {
      // Create
      createSalesPersonService(values)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          history.push('/sales');
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  };

  const CellPhoneInput = (props) => (
    <InputMask
      mask="999-999-9999"
      value={props.value}
      onChange={props.onChange}
    >
      {(inputProps) => (
        <Input
          {...inputProps}
          style={{ width: '50%' }}
          prefix={<MobileOutlined />}
        />
      )}
    </InputMask>
  );

  const PhoneInput = (props) => (
    <InputMask
      mask="999-999-9999"
      value={props.value}
      onChange={props.onChange}
    >
      {(inputProps) => <Input {...inputProps} prefix={<PhoneOutlined />} />}
    </InputMask>
  );

  return (
    <Form name="myForm" form={form} {...layout} onFinish={onFinish}>
      <div>
        <Divider orientation="left">
          <LockOutlined /> Sales Person Information
        </Divider>

        <Space>&nbsp;</Space>

        <Form.Item
          label="First Name"
          name="first_name"
          rules={[{ required: true, message: 'Please input first name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Last Name"
          name="last_name"
          rules={[{ required: true, message: 'Please input last name' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Please input email address' },
            { type: 'email', message: 'Please input valid email address' },
          ]}
        >
          <Input ref={firstInput} />
        </Form.Item>

        <Form.Item label="Note" name="note">
          <Input.TextArea />
        </Form.Item>
      </div>

      <Space>&nbsp;</Space>
      <Space>&nbsp;</Space>

      <Divider orientation="left">
        <MobileOutlined /> Contact Details
      </Divider>
      <Space>&nbsp;</Space>

      <Form.Item label="Cell Phone" extra="Cell Phone number">
        <Form.Item name={['cell_phone']} noStyle>
          <CellPhoneInput placeholder="Phone" />
        </Form.Item>
      </Form.Item>

      <Form.Item label="Office Phone" name="office_phone">
        <PhoneInput />
      </Form.Item>

      <Form.Item label="Ext" name="ext">
        <Input />
      </Form.Item>

      <Form.Item label="Fax" name="fax">
        <PhoneInput />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" loading={isLoading} htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}