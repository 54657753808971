import React from "react";
import { PageHeader } from "antd";
import { MyBreadcrumb } from "utils";
import IspProviderForm from "./form";
export default function IspProviderCreate() {
  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: "ISP Vendor", path: "/manage-isp-provider" },
          { label: "Create", path: "" },
        ]}
      />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Create"
        subTitle=""
      >
        <IspProviderForm />
      </PageHeader>
    </div>
  );
}
