import React, { useState, useEffect } from "react";
import { Form, Select } from "antd";
import { handleApiError } from "utils";
import { companiesListService } from "services/company";
import { branchesListByCompanyIdService } from "services/branch";
const { Option } = Select;
export default function CompanyBranchDropdownForm({ company }) {
  const [isCompanyLoading, setIsCompanyLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    //Load companies dropdown
    setIsCompanyLoading(true);
    companiesListService()
      .then((response) => {
        if (response.data.items && response.data.items.length > 0) {
          setCompanies(response.data.items);
        }
        setIsCompanyLoading(false);
      })
      .catch((err) => {
        setIsCompanyLoading(false);
      });
    if (company) {
      branchesListByCompanyIdService(company)
        .then((response) => {
          setBranches(response.data.items);
        })
        .catch((error) => {
          handleApiError(error);
        });
    }
  }, [company]);

  const onCompanyChange = (value) => {
    branchesListByCompanyIdService(value)
      .then((response) => {
        setBranches(response.data.items);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };
  return (
    <div
      style={{
        backgroundColor: "#FAFAFA",
        border: "1px solid #EAEAEA",
        padding: "30px 5px 5px 5px",
        marginBottom: 30,
      }}
    >
      {companies.length > 0 && (
        <Form.Item
          label="Company"
          name="company"
          rules={[{ required: true, message: "Please select Company" }]}
        >
          <Select
            autoComplete="nope"
            showSearch
            placeholder="Select a company"
            optionFilterProp="children"
            onChange={(value) => onCompanyChange(value)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="">Select a company</Option>
            {companies.map((item, i) => (
              <Option value={item._id}>{item.company_name}</Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {companies.length === 0 && !isCompanyLoading && (
        <Form.Item label="Company" name="company">
          <div style={{ background: "yellow", padding: "2px" }}>
            Please contact admin to allow you company level permission
          </div>
        </Form.Item>
      )}

      <Form.Item
        label="Branch"
        name="branch"
        rules={[{ required: true, message: "Please select Company & Branch" }]}
      >
        <Select
          autoComplete="nope"
          showSearch
          placeholder="Select a branch"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value="">Select a branch</Option>
          {branches.map((item, i) => (
            <Option value={item._id}>{item.branch_name}</Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
}
