import { axiosBase } from "utils";

export function galleryListService() {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/gallery",
  });
}

export function galleryViewByIdService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/gallery/" + id,
  });
}

export function photoListByGalleryIdService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/gallery/" + id + "/photos",
  });
}

export function photoDeleteByIdService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "DELETE",
    url: "/gallery/photo/" + id,
  });
}

export function photoUpdateByIdService(id, values) {
  const axios = axiosBase.create();
  return axios({
    method: "POST",
    url: "/gallery/photo/" + id,
    data: {
      title: values.title,
      description: values.description,
      priority: values.priority || 0,
    },
  });
}

export function galleryCreateService(values) {
  const axios = axiosBase.create();
  return axios({
    method: "POST",
    url: "/gallery",
    data: {
      company: values.company,
      branch: values.branch,
      title: values.title,
      description: values.description,
    },
  });
}

export function galleryUpdateService(id, values) {
  const axios = axiosBase.create();
  return axios({
    method: "PATCH",
    url: "/gallery/" + id,
    data: {
      company: values.company,
      branch: values.branch,
      title: values.title,
      description: values.description,
    },
  });
}

export function galleryDeleteService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "DELETE",
    url: "/gallery/" + id,
  });
}
