import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Space, Button, Form, Input, Divider, Select, Checkbox } from "antd";
import {
  companiesCreateService,
  companiesListByIdService,
  companiesUpdateService,
} from "services/company";
import { cellphoneCarriersService, statesService } from "services/lookup";
import { usersListService } from "services/user";
import { handleApiSuccess, handleApiError } from "utils";

import InputMask from "react-input-mask";

import {
  LockOutlined,
  ShopOutlined,
  MobileOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 7 },
};
const tailLayout = {
  wrapperCol: { offset: 5, span: 7 },
};

const { Option } = Select;

export default function CompaniesForm(props) {
  const { id } = props;

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();

  const [carriers, setCarriers] = useState([]);
  const [states, setStates] = useState([]);
  const [users, setUsers] = useState([]);

  const [
    shippingAddressSameAsPhysicalAddress,
    setShippingAddressSameAsPhysicalAddress,
  ] = useState(false);
  const [
    billingAddressSameAsShippingAddress,
    setBillingAddressSameAsShippingAddress,
  ] = useState(false);

  useEffect(() => {
    // IF id exist the initialize form data
    if (id) {
      companiesListByIdService(id)
        .then((response) => {
          setIsLoading(false);
          if (response.data.items && response.data.items.length > 0) {
            form.setFieldsValue(response.data.items[0]);

            if (
              response.data.items[0]
                .shipping_address_same_as_physical_address === "YES"
            ) {
              setShippingAddressSameAsPhysicalAddress(true);
            } else {
              setShippingAddressSameAsPhysicalAddress(false);
            }

            if (
              response.data.items[0]
                .billing_address_same_as_shipping_address === "YES"
            ) {
              setBillingAddressSameAsShippingAddress(true);
            } else {
              setBillingAddressSameAsShippingAddress(false);
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }

    // Load celphone carriers dropdown
    cellphoneCarriersService()
      .then((response) => {
        setIsLoading(false);
        if (response.data.items && response.data.items.length > 0) {
          setCarriers(response.data.items);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });

    // Load states dropdown
    statesService()
      .then((response) => {
        setIsLoading(false);
        if (response.data.items && response.data.items.length > 0) {
          setStates(response.data.items);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });

    // Load users dropdown
    usersListService()
      .then((response) => {
        setIsLoading(false);
        var usersList = [];
        if (response.data.items && response.data.items.length > 0) {
          response.data.items.map((item) => {
            usersList.push({
              label: item.display_name,
              value: item._id,
            });
          });
        }
        setUsers(usersList);
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  }, [id, form]);

  const onFinish = (values) => {
    setIsLoading(true);

    if (shippingAddressSameAsPhysicalAddress) {
      values.shipping_address_same_as_physical_address = "YES";
    } else {
      values.shipping_address_same_as_physical_address = "NO";
    }

    if (billingAddressSameAsShippingAddress) {
      values.billing_address_same_as_shipping_address = "YES";
    } else {
      values.billing_address_same_as_shipping_address = "NO";
    }

    // Update
    if (id) {
      companiesUpdateService(id, values)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          history.push("/companies");
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    } else {
      // Create
      companiesCreateService(values)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          history.push("/companies");
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  };

  const CellPhoneInput = (props) => (
    <InputMask
      mask="999-999-9999"
      maskChar="x"
      value={props.value}
      onChange={props.onChange}
    >
      {(inputProps) => (
        <Input
          {...inputProps}
          style={{ width: "50%" }}
          prefix={<MobileOutlined />}
        />
      )}
    </InputMask>
  );

  const LandlineInput = (props) => (
    <InputMask
      mask="999-999-9999"
      maskChar="x"
      value={props.value}
      onChange={props.onChange}
    >
      {(inputProps) => (
        <Input
          {...inputProps}
          style={{ width: "70%" }}
          prefix={<PhoneOutlined />}
        />
      )}
    </InputMask>
  );

  var i = 0;

  return (
    <Form name="myForm" form={form} {...layout} onFinish={onFinish}>
      <Divider orientation="left">
        <LockOutlined /> Company Profile
      </Divider>

      <Space>&nbsp;</Space>

      <Form.Item
        label="Company Name"
        name="company_name"
        rules={[{ required: true, message: "Please input company name" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Account Number" name="account_number">
        <Input />
      </Form.Item>

      <Form.Item label="Sub Account Number" name="sub_account_number">
        <Input />
      </Form.Item>

      <Divider orientation="left">
        <UserOutlined /> Contact Person
      </Divider>

      <Form.Item label="Main Contact person" name="main_contact">
        <Select
          onChange={(value) => form.setFieldsValue({ main_contact: value })}
        >
          {users.map((item, i) => {
            return (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item label="Billing contact person" name="billing_contact">
        <Select
          onChange={(value) => form.setFieldsValue({ billing_contact: value })}
        >
          {users.map((item, i) => {
            return (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        label="Purchase approval contact"
        name="purchase_approval_contact"
      >
        <Select
          onChange={(value) =>
            form.setFieldsValue({ purchase_approval_contact: value })
          }
        >
          {users.map((item, i) => {
            return (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Divider orientation="left">
        <ShopOutlined /> Physical Address
      </Divider>
      <Space>&nbsp;</Space>
      <Form.Item label="Address" name="physical_address">
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="Suite/Apt" name="physical_suite_apt">
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="City" name="physical_city">
        <Input />
      </Form.Item>

      <Form.Item label="State" name="physical_state">
        <Select
          onChange={(value) => form.setFieldsValue({ physical_state: value })}
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {states.map((item, i) => {
            return (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item label="Zip" name="physical_zip">
        <Input />
      </Form.Item>

      <Form.Item label="Map URL" name="physical_map_url">
        <Input.TextArea />
      </Form.Item>

      <Divider orientation="left">
        <ShopOutlined /> Shipping Address
      </Divider>
      <Space>&nbsp;</Space>

      <Form.Item
        {...tailLayout}
        name="shipping_address_same_as_physical_address"
      >
        <Checkbox
          checked={shippingAddressSameAsPhysicalAddress}
          onChange={(e) =>
            e.target.checked
              ? setShippingAddressSameAsPhysicalAddress(true)
              : setShippingAddressSameAsPhysicalAddress(false)
          }
        >
          Same as Physical Address
        </Checkbox>
      </Form.Item>

      {!shippingAddressSameAsPhysicalAddress && (
        <div>
          <Form.Item label="Address" name="shipping_address">
            <Input.TextArea />
          </Form.Item>

          <Form.Item label="Suite/Apt" name="shipping_suite_apt">
            <Input.TextArea />
          </Form.Item>

          <Form.Item label="City" name="shipping_city">
            <Input />
          </Form.Item>

          <Form.Item label="State" name="shipping_state">
            <Select
              onChange={(value) =>
                form.setFieldsValue({ shipping_state: value })
              }
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {states.map((item, i) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item label="Zip" name="shipping_zip">
            <Input />
          </Form.Item>

          <Form.Item label="Map URL" name="shipping_map_url">
            <Input.TextArea />
          </Form.Item>
        </div>
      )}

      <Divider orientation="left">
        <ShopOutlined /> Billing Address
      </Divider>
      <Space>&nbsp;</Space>

      <Form.Item
        {...tailLayout}
        name="billing_address_same_as_shipping_address"
      >
        <Checkbox
          checked={billingAddressSameAsShippingAddress}
          onChange={(e) =>
            e.target.checked
              ? setBillingAddressSameAsShippingAddress(true)
              : setBillingAddressSameAsShippingAddress(false)
          }
        >
          Same as Shipping Address
        </Checkbox>
      </Form.Item>

      {!billingAddressSameAsShippingAddress && (
        <div>
          <Form.Item label="Address" name="billing_address">
            <Input.TextArea />
          </Form.Item>

          <Form.Item label="Suite/Apt" name="billing_suite_apt">
            <Input.TextArea />
          </Form.Item>

          <Form.Item label="City" name="billing_city">
            <Input />
          </Form.Item>

          <Form.Item label="State" name="billing_state">
            <Select
              onChange={(value) =>
                form.setFieldsValue({ billing_state: value })
              }
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {states.map((item, i) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item label="Zip" name="billing_zip">
            <Input />
          </Form.Item>

          <Form.Item label="Map URL" name="billing_map_url">
            <Input.TextArea />
          </Form.Item>
        </div>
      )}

      <Divider orientation="left">
        <ShopOutlined /> Notes
      </Divider>
      <Space>&nbsp;</Space>

      <Form.Item label="Notes" name="notes">
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="Website" name="website">
        <Input.TextArea />
      </Form.Item>

      <Divider orientation="left">
        <MobileOutlined /> Contact settings
      </Divider>
      <Space>&nbsp;</Space>

      <Form.Item label="Main Phone 1" extra="Phone Number and Extension (opt.)">
        <Input.Group compact>
          <Form.Item name={["main_phone_1"]} noStyle>
            <LandlineInput placeholder="Phone" style={{ width: "70%" }} />
          </Form.Item>
          <Form.Item name={["main_phone_1_ext"]} noStyle>
            <Input style={{ width: "30%" }} placeholder="Ext" />
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item label="Main Phone 2" extra="Phone Number and Extension (opt.)">
        <Input.Group compact>
          <Form.Item name={["main_phone_2"]} noStyle>
            <LandlineInput placeholder="Phone" style={{ width: "70%" }} />
          </Form.Item>
          <Form.Item name={["main_phone_2_ext"]} noStyle>
            <Input style={{ width: "30%" }} placeholder="Ext" />
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item label="Main Phone 3" extra="Phone Number and Extension (opt.)">
        <Input.Group compact>
          <Form.Item name={["main_phone_3"]} noStyle>
            <LandlineInput placeholder="Phone" style={{ width: "70%" }} />
          </Form.Item>
          <Form.Item name={["main_phone_3_ext"]} noStyle>
            <Input style={{ width: "30%" }} placeholder="Ext" />
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item label="Main Phone 4" extra="Phone Number and Extension (opt.)">
        <Input.Group compact>
          <Form.Item name={["main_phone_4"]} noStyle>
            <LandlineInput placeholder="Phone" style={{ width: "70%" }} />
          </Form.Item>
          <Form.Item name={["main_phone_4_ext"]} noStyle>
            <Input style={{ width: "30%" }} placeholder="Ext" />
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item label="Main Phone 5" extra="Phone Number and Extension (opt.)">
        <Input.Group compact>
          <Form.Item name={["main_phone_5"]} noStyle>
            <LandlineInput placeholder="Phone" style={{ width: "70%" }} />
          </Form.Item>
          <Form.Item name={["main_phone_5_ext"]} noStyle>
            <Input style={{ width: "30%" }} placeholder="Ext" />
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item label="Cell Phone 1" extra="Cell Phone number and carrier">
        <Input.Group compact>
          <Form.Item name={["cell_phone_1"]} noStyle>
            <CellPhoneInput placeholder="Phone" />
          </Form.Item>
          <Form.Item name={["cell_phone_1_carrier"]} noStyle>
            <Select
              placeholder="Select carrier"
              style={{ width: "50%" }}
              onChange={(value) =>
                form.setFieldsValue({ cell_phone_1_carrier: value })
              }
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">Select carrier</Option>
              {carriers.map((item, i) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item label="Cell Phone 2" extra="Cell Phone number and carrier">
        <Input.Group compact>
          <Form.Item name={["cell_phone_2"]} noStyle>
            <CellPhoneInput placeholder="Phone" />
          </Form.Item>
          <Form.Item name={["cell_phone_2_carrier"]} noStyle>
            <Select
              placeholder="Select carrier"
              style={{ width: "50%" }}
              onChange={(value) =>
                form.setFieldsValue({ cell_phone_2_carrier: value })
              }
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">Select carrier</Option>
              {carriers.map((item, i) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item label="Cell Phone 3" extra="Cell Phone number and carrier">
        <Input.Group compact>
          <Form.Item name={["cell_phone_3"]} noStyle>
            <CellPhoneInput placeholder="Phone" />
          </Form.Item>
          <Form.Item name={["cell_phone_3_carrier"]} noStyle>
            <Select
              placeholder="Select carrier"
              style={{ width: "50%" }}
              onChange={(value) =>
                form.setFieldsValue({ cell_phone_3_carrier: value })
              }
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">Select carrier</Option>
              {carriers.map((item, i) => {
                return (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Input.Group>
      </Form.Item>

      <Form.Item label="Fax 1" name="fax_1">
        <LandlineInput />
      </Form.Item>

      <Form.Item label="Fax 2" name="fax_2">
        <LandlineInput />
      </Form.Item>

      <Form.Item label="Fax 3" name="fax_3">
        <LandlineInput />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" loading={isLoading} htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
