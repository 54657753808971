import React from "react";

import { Table, PageHeader, Tooltip, Button, Typography } from "antd";
import Swal from "sweetalert2";
import { MyBreadcrumb } from "utils";
import ExpectScripts from "utils/ExpectScripts";

import { CaretRightOutlined } from "@ant-design/icons";
import Auth from "auth";
const _auth = Auth;
export default function Index() {
  /*
          <Option value="">Select a QoS</Option>
          <Option value="Cisco-Switch.json">Cisco-Switch.json</Option>
          <Option value="CiscoASA5500.json">CiscoASA5500.json</Option>
          <Option value="DellPowerConnect3500.json">
            DellPowerConnect3500.json
          </Option>
          <Option value="DellPowerConnect6200.json">
            DellPowerConnect6200.json
          </Option>
          <Option value="DellPowerConnect6200Peachtree.json">
            DellPowerConnect6200Peachtree.json
          </Option>
          <Option value="FS.comSwitch.json">FS.comSwitch.json</Option>
          <Option value="UbiquitiEdgeRouter.json">
            UbiquitiEdgeRouter.json
          </Option>
          <Option value="UbiquitiEdgeRouterPeachtree.json">
            UbiquitiEdgeRouterPeachtree.json
          </Option>
          <Option value="UbiquitiEdgeSwitch.json">
            UbiquitiEdgeSwitch.json
          </Option>
          <Option value="UbiquitiEdgeSwitchVoiceVLAN.json">
            UbiquitiEdgeSwitchVoiceVLAN.json
          </Option>
          */

  const dataSource = [
    {
      key: "1",
      title: "Cisco-Switch.json",
    },
    {
      key: "2",
      title: "CiscoASA5500.json",
    },
    {
      key: "3",
      title: "DellPowerConnect3500.json",
    },
    {
      key: "4",
      title: "DellPowerConnect6200.json",
    },
    {
      key: "5",
      title: "DellPowerConnect6200Peachtree.json",
    },
    {
      key: "6",
      title: "FS.comSwitch.json",
    },
    {
      key: "7",
      title: "UbiquitiEdgeRouter.json",
    },
    {
      key: "8",
      title: "UbiquitiEdgeRouterPeachtree.json",
    },
    {
      key: "9",
      title: "UbiquitiEdgeSwitch.json",
    },
    {
      key: "10",
      title: "UbiquitiEdgeSwitchVoiceVLAN.json",
    },
  ];

  const manualExpansiveWorkspaceCon = async (url) => {
    const { value: referenceId } = await Swal.fire({
      title: "",
      input: "text",
      inputLabel: "Your branch network reference ID",
      inputPlaceholder: "Reference ID",
    });
    if (referenceId) {
      window.open(url + "&referenceId=" + referenceId, "_blank");
    }
  };

  const columns = [
    {
      title: "No.",
      dataIndex: "key",
      key: "key",
      render: (text, record, index) => <>{index + 1}</>,
    },
    {
      title: "Scripts",
      dataIndex: "title",
      key: "title",
      render: (text, record) => <>{record.title}</>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Tooltip title="Action">
          {record.askReferenceId ? (
            <Button
              onClick={() => manualExpansiveWorkspaceCon(record.url)}
              type="secondary"
              shape="circle"
              icon={<CaretRightOutlined />}
            />
          ) : (
            <Button
              onClick={() => window.open(record.url, "_blank")}
              type="primary"
              shape="circle"
              icon={<CaretRightOutlined />}
            />
          )}
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <MyBreadcrumb
        items={[
          { label: "Home", path: "/" },
          { label: "Manage Expect Scripts", path: "" },
        ]}
      />
      <PageHeader
        ghost={false}
        title={`Manage Expect Scripts`}
        subTitle="Select which scripts need to be available for execution"
        style={{ height: "100vh" }}
      >
        <ExpectScripts settingKey="EXPECT_SCRIPTS_ADMIN" />
      </PageHeader>
    </>
  );
}
