import React from 'react';
import { PageHeader } from 'antd';
import { useParams } from 'react-router';
import { MyBreadcrumb } from 'utils';
import CreateUpdateForm from './form';
export default function Update(props) {
  const params = useParams();
  const { id } = params;
  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: 'Workspace', path: '/workspace' },
          { label: 'Update', path: '/workspace/update/:id' },
        ]}
      />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Update"
        subTitle=""
      >
        <CreateUpdateForm id={id} />
      </PageHeader>
    </div>
  );
}
