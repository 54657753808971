import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { PageHeader, Spin, Space, Comment, Table } from "antd";
import { isMobile } from "react-device-detect";

import { handleApiError, MyBreadcrumb } from "utils";
import { knowledgeBaseViewListServiceByCategoryId } from "services/knowledge_base";
import { categoriesViewListByIdService } from "services/category";
import { Helmet } from "react-helmet";

const columns = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    render: (text, record) => {
      return (
        <div>
          <h2>
            <Link to={"/knowledge-base/view/post-" + record._id}>
              {record.title}
            </Link>
          </h2>
          {!isMobile ? <p>{record.short_description}</p> : ""}
        </div>
      );
    },
  },
];

export default function KnowledgeBaseViewPostList(props) {
  const { id } = props.match.params;
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [category, setCategory] = useState({ name: "" });

  useEffect(() => {
    loadPosts();
    loadCategory();
  }, []);

  const loadPosts = () => {
    setIsLoading(true);
    knowledgeBaseViewListServiceByCategoryId(id)
      .then((response) => {
        setIsLoading(false);
        if (response.data.items && response.data.items.length > 0) {
          setPosts(response.data.items);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const loadCategory = () => {
    categoriesViewListByIdService(id)
      .then((response) => {
        if (response.data.items && response.data.items.length > 0) {
          setCategory(response.data.items[0]);
        }
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{category.name}</title>
      </Helmet>
      <MyBreadcrumb
        items={[
          { label: "Knowledge Base View", path: "/knowledge-base/view" },
          {
            label: category.parent ? category.parent.name : "",
            path: "/knowledge-base/view",
          },
          { label: category.name, path: "/knowledge-base/view" },
        ]}
      />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={category.name}
      >
        <Spin spinning={isLoading} />
        {!isLoading && (
          <Space direction="vertical" style={{ width: "100%" }}>
            <Table
              rowClassName={(record, index) =>
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              }
              showHeader={false}
              dataSource={posts}
              columns={columns}
            />
            {/* posts.map((record, index) => (
              <Comment
                key={index}
                author={
                  <h2>
                    <Link to={"/knowledge-base/view/post-" + record._id}>
                      {record.title}
                    </Link>
                  </h2>
                }
                content={!isMobile ? <p>{record.short_description}</p> : ""}
              />
              )) */}
          </Space>
        )}
      </PageHeader>
    </div>
  );
}
