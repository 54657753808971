import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Upload, message, Spin, Button, Form, Input, Select } from 'antd';
import Auth from 'auth';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';

import { handleApiSuccess, handleApiError } from 'utils';
import { galleryViewByIdService } from 'services/gallery';
import {
  workspaceUploadSaveService,
  workspaceUploadGetOneService,
} from 'services/workspace_uploads';

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { offset: 5, span: 7 },
};

export default function UploadForm(props) {
  const { id, objectId, objectType } = props;
  const history = useHistory();
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState({});
  const [form] = Form.useForm();
  const params = useParams();

  const _auth = Auth;

  useEffect(() => {
    console.log('id====>', id);
    // IF id exist the initialize form data
    if (id) {
      workspaceUploadGetOneService(id)
        .then((response) => {
          setIsLoading(false);
          if (response.data.item) {
            const record = response.data.item;
            form.setFieldsValue(record);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  }, [id, form]);

  useEffect(() => {
    if (params.workspaceLocationId) {
      setIsLoading(true);
      galleryViewByIdService(params.workspaceLocationId)
        .then((response) => {
          setIsLoading(false);
          if (response.data.items && response.data.items.length > 0) {
            const record = response.data.items[0];
            setData(record);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  }, [params.objectId]);

  const onFinish = async (values) => {
    setIsSaving(true);
    workspaceUploadSaveService(values)
      .then((response) => {
        setIsSaving(false);
        handleApiSuccess(response);
        history.push(`/workspace/${objectId}/${objectType}/upload`);
      })
      .catch((error) => {
        setIsSaving(false);
        handleApiError(error);
      });
  };

  return (
    <div>
      <Spin spinning={isLoading} />
      {!isLoading && (
        <>
          <Form form={form} {...layout} onFinish={onFinish}>
            <Form.Item label="Document Type" name="document_type" hidden={id}>
              <Select style={{ width: 150 }} placeholder="Select">
                <Select.Option value="FEATURED_PIC">
                  Featured Picture
                </Select.Option>
                <Select.Option value="PICTURE">Picture</Select.Option>
                <Select.Option value="COLLATERAL">Collateral</Select.Option>
                <Select.Option value="FLOOR_PLAN">Floor Plan</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="ID" name="_id" hidden={true}>
              <Input name="_id" />
            </Form.Item>
            <Form.Item label="Upload" name="file_name" hidden={id}>
              <Upload
                name="file"
                maxCount={1}
                action={`${process.env.REACT_APP_API_BASE_URL}/workspace-upload/${objectId}/upload`}
                headers={{ Authorization: `Bearer ${_auth.getToken()}` }}
                data={(file) => {
                  const item = {
                    document_type: form.getFieldValue('document_type'),
                    object_type: objectType,
                  };
                  return item;
                }}
                showUploadList={true}
                beforeUpload={(file) => {
                  const docType = form.getFieldValue('document_type');
                  if (!docType) {
                    message.error('Please first select document type');
                    return false;
                  }
                  const isJpgOrPng =
                    file.type === 'image/gif' ||
                    file.type === 'image/jpg' ||
                    file.type === 'image/jpeg' ||
                    file.type === 'image/png';
                  if (!isJpgOrPng) {
                    message.error('You can only upload JPG/JPEG/GIF/PNG file!');
                  }
                  //const isLt8M = file.size / 1024 / 1024 < 8;
                  const isLt8M = file.size / 1024 / 1024 < 8;
                  if (!isLt8M) {
                    message.error('Image must smaller than 8MB!');
                  }
                  return isJpgOrPng && isLt8M;
                }}
                onChange={(info) => {
                  console.log({ info });
                  if (info.file.status === 'uploading') {
                    setIsUploading(true);
                  }
                  if (info.file.status === 'done') {
                    message.success(
                      `${info.file.name} file uploaded successfully`,
                    );
                    if (info.file.response && info.file.response.item) {
                      form.setFieldsValue({
                        _id: info.file.response.item._id,
                      });
                    }
                    setIsUploading(false);
                    setIsUploaded(true);
                  } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                    setIsUploading(false);
                    setIsUploaded(false);
                  }
                }}
              >
                <Button
                  type="button"
                  icon={isUploading ? <LoadingOutlined /> : <UploadOutlined />}
                >
                  Upload
                </Button>
              </Upload>
            </Form.Item>
            <Form.Item label="Title" name="title">
              <Input name="title" />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <Input.TextArea name="description" />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button type="primary" loading={isLoading} htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
}
