import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { msptrwStorage } from "utils";

import {
  Table,
  Space,
  Tooltip,
  Button,
  PageHeader,
  Input,
  Anchor,
  Badge,
  Descriptions,
} from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  TeamOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import {
  handleApiSuccess,
  handleApiError,
  MyBreadcrumb,
  accessControls,
  confirmDelete,
} from "utils";
import {
  ispListService,
  ispDeleteService,
  ispSearchService,
} from "services/isp";

import { debounce } from "lodash";

const { Search } = Input;
const { Link } = Anchor;

export default function IspList() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [defaultPageNumber, setDefaultPageNumber] = useState(1);

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      className: "hide",
      /*
      sorter: function (a, b, sortOrder) {
        return a._id.localeCompare(b._id);
      },
      */
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      sorter: function (a, b, sortOrder) {
        return a.company.company_name.localeCompare(b.company.company_name);
      },
      render: (text, record) => record.company.company_name,
    },
    /*
    {
      title: "Market Name",
      dataIndex: "market_name",
      key: "market_name",
      //defaultSortOrder: "ascend",
      sorter: function (a, b, sortOrder) {
        if (a.branch.market_name) {
          return a.branch.market_name.localeCompare(b.branch.market_name);
        }
      },
      render: (text, record) =>
        record.branch.market_name ? record.branch.market_name : "",
    },
    {
      title: "Building ID",
      dataIndex: "building_id",
      key: "building_id",
      sorter: function (a, b, sortOrder) {
        if (a.branch.building_id) {
          return a.branch.building_id.localeCompare(b.branch.building_id);
        }
      },
      render: (text, record) =>
        record.branch.building_id ? record.branch.building_id : "",
    },
    */

    {
      title: "WAN IP",
      dataIndex: "wan_ip_address",
      key: "wan_ip_address",
      sorter: function (a, b, sortOrder) {
        if (a.wan_ip_address) {
          return a.wan_ip_address.localeCompare(b.wan_ip_address);
        }
      },
    },

    {
      title: "Branch Name",
      dataIndex: "branch_name",
      key: "branch_name",
      //defaultSortOrder: "ascend",
      sorter: function (a, b, sortOrder) {
        if (a.branch.branch_name) {
          return a.branch.branch_name.localeCompare(b.branch.branch_name);
        }
      },
      render: (text, record) =>
        record.branch ? record.branch.branch_name : null,
    },
    /*
    {
      title: "Market Name / Building ID / Address",
      dataIndex: "market_name",
      key: "market_name",
      render: (text, record) => {
        let newStr = "";
        if (record.branch.market_name && record.branch.building_id) {
          newStr =
            record.branch.market_name + " - " + record.branch.building_id + " ";
        }
        return newStr + record.branch.physical_address;
      },
    },
    */
    {
      title: "ISP Provider",
      dataIndex: "isp_provider",
      key: "isp_provider",
      sorter: function (a, b, sortOrder) {
        return a.isp_provider.provider_name.localeCompare(
          b.isp_provider.provider_name
        );
      },
      render: (text, record) =>
        record.isp_provider ? record.isp_provider.provider_name : null,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: function (a, b, sortOrder) {
        return a.status.localeCompare(b.status);
      },
      render: (text, record) => {
        switch (text) {
          case "ACTIVE":
            return <Badge color="#52c41a" text="Active" />;
          case "INACTIVE":
            return <Badge color="#ffec3d" text="Inactive" />;
          case "TO_BE_RENEWED":
            return <Badge color="#ffec3d" text="To Be Renewed" />;
          case "TO_BE_CANCELED":
            return <Badge color="#ffec3d" text="To Be Cancelled" />;
          case "ACTIVE_PRIMARY":
            return <Badge color="#52c41a" text="Active Primary" />;
          case "ACTIVE_SECONDARY":
            return <Badge color="#52c41a" text="Active Secondary" />;
          default:
            return "NA";
        }
      },
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {accessControls.can(permissions, "Isp.read:any") && (
            <Tooltip key="view" title="View">
              <Button
                onClick={(e) => {
                  history.push("/isp/view/" + record._id);
                }}
                type="primary"
                shape="circle"
                icon={<EyeOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, "Isp.update:any") && (
            <Tooltip key="editw" title="Edit">
              <Button
                onClick={(e) => {
                  history.push("/isp/update/" + record._id);
                }}
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, "Isp.delete:any") && (
            <Tooltip key="disable" title="Disable">
              <Button
                onClick={(e) => deleteRecord(record._id)}
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    loadData();
    loadPermissions();
    msptrwStorage.get("isp.pageNumber").then((res) => {
      setDefaultPageNumber(res);
    });
  }, []);

  const loadPermissions = () => {
    //********START  ACCESS CONTROL******** */
    accessControls.fetch().then((permissions) => {
      console.log(permissions);
      setPermissions(permissions);
    });
    //********END ACCESS CONTROL******** */
  };

  const loadData = () => {
    setIsLoading(true);
    ispListService()
      .then((response) => {
        setIsLoading(false);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const deleteRecord = (id) => {
    confirmDelete().then(() => {
      setIsLoading(true);
      ispDeleteService(id)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          loadData();
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    });
  };

  const searchRecord = (q) => {
    setIsLoading(true);
    ispSearchService(q)
      .then((response) => {
        setIsLoading(false);
        handleApiSuccess(response);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        } else {
          setDataSource([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const delayedQuery = debounce((q) => searchRecord(q), 500);
  const onChange = (e) => {
    delayedQuery(e.target.value);
  };

  return (
    <div>
      <MyBreadcrumb items={[{ label: "ISP", path: "" }]} />
      <PageHeader
        ghost={false}
        avatar={{ icon: <TeamOutlined /> }}
        title="ISP"
        subTitle={
          <Search
            allowClear={true}
            onChange={onChange}
            loading={isLoading}
            placeholder="Filter search result"
            onSearch={(value) => searchRecord(value)}
            style={{ width: 200 }}
          />
        }
        extra={
          accessControls.can(permissions, "Isp.create:any")
            ? [
                <Button
                  shape="round"
                  onClick={(e) => history.push("/isp/create")}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Add New
                </Button>,
              ]
            : ""
        }
      ></PageHeader>
      <Table
        key={defaultPageNumber}
        rowKey="_id"
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
        pagination={{
          defaultCurrent: parseInt(defaultPageNumber),
          onChange: (pageNumber) => {
            msptrwStorage.set("isp.pageNumber", pageNumber);
          },
        }}
      />
    </div>
  );
}
