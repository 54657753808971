import React from 'react';
import { useParams } from 'react-router';

import { PageHeader } from 'antd';

import { TeamOutlined } from '@ant-design/icons';

import { MyBreadcrumb } from 'utils';
import UploadForm from './_form';

export default function Update() {
  const params = useParams();
  const { objectId, objectType } = params;
  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: 'Workspace', path: '/workspace' },
          { label: 'Uploads', path: '' },
        ]}
      />
      <PageHeader
        ghost={false}
        avatar={{ icon: <TeamOutlined /> }}
        title="Edit"
      >
        <UploadForm
          objectId={objectId}
          objectType={objectType}
          id={params.id}
        />
      </PageHeader>
    </div>
  );
}
