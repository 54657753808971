import React, { useState, useEffect } from "react";
import { PageHeader, Empty, Spin, Typography } from "antd";
import { Select, Popover } from "antd";
import { companiesListService } from "services/company";
import { handleApiSuccess, handleApiError, axiosBase } from "utils";

import { ArrowUpOutlined } from "@ant-design/icons";

import {
  usersSetLoginNetworkService,
  usersGetLoginNetworkService,
} from "services/user";
import Auth from "auth";

const { Title } = Typography;
const _auth = Auth;
const { Option } = Select;
export default function WebsiteWrapper(props) {
  const axios = axiosBase.create();
  const [companies, setCompanies] = useState([]);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [companyCurrent, setCompanyCurrent] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    axios({
      method: "GET",
      url: "/users/get-login-company",
    }).then((response) => {
      setCompanyCurrent(response.data.companyId);
    });

    //Load companies dropdown
    companiesListService().then((response) => {
      if (response.data.items && response.data.items.length > 0) {
        setCompanies(response.data.items);
      }
    });
  }, []);

  const handelCompanyChange = (value) => {
    setIsUpdating(true);

    axios({
      method: "POST",
      url: "/users/set-login-company/",
      data: {
        companyId: value,
      },
    }).then((response) => {
      _auth.authenticate(response.data.token, function () {
        handleApiSuccess(response);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    });
  };

  const AppBar = () => {
    return (
      <>
        <PageHeader
          className="site-page-header"
          onBack={null}
          title="Website management"
          subTitle="Company selection"
          extra={[
            <Popover
              content={<p>Please select company to load associated data</p>}
              title="Select company"
              visible={false}
            >
              <Select
                showSearch
                placeholder="Select company"
                optionFilterProp="children"
                defaultValue={companyCurrent}
                value={companyCurrent}
                onChange={(value) => handelCompanyChange(value)}
                style={{ width: 400, marginRight: 10 }}
                loading={companyLoading}
              >
                {companies.map((item, index) => (
                  <Option key={index} value={item._id}>
                    {item.company_name}
                  </Option>
                ))}
              </Select>
            </Popover>,
          ]}
        />
      </>
    );
  };

  const View = () => {
    if (companyCurrent && !isUpdating) {
      return <div>{props.children}</div>;
    } else {
      if (companyLoading || isUpdating) {
        return (
          <div style={{ margin: "30px" }}>
            <Spin />
          </div>
        );
      } else {
        return (
          <div style={{ backgroundColor: "#FFF", padding: "15px  0" }}>
            <Empty>
              <Title level={2}>
                Please select company
                <ArrowUpOutlined />
              </Title>
              <Title level={5}>
                This must be selected from above dropdown before moving forward.
              </Title>
            </Empty>
          </div>
        );
      }
    }
  };

  return (
    <div style={{ marginTop: 15 }}>
      <div style={{ backgroundColor: "#FAFAFA" }}>
        <AppBar />
      </div>
      <div>
        <View />
      </div>
    </div>
  );
}
