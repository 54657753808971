import React, { useEffect, useRef, useState } from "react";

import { useHistory } from "react-router-dom";

import { Table, Space, Tooltip, Button, PageHeader, Input } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  TeamOutlined,
  EyeOutlined,
} from "@ant-design/icons";

import {
  handleApiSuccess,
  handleApiError,
  MyBreadcrumb,
  confirmDelete,
} from "utils";
import {
  thirdPartyCredentialsListService,
  thirdPartyCredentialsListWithPasswordsService,
  thirdPartyCredentialsDeleteService,
  thirdPartyCredentialsSearchService,
  thirdPartyCredentialsListByIdEditReadService,
} from "services/third_party_credentials";

import { debounce } from "lodash";
import Swal from "sweetalert2";
import queryString from "query-string";

const { Search } = Input;

export default function ThirdPartyCredentialsList() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [withPasswords, setWithPasswords] = useState(null);
  const searchFieldRef = useRef(null);

  useEffect(() => {
    const params = queryString.parse(window.location.search);
    if (params.uid) {
      searchRecord(params.uid);
    }
  }, [searchFieldRef]);

  const showPassword = (e, id) => {
    thirdPartyCredentialsListByIdEditReadService(id)
      .then((response) => {
        if (response.data && response.data.item) {
          Swal.fire({
            title: "Password is!",
            text: response.data.item.password,
            icon: "info",
            confirmButtonText: "Ok",
          });
        }
        setIsLoading(false);
        handleApiSuccess(response);
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const showPin = (e, id) => {
    thirdPartyCredentialsListByIdEditReadService(id)
      .then((response) => {
        if (response.data && response.data.item) {
          Swal.fire({
            title: "PIN is!",
            text: response.data.item.pin,
            icon: "info",
            confirmButtonText: "Ok",
          });
        }
        setIsLoading(false);
        handleApiSuccess(response);
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      //defaultSortOrder: "descend",
      className: "hide",
      sorter: function (a, b, sortOrder) {
        return a._id.localeCompare(b._id);
      },
    },
    {
      title: "Display Name",
      dataIndex: "display_name",
      key: "display_name",
      render: (text, record) => (record.user ? record.user.display_name : null),
      sorter: function (a, b, sortOrder) {
        if (a.user) {
          return a.user.display_name.localeCompare(b.user.display_name);
        } else {
          return null;
        }
      },
    },
    {
      title: "Title / Description",
      dataIndex: "title",
      key: "title",
      sorter: function (a, b, sortOrder) {
        return a.title.localeCompare(b.title);
      },
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      render: (text, record) =>
        record.company ? record.company.company_name : null,
      sorter: function (a, b, sortOrder) {
        if (a.user.company) {
          return a.company.company_name.localeCompare(b.company.company_name);
        } else {
          return null;
        }
      },
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch",
      render: (text, record) =>
        record.branch ? record.branch.branch_name : null,
      sorter: function (a, b, sortOrder) {
        if (a.branch) {
          return a.branch.branch_name.localeCompare(b.branch.branch_name);
        } else {
          return null;
        }
      },
    },
    {
      title: "Login URL",
      dataIndex: "login_url",
      key: "login_url",
      sorter: function (a, b, sortOrder) {
        return a.login_url.localeCompare(b.login_url);
      },
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      sorter: function (a, b, sortOrder) {
        return a.username.localeCompare(b.username);
      },
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      render: (text, record) => {
        return (
          <Button onClick={(e) => showPassword(e, record._id)} type="link">
            {withPasswords ? record.password : "********"}
          </Button>
        );
      },
    },
    {
      title: "PIN",
      dataIndex: "pin",
      key: "pin",
      render: (text, record) => {
        if (record.pin) {
          return (
            <Button onClick={(e) => showPin(e, record._id)} type="link">
              {withPasswords ? record.pin : "********"}
            </Button>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (text, record) => record.user.display_name,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <Button
              onClick={(e) => {
                history.push("/third-party-credentials/update/" + record._id);
              }}
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Disable">
            <Button
              onClick={(e) => deleteRecord(record._id)}
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    const params = queryString.parse(window.location.search);
    if (!params.uid) {
      loadData();
    }
  }, []);

  const loadData = (loadWithPasswords = false) => {
    setIsLoading(true);
    let apiCall = null;
    if (loadWithPasswords) {
      setWithPasswords(true);
      apiCall = thirdPartyCredentialsListWithPasswordsService();
    } else {
      setWithPasswords(false);
      apiCall = thirdPartyCredentialsListService();
    }

    apiCall
      .then((response) => {
        setIsLoading(false);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        } else {
          setDataSource([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const deleteRecord = (id) => {
    confirmDelete().then(() => {
      setIsLoading(true);
      thirdPartyCredentialsDeleteService(id)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          loadData();
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    });
  };

  const searchRecord = (q) => {
    setIsLoading(true);
    thirdPartyCredentialsSearchService(q, withPasswords)
      .then((response) => {
        setIsLoading(false);
        handleApiSuccess(response);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        } else {
          setDataSource([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const delayedQuery = debounce((q) => searchRecord(q), 500);
  const onChange = (e) => {
    delayedQuery(e.target.value);
  };

  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: "Home", path: "/" },
          { label: "Third Party Credentials", path: "" },
        ]}
      />
      <PageHeader
        ghost={false}
        avatar={{ icon: <TeamOutlined /> }}
        title="Third Party Credentials"
        subTitle={
          <Search
            ref={searchFieldRef}
            allowClear={true}
            onChange={onChange}
            loading={isLoading}
            placeholder="Filter search result"
            onSearch={(value) => searchRecord(value)}
            style={{ width: 200 }}
          />
        }
        extra={[
          <Button
            key="create"
            shape="round"
            onClick={(e) => loadData(true)}
            type="primary"
            icon={<EyeOutlined />}
          >
            List with Passwords
          </Button>,
          <Button
            key="create"
            shape="round"
            onClick={(e) => history.push("/third-party-credentials/create")}
            type="primary"
            icon={<PlusCircleOutlined />}
          >
            Add New
          </Button>,
        ]}
      ></PageHeader>
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
        rowKey="_id"
      />
    </div>
  );
}
