import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { Table, Space, Tooltip, Button, PageHeader, Input } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  TeamOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";

import {
  handleApiSuccess,
  handleApiError,
  MyBreadcrumb,
  confirmDelete,
} from "utils";
import { galleryListService, galleryDeleteService } from "services/gallery";

export default function OfficeregistryList() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState(null);

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      defaultSortOrder: "descend",
      className: "hide",
      sorter: function (a, b, sortOrder) {
        return a._id.localeCompare(b._id);
      },
    },
    {
      title: "Gallery Title",
      dataIndex: "title",
      key: "title",
      sorter: function (a, b, sortOrder) {
        return a.title.localeCompare(b.title);
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "portregistry",
      render: (text, record) => record.description,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="View">
            <Button
              onClick={(e) => {
                history.push("/gallery/view/" + record._id);
              }}
              type="primary"
              shape="circle"
              icon={<CloudUploadOutlined />}
            />
          </Tooltip>
          <Tooltip title="Edit">
            <Button
              onClick={(e) => {
                history.push("/gallery/update/" + record._id);
              }}
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Disable">
            <Button
              onClick={(e) => deleteRecord(record._id)}
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setIsLoading(true);
    galleryListService()
      .then((response) => {
        setIsLoading(false);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        } else {
          setDataSource([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const deleteRecord = (id) => {
    confirmDelete().then(() => {
      setIsLoading(true);
      galleryDeleteService(id)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          loadData();
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    });
  };

  return (
    <div>
      <MyBreadcrumb items={[{ label: "Gallery", path: "" }]} />
      <PageHeader
        ghost={false}
        avatar={{ icon: <TeamOutlined /> }}
        title="Gallery"
        extra={[
          <Button
            key="create"
            shape="round"
            onClick={(e) => history.push("/gallery/create")}
            type="primary"
            icon={<PlusCircleOutlined />}
          >
            Add New
          </Button>,
        ]}
      ></PageHeader>
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
        rowKey="_id"
      />
    </div>
  );
}
