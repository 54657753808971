import React from 'react';
import { Redirect } from 'react-router-dom';
import { message } from 'antd';
import Auth from 'auth';
const _auth = Auth;

function Logout() {
    _auth.signout();
    message.success("Signout successfully");
    return (
        <Redirect to="/login" />
    );
}
export default Logout;