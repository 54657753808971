import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Space, Button, PageHeader, Form, Input, Divider } from 'antd';
import { usersUpdatePasswordService } from 'services/user';
import { handleApiSuccess, handleApiError, MyBreadcrumb } from 'utils';

import {
    LockOutlined,
} from '@ant-design/icons';

const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 8 }
};
const tailLayout = {
    wrapperCol: { offset: 3, span: 8 }
};

export default function UsersUpdatePassword(props) {
    const { id } = props.match.params;

    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [form] = Form.useForm();

    const onFinish = values => {
        setIsLoading(true);
        usersUpdatePasswordService(id, values).then(response => {
            setIsLoading(false);
            handleApiSuccess(response);
            history.push('/users');
        }).catch(error => {
            setIsLoading(false);
            handleApiError(error);
        });
    };

    return (
        <div>
            <MyBreadcrumb items={[{ label: "Users", path: "/users" }, { label: "Update Password", path: "" }]} />
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title="Update Password"
                subTitle=""
            >
                <Form
                    name="myForm"
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >

                    <Divider orientation="left"><LockOutlined /> Change Login Password</Divider>

                    <Space>&nbsp;</Space>

                    <Form.Item label="Password" name="password"
                        rules={[{ required: true, message: "Please input Password" }]}
                    >
                        <Input type="password" />
                    </Form.Item>

                    <Form.Item label="Confirm Password" name="confirm_password"
                        dependencies={['password']}
                        rules={[
                            { required: true, message: "Please input Confirm password" },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('The two passwords that you entered do not match!');
                                },
                            }),
                        ]}
                    >
                        <Input type="password" />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button type="primary" loading={isLoading} htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>

                </Form>
            </PageHeader>
        </div>
    );
}