import React from "react";

import { Table, PageHeader, Tooltip, Button, Typography } from "antd";
import Swal from "sweetalert2";
import { MyBreadcrumb } from "utils";

import { CaretRightOutlined } from "@ant-design/icons";
import Auth from "auth";
const _auth = Auth;
export default function Index() {
  const dataSource = [
    {
      key: "1",
      title: "View Expansive workspace live raw data on screen",
      description:
        "This link fetch latest json file and show content on screen",
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }/test/test-expansive-workspace-live-data?token=${_auth.getToken()}`,
    },

    /*
    {
      key: "1",
      title:
        "Pull latest Expansive users data and create radgroupreply 3 records if they missed or not exist",
      description:
        "This cron is for run manually, because it rare happen for and server failure when radgroupreply 3 records it miss to create, because when new branch client/customer created radgroupreply 3 records automatically get created",
      url: `${
        process.env.REACT_APP_API_BASE_URL
      }/test/test-radgroupreply-create-data-if-not-exist-cron?token=${_auth.getToken()}`,
    },
    */
  ];

  const manualExpansiveWorkspaceCon = async (url) => {
    const { value: referenceId } = await Swal.fire({
      title: "",
      input: "text",
      inputLabel: "Your branch network reference ID",
      inputPlaceholder: "Reference ID",
    });
    if (referenceId) {
      window.open(url + "&referenceId=" + referenceId, "_blank");
    }
  };

  const columns = [
    {
      title: "Task",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <>
          <Typography.Title style={{ margin: 0 }} level={3}>
            {record.title}
          </Typography.Title>
          <p>{record.description}</p>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Tooltip title="Action">
          {record.askReferenceId ? (
            <Button
              onClick={() => manualExpansiveWorkspaceCon(record.url)}
              type="secondary"
              shape="circle"
              icon={<CaretRightOutlined />}
            />
          ) : (
            <Button
              onClick={() => window.open(record.url, "_blank")}
              type="primary"
              shape="circle"
              icon={<CaretRightOutlined />}
            />
          )}
        </Tooltip>
      ),
    },
  ];

  return (
    <>
      <MyBreadcrumb
        items={[
          { label: "Home", path: "/" },
          { label: "Admin controls", path: "" },
        ]}
      />
      <PageHeader
        ghost={false}
        title={`Admin controls`}
        subTitle=""
      ></PageHeader>
      <Table dataSource={dataSource} columns={columns} rowKey="_id" />
    </>
  );
}
