import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Space, Button, Form, Input, Divider, Select } from "antd";
import {
  clientsCreateService,
  clientsListByIdService,
  clientsUpdateService,
} from "services/client";
import { handleApiSuccess, handleApiError } from "utils";

import InputMask from "react-input-mask";

import {
  LockOutlined,
  ShopOutlined,
  MobileOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 8 },
};
const tailLayout = {
  wrapperCol: { offset: 3, span: 8 },
};

const { Option } = Select;

const states = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District Of Columbia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

export default function ClientsForm(props) {
  const { id } = props;

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();

  const [phone, setPhone] = useState("33333333");

  const firstInput = useRef(null);
  useEffect(() => {
    firstInput.current.focus();
  }, []);

  useEffect(() => {
    // IF id exist the initialize form data
    if (id) {
      clientsListByIdService(id)
        .then((response) => {
          setIsLoading(false);
          if (response.data.items && response.data.items.length > 0) {
            form.setFieldsValue(response.data.items[0]);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  }, [id, form]);

  const onFinish = (values) => {
    setIsLoading(true);

    // Update
    if (id) {
      clientsUpdateService(id, values)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          history.push("/clients");
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    } else {
      // Create
      clientsCreateService(values)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          history.push("/clients");
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  };

  const PhoneInput = (props) => (
    <InputMask
      mask="999-999-9999"
      value={props.value}
      onChange={props.onChange}
    >
      {(inputProps) => <Input {...inputProps} prefix={<PhoneOutlined />} />}
    </InputMask>
  );

  return (
    <Form name="myForm" form={form} {...layout} onFinish={onFinish}>
      <Divider orientation="left">
        <LockOutlined />
        Reseller Profile
      </Divider>

      <Space>&nbsp;</Space>

      <Form.Item
        label="Reseller Name"
        name="client_name"
        rules={[{ required: true, message: "Please input client name" }]}
      >
        <Input ref={firstInput} />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          { required: true, message: "Please input email address" },
          { type: "email", message: "Please input valid email address" },
        ]}
      >
        <Input />
      </Form.Item>

      <Divider orientation="left">
        <ShopOutlined /> Address
      </Divider>
      <Space>&nbsp;</Space>
      <Form.Item label="Address" name="address">
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="Suite/Apt" name="suite_apt">
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="City" name="city">
        <Input />
      </Form.Item>

      <Form.Item label="State" name="state">
        <Select
          onChange={(value) => form.setFieldsValue({ state: value })}
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {states.map((item, i) => {
            return (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item label="Zip" name="zip">
        <Input />
      </Form.Item>

      <Divider orientation="left">
        <MobileOutlined /> Contact settings
      </Divider>
      <Space>&nbsp;</Space>

      <Form.Item label="Office Phone" name="phone">
        <PhoneInput />
      </Form.Item>

      <Form.Item label="Ext" name="ext">
        <Input />
      </Form.Item>

      <Form.Item label="Fax" name="fax">
        <PhoneInput />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" loading={isLoading} htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
