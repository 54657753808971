import React, { useEffect, useRef, useState } from "react";

import { useHistory } from "react-router-dom";

import {
  Button,
  PageHeader,
  Input,
  Avatar,
  List,
  Descriptions,
  Typography,
  Select,
  Space,
  Spin,
} from "antd";

import { TeamOutlined, UserOutlined, PrinterOutlined } from "@ant-design/icons";
import { handleApiSuccess, handleApiError, MyBreadcrumb } from "utils";
import { usersListService, usersSearchService } from "services/user";
import { companiesListService } from "services/company";
import { branchesListByCompanyIdService } from "services/branch";

import { debounce } from "lodash";
import { useReactToPrint } from "react-to-print";

const { Option } = Select;

export default function Print() {
  const [isLoading, setIsLoading] = useState(false);

  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);

  const componentRef = useRef(null);

  useEffect(() => {
    loadData();
    //Load companies dropdown
    companiesListService().then((response) => {
      if (response.data.items && response.data.items.length > 0) {
        setCompanies(response.data.items);
      }
    });
  }, []);

  const onCompanyChange = (value) => {
    branchesListByCompanyIdService(value)
      .then((response) => {
        searchRecord("company:" + value);
        setBranches(response.data.items);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const onBranchChange = (value) => {
    searchRecord("branch:" + value);
  };

  const loadData = () => {
    setIsLoading(true);
    usersListService()
      .then((response) => {
        setIsLoading(false);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setUsers(response.data.items);
        } else {
          setUsers([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const searchRecord = (q) => {
    setIsLoading(true);
    usersSearchService(q)
      .then((response) => {
        setIsLoading(false);
        handleApiSuccess(response);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setUsers(response.data.items);
        } else {
          setUsers([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <MyBreadcrumb items={[{ label: "Users", path: "" }]} />
      <PageHeader
        ghost={false}
        avatar={{ icon: <TeamOutlined /> }}
        title="Users"
        subTitle={
          <Space size={"large"}>
            <Select
              autoComplete="nope"
              showSearch
              placeholder="Select a company"
              optionFilterProp="children"
              onChange={(value) => onCompanyChange(value)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">Select a company</Option>
              {companies.map((item, i) => (
                <Option value={item._id}>{item.company_name}</Option>
              ))}
            </Select>
            <Select
              autoComplete="nope"
              showSearch
              style={{ width: 200 }}
              placeholder="Select a branch"
              optionFilterProp="children"
              onChange={(value) => onBranchChange(value)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">Select a branch</Option>
              {branches.map((item, i) => (
                <Option value={item._id}>{item.branch_name}</Option>
              ))}
            </Select>
          </Space>
        }
        extra={[
          <Button
            key="print"
            shape="round"
            onClick={handlePrint}
            type="primary"
            icon={<PrinterOutlined />}
          >
            Print
          </Button>,
        ]}
      >
        <div ref={componentRef} style={{ maxWidth: 800 }}>
          {isLoading ? (
            <Spin />
          ) : (
            <List
              itemLayout="horizontal"
              dataSource={users}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      item.photo !== "" ? (
                        <Avatar size={64} src={item.photo} />
                      ) : (
                        <Avatar size={64} icon={<UserOutlined />} />
                      )
                    }
                    title={
                      <Typography.Title level={2}>
                        {item.display_name}
                      </Typography.Title>
                    }
                    description={
                      <Descriptions title="" column={2}>
                        <Descriptions.Item label="First Name">
                          {item.first_name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Last Name">
                          {item.last_name}
                        </Descriptions.Item>
                        <Descriptions.Item label="Email">
                          {item.email}
                        </Descriptions.Item>
                        <Descriptions.Item label="Cell Phone">
                          {item.cell_phone}
                        </Descriptions.Item>
                        <Descriptions.Item label="Phone">
                          {item.phone}
                        </Descriptions.Item>
                        <Descriptions.Item label="Fax">
                          {item.fax}
                        </Descriptions.Item>
                      </Descriptions>
                    }
                  />
                </List.Item>
              )}
            />
          )}
        </div>
      </PageHeader>
    </div>
  );
}
