import React from "react";
import { PageHeader } from "antd";
import { MyBreadcrumb } from "utils";
import GalleryForm from "./form";
export default function GalleryUpdate(props) {
  const { id } = props.match.params;
  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: "Gallery", path: "/gallery" },
          { label: "Update", path: "" },
        ]}
      />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Update"
        subTitle=""
      >
        <GalleryForm id={id} />
      </PageHeader>
    </div>
  );
}
