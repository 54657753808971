/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useRef } from "react";
import { PageHeader, Button, Typography, Descriptions } from "antd";
import { MyBreadcrumb } from "utils";
import { PrinterOutlined } from "@ant-design/icons";
import { usersDetailByIdService } from "services/user";
import { thirdPartyCredentialsListByIdEditReadService } from "services/third_party_credentials";

import { handleApiSuccess, handleApiError } from "utils";
import { useReactToPrint } from "react-to-print";
import Swal from "sweetalert2";
import * as moment from "moment";
const { Title } = Typography;

export default function View(props) {
  const { id } = props.match.params;
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [thirdPartyCredentials, setThirdPartyCredentials] = useState([]);
  const [showCredentials, setShowCredentials] = useState(false);
  const componentRef = useRef(null);

  useEffect(() => {
    // IF id exist the initialize form data
    if (id) {
      usersDetailByIdService(id).then((response) => {
        console.log("RESPONSE", response);
        setIsLoading(false);
        if (response.data.item) {
          setUserInfo(response.data.item);
        }
        if (response.data.thirdPartyCredentials) {
          setThirdPartyCredentials(response.data.thirdPartyCredentials);
        }
      });
    }
  }, [id]);

  const showPassword = (e, id) => {
    thirdPartyCredentialsListByIdEditReadService(id)
      .then((response) => {
        if (response.data && response.data.item) {
          Swal.fire({
            title: "Password is!",
            text: response.data.item.password,
            icon: "info",
            confirmButtonText: "Ok",
          });
        }
        setIsLoading(false);
        handleApiSuccess(response);
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const showPin = (e, id) => {
    thirdPartyCredentialsListByIdEditReadService(id)
      .then((response) => {
        if (response.data && response.data.item) {
          Swal.fire({
            title: "PIN is!",
            text: response.data.item.pin,
            icon: "info",
            confirmButtonText: "Ok",
          });
        }
        setIsLoading(false);
        handleApiSuccess(response);
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const handlePrintWithPasswords = () => {
    let myPromises = [];

    for (let i = 0; i < thirdPartyCredentials.length; i++) {
      myPromises.push(
        new Promise((resolved, rejected) => {
          thirdPartyCredentialsListByIdEditReadService(
            thirdPartyCredentials[i]["_id"]
          )
            .then((response) => {
              resolved(response.data.item);
            })
            .catch((error) => {
              rejected(error);
            });
        })
      );
    }

    Promise.all(myPromises).then((values) => {
      setThirdPartyCredentials(values);
      setShowCredentials(true);
      setTimeout(() => handlePrint(), 500);
    });
    //setThirdPartyCredentials(decryptedItems);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: "Users", path: "/user" },
          { label: "View", path: "" },
        ]}
      />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="View"
        subTitle=""
        extra={[
          <Button
            key="1"
            onClick={handlePrint}
            type="primary"
            shape="round"
            icon={<PrinterOutlined />}
          >
            Print
          </Button>,
          <Button
            key="1"
            onClick={(e) => handlePrintWithPasswords()}
            type="primary"
            shape="round"
            icon={<PrinterOutlined />}
          >
            Print with Passwords
          </Button>,
        ]}
      >
        {isLoading ? "Loading..." : ""}

        <div ref={componentRef}>
          <div className="descriptionView2-heading">
            <Title level={3}>{userInfo?.display_name}</Title>
          </div>

          <div className="descriptionView2">
            <div className="box">
              <Title level={4}>Profile</Title>
              {userInfo?.company && (
                <div>Company: {userInfo?.company.company_name}</div>
              )}
              {userInfo?.branch && (
                <div>Branch: {userInfo?.branch.branch_name}</div>
              )}
              {userInfo?.title && <div>Title: {userInfo?.title}</div>}
              {userInfo?.display_name && (
                <div>Display Name: {userInfo?.display_name}</div>
              )}
              {userInfo?.first_name && (
                <div>First Name: {userInfo?.first_name}</div>
              )}
              {userInfo?.last_name && (
                <div>Last Name: {userInfo?.last_name}</div>
              )}
            </div>

            {(userInfo?.cell_phone ||
              userInfo?.phone ||
              userInfo?.ext ||
              userInfo?.fax) && (
              <div className="box">
                <Title level={4}>Contact details</Title>
                {userInfo?.cell_phone && (
                  <div>Cell Phone: {userInfo?.cell_phone}</div>
                )}
                {userInfo?.phone && <div>Phone: {userInfo?.phone}</div>}
                {userInfo?.ext && <div>Ext: {userInfo?.ext}</div>}
                {userInfo?.fax && <div>Fax: {userInfo?.fax}</div>}
              </div>
            )}

            {thirdPartyCredentials.map((item, index) => {
              return (
                <div key={"credentials" + index} className="box">
                  <Title level={4}>Third Party credentials {index + 1}</Title>
                  {userInfo?.title && <div>Title: {item?.title}</div>}
                  {item?.login_url && (
                    <div>
                      Login URL: <a href={item?.login_url}>{item?.login_url}</a>
                    </div>
                  )}
                  {item?.username && <div>Username: {item?.username}</div>}
                  {item?.password && (
                    <div>
                      Password:{" "}
                      <Button
                        onClick={(e) => showPassword(e, item._id)}
                        type="link"
                      >
                        {showCredentials ? item.password : "********"}
                      </Button>
                    </div>
                  )}
                  {item?.pin && (
                    <div>
                      PIN:{" "}
                      <Button onClick={(e) => showPin(e, item._id)} type="link">
                        {showCredentials ? item.pin : "********"}
                      </Button>
                    </div>
                  )}
                  {item?.notes && <div>Notes: {item?.notes}</div>}
                  {item?.renewal_date && item?.renewal_date !== "" && (
                    <div>
                      Renewal Date:{" "}
                      {moment(item?.renewal_date).format("ddd MMM DD YYYY")}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </PageHeader>
    </div>
  );
}
