import React from 'react';
import { PageHeader } from 'antd';

import { MyBreadcrumb } from 'utils';
import KnowledgeBaseForm from './form';
export default function KnowledgeBaseUpdate(props) {
    const { id } = props.match.params;
    return (
        <div>
            <MyBreadcrumb items={[{ label: "Knowledge Base", path: "/clients" }, { label: "Update", path: "" }]} />
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title="Update"
                subTitle=""
            >
                <KnowledgeBaseForm id={id} />
            </PageHeader>
        </div>
    );
}