import React, { useEffect, useState } from "react";
import {
  PageHeader,
  Space,
  Spin,
  Input,
  Button,
  Modal,
  Form,
  Select,
  Tag,
  Empty,
} from "antd";

import {
  ReadOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import { MyBreadcrumb } from "utils";
import SortableTree, { removeNodeAtPath } from "react-sortable-tree";
import "react-sortable-tree/style.css";
import { axiosBase, confirmDelete } from "utils";
import { debounce } from "lodash";
import WebsiteWrapper from "./../../layouts/WebsiteWrapper";

export default function Menu() {
  const axios = axiosBase.create();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [postItems, setPostItems] = useState([]);
  const [selectedPostItem, setSelectedPostItem] = useState(null);
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    loadMenuItem();
    loadPageItem();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const hideModal = () => {
    setIsModalVisible(false);
  };

  const loadMenuItem = () => {
    setIsLoading(true);
    axios({
      method: "GET",
      url: "/website",
    })
      .then((response) => {
        const dataItem = response.data.item;
        setTreeData(JSON.parse(dataItem.treeData));
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        setIsLoading(false);
      });
  };

  const handleTreeChange = (_treeData) => {
    const payload = _treeData;
    saveData(payload);
  };

  const removeNode = (rowInfo) => {
    let { path } = rowInfo;
    confirmDelete().then(() => {
      const _treeData = removeNodeAtPath({
        treeData: treeData,
        path: path,
        getNodeKey: ({ node: TreeNode, treeIndex: number }) => {
          return number;
        },
        ignoreCollapsed: false,
      });
      saveData(_treeData);
    });
  };

  const onFormSubmit = (formData) => {
    const payload = [...treeData, formData];
    saveData(payload);
  };

  const saveData = (payload) => {
    setIsLoading(true);
    axios({
      method: "POST",
      url: "/website",
      data: { treeData: JSON.stringify(payload) },
    }).then((response) => {
      form.resetFields();
      setIsModalVisible(false);
      loadMenuItem();
    });
  };

  const handleOnSelect = (value) => {
    setSelectedPostItem(value);
  };

  const loadPageItem = () => {
    axios({
      method: "GET",
      url: `/page`,
    }).then((response) => {
      let _postItems = [];
      const dataItems = response.data.items;
      for (let i = 0; i < dataItems.length; i++) {
        const dataItem = {
          label: dataItems[i].title,
          value: dataItems[i]._id,
        };
        _postItems.push(dataItem);
      }
      setPostItems(_postItems);
    });
  };

  const delayedQuery = debounce((value) => {
    axios({
      method: "GET",
      url: `/pages/autocomplete?q=${value}`,
    }).then((response) => {
      let _postItems = [];
      const dataItems = response.data.items;
      for (let i = 0; i < dataItems.length; i++) {
        const dataItem = {
          label: dataItems[i].title,
          value: dataItems[i]._id,
        };
        _postItems.push(dataItem);
      }
      setPostItems(_postItems);
    });
  }, 500);
  const handleOnSearch = (value) => {
    delayedQuery(value);
  };

  return (
    <div>
      <MyBreadcrumb
        items={[{ label: "Website", path: "/knowledge-base/view" }]}
      />
      <WebsiteWrapper>
        <PageHeader
          ghost={false}
          avatar={{ icon: <ReadOutlined /> }}
          title="Website"
          extra={[
            <Button
              key="create"
              shape="round"
              onClick={showModal}
              type="primary"
              icon={<PlusCircleOutlined />}
            >
              Add Menu
            </Button>,
          ]}
        >
          <Spin spinning={isLoading} />

          {!isLoading && treeData.length === 0 && <Empty />}

          <div style={{ height: 800, marginTop: 30 }}>
            <SortableTree
              treeData={treeData && Array.isArray(treeData) ? treeData : []}
              onChange={(treeData) => handleTreeChange(treeData)}
              maxDepth={2}
              generateNodeProps={(rowInfo) => ({
                title: (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: 250,
                    }}
                  >
                    <a href={rowInfo.node.url}>{rowInfo.node.title}</a>
                    <div>
                      <Space>
                        <Button
                          size="small"
                          onClick={(e) => removeNode(rowInfo)}
                          shape="circle"
                          type="primary"
                          icon={<DeleteOutlined />}
                        />
                      </Space>
                    </div>
                  </div>
                ),
              })}
            />
          </div>
        </PageHeader>
        <Modal
          onCancel={hideModal}
          title="Create menu item"
          footer={null}
          visible={isModalVisible}
        >
          <Form
            name="myForm"
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            onFinish={onFormSubmit}
          >
            <Space>&nbsp;</Space>

            <Form.Item
              label="Menu title"
              name="title"
              rules={[{ required: true, message: "Please enter menu title" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Page"
              name="post"
              rules={[{ required: true, message: "Please select page" }]}
            >
              <Select
                value={selectedPostItem}
                options={postItems}
                defaultActiveFirstOption={false}
                showArrow={true}
              />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 4, span: 20 }}>
              <Button type="primary" loading={isLoading} htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </WebsiteWrapper>
    </div>
  );
}
