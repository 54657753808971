import React, { useState } from "react";
import { PageHeader } from "antd";
import { MyBreadcrumb } from "utils";
import Form from "./form";
export default function IspUpdate(props) {
  const { id } = props.match.params;

  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: "Utility Providers", path: "/utility-providers" },
          { label: "Update", path: "" },
        ]}
      />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={`Update`}
        subTitle=""
      >
        <Form id={id} />
      </PageHeader>
    </div>
  );
}
