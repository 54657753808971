import React from "react";
import { PageHeader } from "antd";
import { MyBreadcrumb } from "utils";
import WorkspaceForm from "./form";
export default function WorkspaceCreate() {
  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: "Home", path: "/" },
          { label: "Workspace", path: "/workspace" },
          { label: "Create", path: "/create" },
        ]}
      />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Create"
        subTitle=""
      >
        <WorkspaceForm />
      </PageHeader>
    </div>
  );
}