import React, { useEffect, useState } from "react";

import { Link, useHistory } from "react-router-dom";
import {
  PageHeader,
  List,
  Typography,
  Space,
  Spin,
  Input,
  Table,
  Button,
} from "antd";

import {
  ReadOutlined,
  RightOutlined,
  FileSearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { handleApiError, handleApiSuccess, MyBreadcrumb } from "utils";
import { categoriesViewListService } from "services/category";
import { knowledgeBaseSearchService } from "services/knowledge_base";

import { groupBy, debounce, filter } from "lodash";
const { Search } = Input;

export default function KnowledgeBaseView() {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesOriginal, setCategoriesOriginal] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const { Title } = Typography;
  let history = useHistory();

  useEffect(() => {
    loadCategories();
  }, []);

  useEffect(() => {
    let localStoredSearchQuery = localStorage.getItem("msptrw-kbSearchQuery");
    if (localStoredSearchQuery) {
      searchRecord(localStoredSearchQuery);
    }
  }, []);

  const clearSearchHistory = () => {
    localStorage.removeItem("msptrw-kbSearchQuery");
    searchRecord("");
  };

  const searchRecord = (q) => {
    setIsLoading(true);
    setSearchQuery(q);
    if (q === "") {
      localStorage.removeItem("msptrw-kbSearchQuery");
      setDataSource([]);
      setIsLoading(false);
      return false;
    }
    knowledgeBaseSearchService(q)
      .then((response) => {
        setIsLoading(false);
        handleApiSuccess(response);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          localStorage.setItem("msptrw-kbSearchQuery", q);
          setDataSource(response.data.items);
        } else {
          setDataSource([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const filterCategories = async (e) => {
    const q = e.target.value;
    const reArrange = [];

    if (q !== "") {
      for await (const [key, value] of Object.entries(categoriesOriginal)) {
        let result = await filter(value, function (item) {
          //return startsWith(q.toLowerCase(), item.name.toLowerCase());
          return item.name.toLowerCase().indexOf(q.toLowerCase()) !== -1;
        });
        reArrange[key] = result;
      }
      setCategories(reArrange);
    } else {
      setCategories(categoriesOriginal);
    }
  };

  const delayedQuery = debounce((q) => searchRecord(q), 500);
  const onChange = (e) => {
    delayedQuery(e.target.value);
  };

  const loadCategories = () => {
    setIsLoading(true);
    categoriesViewListService()
      .then((response) => {
        setIsLoading(false);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          let data = groupBy(response.data.items, (item) => {
            if (item.parent) {
              return item.parent.name;
            }
          });
          delete data[undefined];
          setCategories(data);
          setCategoriesOriginal(data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: "Knowledge Base Categories", path: "/knowledge-base/view" },
        ]}
      />
      <PageHeader
        ghost={false}
        avatar={{ icon: <ReadOutlined /> }}
        title="Knowledge Base"
        subTitle={
          <Search
            key={0}
            name="posts_search"
            onChange={onChange}
            loading={isLoading}
            placeholder="Search KB posts"
            onSearch={(value) => searchRecord(value)}
            style={{ width: 200 }}
          />
        }
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Spin spinning={isLoading} />

          {searchQuery !== "" && (
            <List
              itemLayout="horizontal"
              bordered
              dataSource={dataSource}
              header={
                <Title level={4}>
                  <FileSearchOutlined /> Search result for "{searchQuery}"
                  &nbsp;&nbsp;
                  <Button
                    key="create"
                    shape="round"
                    onClick={clearSearchHistory}
                    type="primary"
                    icon={<DeleteOutlined />}
                  >
                    Clear search history
                  </Button>
                </Title>
              }
              renderItem={(item) => (
                <List.Item
                  key={item._id}
                  actions={[
                    <Link
                      //target="_blank"
                      to={"/knowledge-base/view/post-" + item._id}
                    >
                      <RightOutlined />
                    </Link>,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <Link
                        //target="_blank"
                        to={"/knowledge-base/view/post-" + item._id}
                      >
                        {item.title || "N/A"}
                      </Link>
                    }
                  />
                </List.Item>
              )}
            />
          )}

          {searchQuery === "" && (
            <div>
              <Title level={3}>List of Categories & Sub Categories</Title>
              <Space
                direction="vertical"
                style={{ width: "100%" }}
                size="middle"
              >
                <Search
                  style={{ maxWidth: "300px" }}
                  onChange={(value) => filterCategories(value)}
                  placeholder="Start typing category name to filter"
                />
                {Object.keys(categories).map((key, index) => (
                  <Table
                    key={key + index}
                    rowKey={(record) => record._id}
                    dataSource={categories[key]}
                    pagination={{
                      pageSize: 5,
                      size: "small",
                      position: ["bottomLeft"],
                      hideOnSinglePage: true,
                    }}
                    columns={[
                      {
                        key: "name",
                        title: key,
                        dataIndex: "name",
                        defaultSortOrder: "ascend",
                        sorter: function (a, b, sortOrder) {
                          return a.name.localeCompare(b.name);
                        },
                        render: (text, record) => {
                          return (
                            <Link
                              to={"/knowledge-base/view/category-" + record._id}
                            >
                              {record.name || "N/A"}
                            </Link>
                          );
                        },
                      },
                    ]}
                  />
                ))}
              </Space>
            </div>
          )}
        </Space>
      </PageHeader>
    </div>
  );
}
