import React, { useState, useEffect } from "react";
import { Table, Select } from "antd";

import { axiosBase } from "utils";

const allOptions = [
  {
    label: "Cisco-Switch.json",
    value: "Cisco-Switch.json",
  },
  {
    label: "CiscoASA5500.json",
    value: "CiscoASA5500.json",
  },
  {
    label: "DellPowerConnect3500.json",
    value: "DellPowerConnect3500.json",
  },
  {
    label: "DellPowerConnect6200.json",
    value: "DellPowerConnect6200.json",
  },
  {
    label: "DellPowerConnect6200Peachtree.json",
    value: "DellPowerConnect6200Peachtree.json",
  },
  {
    label: "FS.comSwitch.json",
    value: "FS.comSwitch.json",
  },
  {
    label: "UbiquitiEdgeRouter.json",
    value: "UbiquitiEdgeRouter.json",
  },
  {
    label: "UbiquitiEdgeRouterPeachtree.json",
    value: "UbiquitiEdgeRouterPeachtree.json",
  },
  {
    label: "UbiquitiEdgeSwitch.json",
    value: "UbiquitiEdgeSwitch.json",
  },
  {
    label: "UbiquitiEdgeSwitchVoiceVLAN.json",
    value: "UbiquitiEdgeSwitchVoiceVLAN.json",
  },
];

export default function ExpectScripts({ settingKey }) {
  const axios = axiosBase.create();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [availableOptions, setAvailableOptions] = useState([]);

  useEffect(() => {
    if (settingKey === "EXPECT_SCRIPTS_ADMIN") {
      // set all options
      setAvailableOptions(allOptions);
    } else {
      // show only what available by admin
      loadAdminOptions();
    }
    // read selected options
    readSelectedOptions();
  }, []);

  const loadAdminOptions = () => {
    console.log("loadAdminOptions-->");
    setIsLoading(true);
    axios({
      method: "GET",
      url: "/admin-settings?settingKey=EXPECT_SCRIPTS_ADMIN",
    })
      .then((response) => {
        setIsLoading(false);
        if (response.data && response.data.items) {
          console.log("set available options", response.data.items);
          const allOptionsOfAdmin = response.data.items.map((item) => {
            return {
              label: item,
              value: item,
            };
          });
          setAvailableOptions(allOptionsOfAdmin);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const readSelectedOptions = () => {
    setIsLoading(true);
    axios({
      method: "GET",
      url: `/admin-settings?settingKey=${settingKey}`,
    })
      .then((response) => {
        console.log("readSelectedOptions", response.data);
        setIsLoading(false);
        if (response.data && response.data.items) {
          setSelectedOptions(response.data.items);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const writeSelectedOptions = (value) => {
    setIsLoading(true);
    axios({
      method: "POST",
      url: `/admin-settings?settingKey=${settingKey}`,
      data: value,
    })
      .then((response) => {
        setIsLoading(false);
      })

      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleChange = (value) => {
    // from value set new selectedOptions
    console.log(`selected ${value}`);
    setSelectedOptions(value);
    writeSelectedOptions(value);
  };

  return (
    <Select
      mode="multiple"
      style={{ width: "100%", minHeight: 400 }}
      placeholder="Please select"
      value={selectedOptions}
      onChange={handleChange}
      options={availableOptions}
    />
  );
}
