import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { Table, Space, Tooltip, Button, PageHeader, Input } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  TeamOutlined,
} from "@ant-design/icons";

import {
  handleApiSuccess,
  handleApiError,
  MyBreadcrumb,
  accessControls,
  confirmDelete,
} from "utils";
import {
  ispProviderListService,
  ispProviderDeleteService,
  ispProviderSearchService,
} from "services/isp_provider";

import { debounce } from "lodash";

const { Search } = Input;

export default function IspProviderList() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      //defaultSortOrder: "descend",
      className: "hide",
      sorter: function (a, b, sortOrder) {
        return a._id.localeCompare(b._id);
      },
    },
    {
      title: "Vendor Name",
      dataIndex: "provider_name",
      key: "provider_name",
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {accessControls.can(permissions, "IspProvider.update:any") && (
            <Tooltip title="Edit">
              <Button
                onClick={(e) => {
                  history.push("/manage-isp-provider/update/" + record._id);
                }}
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, "IspProvider.delete:any") && (
            <Tooltip title="Disable">
              <Button
                onClick={(e) => deleteRecord(record._id)}
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    loadData();
    loadPermissions();
  }, []);

  const loadPermissions = () => {
    //********START  ACCESS CONTROL******** */
    accessControls.fetch().then((permissions) => {
      console.log(permissions);
      setPermissions(permissions);
    });
    //********END ACCESS CONTROL******** */
  };

  const loadData = () => {
    setIsLoading(true);
    ispProviderListService()
      .then((response) => {
        setIsLoading(false);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const deleteRecord = (id) => {
    confirmDelete().then(() => {
      setIsLoading(true);
      ispProviderDeleteService(id)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          loadData();
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    });
  };

  const searchRecord = (q) => {
    setIsLoading(true);
    ispProviderSearchService(q)
      .then((response) => {
        setIsLoading(false);
        handleApiSuccess(response);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const delayedQuery = debounce((q) => searchRecord(q), 500);
  const onChange = (e) => {
    delayedQuery(e.target.value);
  };

  return (
    <div>
      <MyBreadcrumb items={[{ label: "ISP Vendor", path: "" }]} />
      <PageHeader
        ghost={false}
        avatar={{ icon: <TeamOutlined /> }}
        title="ISP Vendors"
        subTitle={
          <Search
            allowClear={true}
            onChange={onChange}
            loading={isLoading}
            placeholder="Filter search result"
            onSearch={(value) => searchRecord(value)}
            style={{ width: 200 }}
          />
        }
        extra={
          accessControls.can(permissions, "IspProvider.create:any")
            ? [
                <Button
                  shape="round"
                  onClick={(e) => history.push("/manage-isp-provider/create")}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Add New
                </Button>,
              ]
            : ""
        }
      ></PageHeader>
      <Table dataSource={dataSource} columns={columns} loading={isLoading} />
    </div>
  );
}
