import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { Table, Space, Tooltip, Button, PageHeader, Input, Tag } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  TeamOutlined,
  ShareAltOutlined,
  ProfileOutlined,
  FolderOutlined,
} from "@ant-design/icons";

import {
  handleApiSuccess,
  handleApiError,
  MyBreadcrumb,
  accessControls,
  confirmDelete,
} from "utils";
import {
  categoriesListService,
  categoriesDeleteService,
  categoriesSearchService,
} from "services/category";

import { debounce } from "lodash";

const { Search } = Input;

export default function CategoriesList(props) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const columns = [
    /*
        {
            title: 'ID', dataIndex: '_id', key: '_id',
            sorter: function (a, b, sortOrder) {
                return a._id.localeCompare(b._id);
            }
        },
        */
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
      defaultSortOrder: "ascend",
      sorter: function (a, b, sortOrder) {
        return a.name.localeCompare(b.name);
      },
      render: (text, record) => {
        return record.parent ? (
          <span style={{ color: "green" }}>
            {<ProfileOutlined style={{ fontSize: 18 }} />} {record.name}
          </span>
        ) : (
          <span style={{ color: "#666" }}>
            {" "}
            {<FolderOutlined style={{ fontSize: 18 }} />} {record.name}
          </span>
        );
      },
    },
    {
      title: "Parent Category",
      dataIndex: "parent",
      key: "parent.name",
      render: (text, record) => {
        return record.parent ? (
          <Tag color="blue">{record.parent.name}</Tag>
        ) : (
          <span></span>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company.company_name",
      render: (text, record) => {
        return record.company ? record.company.company_name : <span></span>;
      },
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key: "branch.branch_name",
      render: (text, record) => {
        return record.branch ? record.branch.branch_name : <span></span>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {accessControls.can(permissions, "KBCategory.update:any") && (
            <Tooltip title="Edit">
              <Button
                onClick={(e) => {
                  history.push(
                    "/" +
                      props.match.params.type +
                      "/categories/update/" +
                      record._id
                  );
                }}
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, "KBCategory.delete:any") && (
            <Tooltip title="Disable">
              <Button
                onClick={(e) => deleteRecord(record._id)}
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, "KBCategory.update:any") &&
            record.parent && (
              <Tooltip title="Share">
                <Button
                  onClick={(e) => {
                    history.push(
                      "/" +
                        props.match.params.type +
                        "/categories/share/" +
                        record._id
                    );
                  }}
                  type="primary"
                  shape="circle"
                  icon={<ShareAltOutlined />}
                />
              </Tooltip>
            )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    loadData();
    loadPermissions();
  }, []);

  const loadPermissions = () => {
    //********START  ACCESS CONTROL******** */
    accessControls.fetch().then((permissions) => {
      console.log(permissions);
      setPermissions(permissions);
    });
    //********END ACCESS CONTROL******** */
  };

  const loadData = () => {
    setIsLoading(true);
    categoriesListService()
      .then((response) => {
        setIsLoading(false);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const deleteRecord = (id) => {
    confirmDelete().then(() => {
      setIsLoading(true);
      categoriesDeleteService(id)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          loadData();
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    });
  };

  const searchRecord = (q) => {
    setIsLoading(true);
    categoriesSearchService(q)
      .then((response) => {
        setIsLoading(false);
        handleApiSuccess(response);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const delayedQuery = debounce((q) => searchRecord(q), 500);
  const onChange = (e) => {
    delayedQuery(e.target.value);
  };

  return (
    <div>
      <MyBreadcrumb items={[{ label: "Categories", path: "" }]} />
      <PageHeader
        ghost={false}
        avatar={{ icon: <TeamOutlined /> }}
        title="Categories"
        subTitle={
          <Search
            allowClear={true}
            onChange={onChange}
            loading={isLoading}
            placeholder="Filter search result"
            onSearch={(value) => searchRecord(value)}
            style={{ width: 200 }}
          />
        }
        extra={
          accessControls.can(permissions, "KBCategory.create:any")
            ? [
                <Button
                  key="create"
                  shape="round"
                  onClick={(e) =>
                    history.push(
                      "/" + props.match.params.type + "/categories/create"
                    )
                  }
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Add New
                </Button>,
              ]
            : ""
        }
      ></PageHeader>
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
        rowKey="_id"
      />
    </div>
  );
}
