import { axiosBase } from "utils";

export function ispListService() {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/isp",
  });
}

export function ispListByIdService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/isp/" + id,
  });
}

export function ispViewByIdService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/isp/view/" + id,
  });
}

export function ispCreateService(values) {
  const axios = axiosBase.create();
  return axios({
    method: "POST",
    url: "/isp",
    data: {
      company: values.company,
      branch: values.branch,

      isp_provider: values.isp_provider,

      account_number: values.account_number,
      circuit_id: values.circuit_id,
      status: values.status,

      location_name: values.location_name,
      node_id: values.node_id,

      billing_name: values.billing_name,
      billing_address: values.billing_address,
      billing_address2: values.billing_address2,
      billing_country: values.billing_country,
      billing_state: values.billing_state,
      billing_city: values.billing_city,
      billing_zip: values.billing_zip,
      billing_contact: values.billing_contact,
      billing_phone: values.billing_phone,
      billing_email: values.billing_email,
      billing_fax: values.billing_fax,

      notices_address: values.notices_address,
      notices_address2: values.notices_address2,
      notices_country: values.notices_country,
      notices_state: values.notices_state,
      notices_city: values.notices_city,
      notices_zip: values.notices_zip,

      sla: values.sla,

      monthly_fee: values.monthly_fee,

      contract_start: values.contract_start ? values.contract_start : "",
      contract_end: values.contract_end ? values.contract_end : "",

      term_length_months: values.term_length_months,
      onoff_net: values.onoff_net,
      installation_fee: values.installation_fee,
      contract_signedby: values.contract_signedby,
      download_speed_mbps: values.download_speed_mbps,
      upload_speed_mbps: values.upload_speed_mbps,

      primary_on_account: values.primary_on_account,
      pin: values.pin,
      security_question: values.security_question,
      phone_number: values.phone_number,
      username: values.username,
      password: values.password,
      website: values.website,
      email: values.email,
      demarc: values.demarc,
      link_type: values.link_type,
      interface_type: values.interface_type,
      rack_info: values.rack_info,
      isp_cidr: values.isp_cidr,
      branch_wan_cidr: values.branch_wan_cidr,
      carrier_wan_cidr: values.carrier_wan_cidr,
      branch_ipv4_address_block: values.branch_ipv4_address_block,
      branch_ipv4_address_block2: values.branch_ipv4_address_block2,
      branch_ipv6_address_block: values.branch_ipv6_address_block,

      speed: values.speed,
      duplex: values.duplex,
      circuit_use: values.circuit_use,
      failover: values.failover,

      branch_wan: values.branch_wan,
      carrier_wan: values.carrier_wan,
      wan_ip_address: values.wan_ip_address,
      branch_wan_ipv6_address: values.branch_wan_ipv6_address,
      branch_subnet_mask: values.branch_subnet_mask,
      branch_subnet_mask2: values.branch_subnet_mask2,
      carrier_subnet_mask: values.carrier_subnet_mask,
      branch_ip_gateway: values.branch_ip_gateway,
      carrier_ip_gateway: values.carrier_ip_gateway,
      branch_ip_gateway_ipv6: values.branch_ip_gateway_ipv6,
      carrier_ip_gateway_ipv6: values.carrier_ip_gateway_ipv6,
      branch_first_assignable_ip: values.branch_first_assignable_ip,
      branch_last_assignable_ip: values.branch_last_assignable_ip,
      branch_first_assignable_ipv6: values.branch_first_assignable_ipv6,
      branch_broadcast_ip: values.branch_broadcast_ip,
      branch_usable_ip_range: values.branch_usable_ip_range,
      carrier_usable_ip_range: values.carrier_usable_ip_range,

      isp_dns_resolver1: values.isp_dns_resolver1,
      isp_dns_resolver2: values.isp_dns_resolver2,

      isp_dns_resolver_ipv6_1: values.isp_dns_resolver_ipv6_1,
      isp_dns_resolver_ipv6_2: values.isp_dns_resolver_ipv6_2,

      isp_dns_authoritative1: values.isp_dns_authoritative1,
      isp_dns_authoritative2: values.isp_dns_authoritative2,

      wan_cr_serial_ip_address: values.wan_cr_serial_ip_address,
      wan_cr_serial_ip_address_ipv6: values.wan_cr_serial_ip_address_ipv6,
      wan_cr_serial_ip_address_ipv6_compressed:
        values.wan_cr_serial_ip_address_ipv6_compressed,
      wan_ar_serial_ip_address: values.wan_ar_serial_ip_address,
      wan_ar_serial_ip_address_ipv6: values.wan_ar_serial_ip_address_ipv6,
      wan_ar_serial_ip_address_ipv6_compressed:
        values.wan_ar_serial_ip_address_ipv6_compressed,

      isp_sales_agent_email: values.isp_sales_agent_email,
      isp_sales_agent_phone: values.isp_sales_agent_phone,
      isp_sales_agent_name: values.isp_sales_agent_name,

      isp_notes: values.isp_notes,
    },
  });
}

export function ispUpdateService(id, values) {
  const axios = axiosBase.create();
  return axios({
    method: "PATCH",
    url: "/isp/" + id,
    data: {
      company: values.company,
      branch: values.branch,

      isp_provider: values.isp_provider,

      location_name: values.location_name,
      account_number: values.account_number,
      circuit_id: values.circuit_id,
      status: values.status,

      node_id: values.node_id,

      billing_name: values.billing_name,
      billing_address1: values.billing_address1,
      billing_address2: values.billing_address2,
      billing_country: values.billing_country,
      billing_state: values.billing_state,
      billing_city: values.billing_city,
      billing_zip: values.billing_zip,
      billing_contact: values.billing_contact,
      billing_phone: values.billing_phone,
      billing_email: values.billing_email,
      billing_fax: values.billing_fax,

      notices_address1: values.notices_address1,
      notices_address2: values.notices_address2,
      notices_country: values.notices_country,
      notices_state: values.notices_state,
      notices_city: values.notices_city,
      notices_zip: values.notices_zip,

      sla: values.sla,

      monthly_fee: values.monthly_fee,
      contract_start: values.contract_start ? values.contract_start : "",
      contract_end: values.contract_end ? values.contract_end : "",
      term_length_months: values.term_length_months,
      onoff_net: values.onoff_net,
      installation_fee: values.installation_fee,
      contract_signedby: values.contract_signedby,
      download_speed_mbps: values.download_speed_mbps,
      upload_speed_mbps: values.upload_speed_mbps,

      primary_on_account: values.primary_on_account,
      pin: values.pin,
      security_question: values.security_question,
      phone_number: values.phone_number,
      username: values.username,
      password: values.password,
      website: values.website,
      email: values.email,
      demarc: values.demarc,
      link_type: values.link_type,
      interface_type: values.interface_type,
      rack_info: values.rack_info,
      isp_cidr: values.isp_cidr,
      branch_wan_cidr: values.branch_wan_cidr,
      carrier_wan_cidr: values.carrier_wan_cidr,
      branch_ipv4_address_block: values.branch_ipv4_address_block,
      branch_ipv4_address_block2: values.branch_ipv4_address_block2,
      branch_ipv6_address_block: values.branch_ipv6_address_block,

      speed: values.speed,
      duplex: values.duplex,
      circuit_use: values.circuit_use,
      failover: values.failover,

      branch_wan: values.branch_wan,
      carrier_wan: values.carrier_wan,
      wan_ip_address: values.wan_ip_address,
      branch_wan_ipv6_address: values.branch_wan_ipv6_address,
      branch_subnet_mask: values.branch_subnet_mask,
      branch_subnet_mask2: values.branch_subnet_mask2,
      carrier_subnet_mask: values.carrier_subnet_mask,
      branch_ip_gateway: values.branch_ip_gateway,
      carrier_ip_gateway: values.carrier_ip_gateway,
      branch_ip_gateway_ipv6: values.branch_ip_gateway_ipv6,
      carrier_ip_gateway_ipv6: values.carrier_ip_gateway_ipv6,
      branch_first_assignable_ip: values.branch_first_assignable_ip,
      branch_last_assignable_ip: values.branch_last_assignable_ip,
      branch_first_assignable_ipv6: values.branch_first_assignable_ipv6,
      branch_broadcast_ip: values.branch_broadcast_ip,
      branch_usable_ip_range: values.branch_usable_ip_range,
      carrier_usable_ip_range: values.carrier_usable_ip_range,

      isp_dns_resolver1: values.isp_dns_resolver1,
      isp_dns_resolver2: values.isp_dns_resolver2,

      isp_dns_resolver_ipv6_1: values.isp_dns_resolver_ipv6_1,
      isp_dns_resolver_ipv6_2: values.isp_dns_resolver_ipv6_2,

      isp_dns_authoritative1: values.isp_dns_authoritative1,
      isp_dns_authoritative2: values.isp_dns_authoritative2,

      wan_cr_serial_ip_address: values.wan_cr_serial_ip_address,
      wan_cr_serial_ip_address_ipv6: values.wan_cr_serial_ip_address_ipv6,
      wan_cr_serial_ip_address_ipv6_compressed:
        values.wan_cr_serial_ip_address_ipv6_compressed,
      wan_ar_serial_ip_address: values.wan_ar_serial_ip_address,
      wan_ar_serial_ip_address_ipv6: values.wan_ar_serial_ip_address_ipv6,
      wan_ar_serial_ip_address_ipv6_compressed:
        values.wan_ar_serial_ip_address_ipv6_compressed,

      isp_sales_agent_email: values.isp_sales_agent_email,
      isp_sales_agent_phone: values.isp_sales_agent_phone,
      isp_sales_agent_name: values.isp_sales_agent_name,

      isp_notes: values.isp_notes,
    },
  });
}

export function ispDeleteService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "DELETE",
    url: "/isp/" + id,
  });
}

export function ispSearchService(q) {
  const axios = axiosBase.create();
  if (q === "") {
    return axios({
      method: "GET",
      url: "/isp",
    });
  } else {
    return axios({
      method: "GET",
      url: "/isp/search?q=" + q,
    });
  }
}
