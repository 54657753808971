import React from "react";
import { PageHeader } from "antd";
import { MyBreadcrumb } from "utils";
import GalleryForm from "./form";
export default function GalleryCreate() {
  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: "Gallery", path: "/gallery" },
          { label: "Create", path: "" },
        ]}
      />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Create"
        subTitle=""
      >
        <GalleryForm />
      </PageHeader>
    </div>
  );
}
