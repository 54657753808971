import React, { useState, useEffect } from "react";
import { Table, Checkbox } from "antd";

import { handleApiSuccess, handleApiError } from "utils";
import {
  accessControlListService,
  accessControlCreateService,
  accessControlDeleteService,
} from "services/access_control";

export default function AccessControlManagement({ role }) {
  const [isLoading, setIsLoading] = useState(false);
  const [accessControls, setAccessControls] = useState([]);

  const columns = [
    {
      title: "Resource",
      dataIndex: "name",
      key: "resource",
      width: "150px",
      render: (text, record) => {
        return record.name;
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => {
        return record.actions.map((item, i) => {
          return (
            <Checkbox
              checked={item.access}
              onChange={(e) =>
                onRoleAccessChange(record.resource, item.action, e)
              }
            >
              <span style={{ textTransform: "uppercase" }}>
                {item.action.replace(":any", "")}
              </span>
            </Checkbox>
          );
        });
      },
    },
  ];

  useEffect(() => {
    loadData(role);
  }, [role]);

  const loadData = (fetchRole) => {
    setIsLoading(true);
    accessControlListService(fetchRole)
      .then((response) => {
        setIsLoading(false);
        if (response.data.items && response.data.items.length > 0) {
          //handleApiSuccess(response);
          setAccessControls(response.data.items);
        } else {
          setAccessControls([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const onRoleAccessChange = (resource, action, e) => {
    if (e.target.checked) {
      setIsLoading(true);
      accessControlCreateService(resource, action, role)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          loadData(role);
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
          loadData(role);
        });
    } else {
      setIsLoading(true);
      accessControlDeleteService(resource, action, role)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          loadData(role);
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
          loadData(role);
        });
    }
  };

  return (
    <Table
      pagination={false}
      loading={isLoading}
      columns={columns}
      dataSource={accessControls}
    />
  );
}
