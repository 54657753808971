import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Space, Button, Form, Input, InputNumber, Divider, Select, DatePicker, Checkbox, Upload } from 'antd';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { companiesListService } from 'services/company';
import { branchesListByCompanyIdService } from 'services/branch';

import { qosoptionsListService } from 'services/qosoptions';
import ExpectScripts from 'utils/ExpectScripts';

import { handleApiSuccess, handleApiError, IPtoNum } from 'utils';
import { workspaceCreateService, workspaceListByIdService, workspaceUpdateService } from 'services/workspace';
import moment from 'moment';
import { ispListService } from 'services/isp';
const isIp = require('is-ip');

const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 7 },
};
const tailLayout = {
    wrapperCol: { offset: 5, span: 7 },
};

const { Option } = Select;

const amenities = [
    "Alexa in Office",
    "Airport Location",
    "Beer on Tap",
    "Bicycle Storage",
    "Break-Out Areas",
    "Business Lounge",
    "Business Park Location",
    "Café",
    "City/Town Centre",
    "Coat/Umbrella Storage",
    "Complimentary Coffee",
    "Complimentary Tea",
    "Complimentary Coffee & Tea",
    "Complementary Fruit-infused Water, Coffee, & Tea",
    "Complementary Cookies",
    "Conference Rooms",
    "Contactless Entry",
    "Copier",
    "Directory Board Entry",
    "Downtown",
    "Echo Dot in Office",
    "Elevator",
    "Faster Internet Options",
    "Free Wi-Fi",
    "Fully Furnished",
    "Gym and Fitness Room",
    "Handicap Accessible",
    "Historic District",
    "Live Receptionist",
    "Live Call Answering",
    "Lockers",
    "Logo in Lobby",
    "Logo on Door",
    "Mail Room",
    "Major Transport Connections",
    "Meeting Rooms",
    "Monitor Leasing",
    "On-site Lunch Restaurant",
    "On-site Restaurant",
    "On-site Sandwich / Coffee Bar",
    "On-site Networking Events",
    "On-site Professional Development Events",
    "Outside Seating Area / Terrace",
    "Parking",
    "Pet Friendly",
    "Phones",
    "Phone Booths",
    "Printer",
    "Projectors",
    "Quiet Rooms",
    "Rail/Tram Access",
    "Sandwich Service",
    "Security Cameras",
    "24-hour Surveillance Monitoring",
    "Secure Underground Parking",
    "Shipping Services",
    "Showers",
    "Speakers in Offices",
    "Suspended Ceilings",
    "Theater District",
    "TVs in Conference Rooms",
    "TV in Office",
    "TV Service",
    "Umbrella Storage",
    "Valet Parking",
    "Vending Machines",
    "Virtual Offices",
    "Wellness Rooms",
    "Whiteboards"
];

const spaceTypes = [
    "Conference Rooms",
    "Coworking",
    "Day Offices",
    "Event/Party Space",
    "Full Floors",
    "Fully Furnished",
    "Hot Desks – Dedicated",
    "Hot Desks – Non-Dedicated",
    "Hourly Offices",
    "Interview Rooms",
    "Long Term Office Leases",
    "Lounge Space",
    "Meeting Rooms",
    "Multi-Day Passes",
    "Podcast Rooms",
    "Private Offices",
    "Suites Large & Small",
    "Team Rooms",
    "Training Rooms",
    "Traditional Office Space",
    "Virtual Offices",
    "Webcast Rooms"
];

export default function WorkspaceForm(props) {
    const { id } = props;

    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [form] = Form.useForm();
    const [companies, setCompanies] = useState([]);
    const [branches, setBranches] = useState([]);
    const [isp, setIsps] = useState([]);

    const watchCompanyId = Form.useWatch('company', form);
    const watchBranchId = Form.useWatch('branch', form);
    const watchDirectoryDisplayDevice = Form.useWatch(
        'directoryDisplayDevice',
        form,
    );

    useEffect(() => {
        ispListService().then((response) => {
            if (response.data.items && response.data.items.length > 0) {
                setIsps(response.data.items)
            }
        });
    }, [])

    useEffect(() => {
        // IF id exist the initialize form data
        if (id) {
            workspaceListByIdService(id)
                .then((response) => {
                    setIsLoading(false);
                    if (response.data.items && response.data.items.length > 0) {
                        var record = response.data.items[0];
                        form.setFieldsValue({
                            company: record.company,
                            branch: record.branch,
                            slug: record.slug,
                            market_name: record.market_name,
                            opened: moment(record.opened),
                            voicemail: record.voicemail,
                            internet_service_provider: record.internet_service_provider,
                            cheapest_office: record.cheapest_office,
                            amenities: record.amenities,
                            space_types: record.space_types,
                        })
                        // If company was set for category then pull list of branches
                        if (response.data.items[0].company) {
                            branchesListByCompanyIdService(response.data.items[0].company)
                                .then((response) => {
                                    setBranches(response.data.items);
                                })
                                .catch((error) => {
                                    handleApiError(error);
                                });
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    handleApiError(error);
                });
        }

        //Load companies dropdown
        companiesListService().then((response) => {
            if (response.data.items && response.data.items.length > 0) {
                setCompanies(response.data.items);
            }
        });
    }, [id, form]);

    const onFinish = (values) => {
        setIsLoading(true);
        // Update
        if (id) {
            workspaceUpdateService(id, values)
                .then((response) => {
                    setIsLoading(false);
                    handleApiSuccess(response);
                    history.push('/workspace');
                })
                .catch((error) => {
                    setIsLoading(false);
                    handleApiError(error);
                });
        } else {
            // Create
            workspaceCreateService(values)
                .then((response) => {
                    setIsLoading(false);
                    handleApiSuccess(response);
                    history.push('/workspace');
                })
                .catch((error) => {
                    setIsLoading(false);
                    handleApiError(error);
                });
        }
    };

    const onCompanyChange = (value) => {
        branchesListByCompanyIdService(value)
            .then((response) => {
                setBranches(response.data.items);
            })
            .catch((error) => {
                handleApiError(error);
            });
    };

    return (
        <Form
            name="myForm"
            form={form}
            {...layout}
            onFinish={onFinish}
        >
            <Space>&nbsp;</Space>
            <div
                style={{
                    backgroundColor: '#FAFAFA',
                    border: '1px solid #EAEAEA',
                    padding: '30px 5px 5px 5px',
                    marginBottom: 30,
                }}
            >
                <Form.Item
                    label="Company"
                    name="company"
                    rules={[{ required: true, message: 'Please select Company' }]}
                >
                    <Select
                        autoComplete="nope"
                        showSearch
                        placeholder="Select a company"
                        optionFilterProp="children"
                        onChange={(value) => onCompanyChange(value)}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value="">Select a company</Option>
                        {companies.map((item, i) => (
                            <Option value={item._id}>{item.company_name}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Branch"
                    name="branch"
                    rules={[
                        { required: true, message: 'Please select Company & Branch' },
                    ]}
                >
                    <Select
                        autoComplete="nope"
                        showSearch
                        placeholder="Select a branch"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value="">Select a branch</Option>
                        {branches.map((item, i) => (
                            <Option value={item._id}>{item.branch_name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Market Name" name="market_name">
                    <Input />
                </Form.Item>
                <Form.Item label="Slug" name="slug" rules={
                    [
                        {
                            required: true,
                            message: 'Slug is required',
                        },
                        {
                            pattern: /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
                            message: 'Slug must be in lowercase and hyphen separated'
                        }
                    ]
                }>
                    <Input />
                </Form.Item>
            </div>
            <Divider orientation="left">More Details</Divider>
            {/*
      <Form.Item label="Subnet" name="subnet">
        <Input />
      </Form.Item>
      */}
            <div
                style={{
                    backgroundColor: '#FAFAFA',
                    border: '1px solid #EAEAEA',
                    padding: '30px 5px 5px 5px',
                    marginBottom: 30,
                }}
            >
                <Form.Item
                    label="Opened"
                    name="opened"
                    tooltip="The date when it is opened"
                    rules={[
                        {
                            required: true,
                            message: 'Opened Date is required',
                        },
                    ]}
                >
                    <DatePicker />
                </Form.Item>

                <Form.Item label="Voicemail Number" name="voicemail">
                    <Input />
                </Form.Item>
                <Form.Item
                    name="internet_service_provider"
                    label="Internet Provider"
                    rules={[{ required: true, message: 'Please select Internet Provider' }]}
                >
                    <Select
                        autoComplete="internet_provider"
                        showSearch
                        placeholder="Select an Internet Provider"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        <Option value="">Select an Internet Provider</Option>
                        {isp.map((item, i) => (
                            <Option value={item._id}>{item.isp_provider.provider_name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="Cheapest Office Available" name="cheapest_office">
                    <Input />
                </Form.Item>
            </div>
            {/*
      <Form.Item
        name="subnet_range"
        label="Subnet Range"
        help="example: 0.101.1.1 – 10.103.3.1"
        required={true}
      >
        <Input.Group compact>
          <Form.Item
            name="subnet_range"
            rules={[{ required: true, message: "Please enter" }]}
          >
            <Input required={true} placeholder="IP" />
          </Form.Item>
          <Input
            className="site-input-split"
            style={{
              width: 30,
              borderLeft: 0,
              borderRight: 0,
              pointerEvents: "none",
            }}
            placeholder="-"
            disabled
          />
          <Input required={true} style={{ width: "40%" }} placeholder="IP" />
        </Input.Group>
      </Form.Item>
          */}
            <Divider orientation="left">Amenities</Divider>
            <Form.Item label="Amenities" name="amenities">
                <Checkbox.Group style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }} options={amenities} />
            </Form.Item>
            <Divider orientation="left">Space Types</Divider>
            <Form.Item label="Space Types" name="space_types">
                <Checkbox.Group style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }} options={spaceTypes} />
            </Form.Item>
            <Divider style={{ padding: '30px 0px' }} orientation="left">Driving Details</Divider>


            {/* {watchDirectoryDisplayDevice === 'NON_TOUCH_SCREEN' && (
                <>
                    <Form.Item
                        label="Items Per Slide"
                        name="directoryItemsPerSlide"
                        help="Enter or leave it with default value '5' items"
                    >
                        <InputNumber min={1} max={15} />
                    </Form.Item>
                    <Form.Item
                        label="Autoplay Speed (ms)"
                        name="directoryAutoplaySpeed"
                        help="Enter or leave it with default value '3000' (3 seconds)"
                    >
                        <InputNumber min={1000} max={10000} step={1000} />
                    </Form.Item>
                </>
            )} */}

            <Form.Item label="Driving Directions" name="drivingDirections">
                <Input />
            </Form.Item>
            <Form.Item label="Description" name="description">
                <Input.TextArea rows={4} />
            </Form.Item>
            {/* <Form.Item label="Scripts" name="directoryVideoUrl">
                {watchCompanyId && watchBranchId && (
                    <ExpectScripts
                        settingKey={`EXPECT_SCRIPTS_${watchCompanyId}_${watchBranchId}`}
                    />
                )}
            </Form.Item> */}

            <Form.Item {...tailLayout}>
                <Button type="primary" loading={isLoading} htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}

const uploadButton = (
    <button
        style={{
            border: "1px solid #d9d9d9",
            borderRadius: "10px",
            background: 'none',
        }}
        type="button"
    >
        <PlusOutlined />
        <div
            style={{
                marginTop: 8,
            }}
        >
            Upload
        </div>
    </button>
);