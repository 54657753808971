import { axiosBase } from "utils";

export function ispProviderListService() {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/isp-provider",
  });
}

export function ispProviderListByIdService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/isp-provider/" + id,
  });
}

export function ispProviderCreateService(values) {
  const axios = axiosBase.create();
  return axios({
    method: "POST",
    url: "/isp-provider",
    data: {
      company: values.company,
      branch: values.branch,

      provider_name: values.provider_name,

      isp_contact_address1: values.isp_contact_address1,
      isp_contact_address2: values.isp_contact_address2,
      isp_contact_city: values.isp_contact_city,
      isp_contact_state: values.isp_contact_state,
      isp_contact_zip: values.isp_contact_zip,
      isp_contact_country: values.isp_contact_country,
      isp_contact_phone: values.isp_contact_phone,
      isp_contact_fax: values.isp_contact_fax,

      isp_support_email: values.isp_support_email,
      isp_support_website: values.isp_support_website,
      isp_support_phone_number: values.isp_support_phone_number,

      isp_billing_address1: values.isp_billing_address1,
      isp_billing_address2: values.isp_billing_address2,
      isp_billing_city: values.isp_billing_city,
      isp_billing_state: values.isp_billing_state,
      isp_billing_zip: values.isp_billing_zip,
      isp_billing_country: values.isp_billing_country,
      isp_billing_email: values.isp_billing_email,
      isp_billing_phone: values.isp_billing_phone,

      isp_termination_email: values.isp_termination_email,
      isp_termination_phone: values.isp_termination_phone,

      isp_sales_email: values.isp_sales_email,
      isp_sales_phone: values.isp_sales_phone,

      isp_service_area: values.isp_service_area,
      isp_service_area_states: values.isp_service_area_states,

      notes: values.notes,
    },
  });
}

export function ispProviderUpdateService(id, values) {
  const axios = axiosBase.create();
  return axios({
    method: "PATCH",
    url: "/isp-provider/" + id,
    data: {
      provider_name: values.provider_name,

      isp_contact_address1: values.isp_contact_address1,
      isp_contact_address2: values.isp_contact_address2,
      isp_contact_city: values.isp_contact_city,
      isp_contact_state: values.isp_contact_state,
      isp_contact_zip: values.isp_contact_zip,
      isp_contact_country: values.isp_contact_country,
      isp_contact_phone: values.isp_contact_phone,
      isp_contact_fax: values.isp_contact_fax,

      isp_support_email: values.isp_support_email,
      isp_support_website: values.isp_support_website,
      isp_support_phone_number: values.isp_support_phone_number,

      isp_billing_address1: values.isp_billing_address1,
      isp_billing_address2: values.isp_billing_address2,
      isp_billing_city: values.isp_billing_city,
      isp_billing_state: values.isp_billing_state,
      isp_billing_zip: values.isp_billing_zip,
      isp_billing_country: values.isp_billing_country,
      isp_billing_email: values.isp_billing_email,
      isp_billing_phone: values.isp_billing_phone,

      isp_termination_email: values.isp_termination_email,
      isp_termination_phone: values.isp_termination_phone,

      isp_sales_email: values.isp_sales_email,
      isp_sales_phone: values.isp_sales_phone,

      isp_service_area: values.isp_service_area,
      isp_service_area_states: values.isp_service_area_states,

      notes: values.notes,
    },
  });
}

export function ispProviderDeleteService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "DELETE",
    url: "/isp-provider/" + id,
  });
}

export function ispProviderSearchService(q) {
  const axios = axiosBase.create();
  if (q === "") {
    return axios({
      method: "GET",
      url: "/isp-provider",
    });
  } else {
    return axios({
      method: "GET",
      url: "/isp-provider/search?q=" + q,
    });
  }
}
