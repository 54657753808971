import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Space, Button, Form, Input, DatePicker, Select, Radio } from "antd";
import {
  thirdPartyCredentialsCreateService,
  thirdPartyCredentialsListByIdEditReadService,
  thirdPartyCredentialsUpdateService,
} from "services/third_party_credentials";
import { usersListService } from "services/user";
import { handleApiSuccess, handleApiError } from "utils";
import * as moment from "moment";

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 8 },
};
const tailLayout = {
  wrapperCol: { offset: 3, span: 8 },
};

const { Option } = Select;

export default function ThirdPartyCredentialsForm(props) {
  const { id } = props;

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const nameInput = useRef(null);

  useEffect(() => {
    nameInput.current.focus();
  }, []);

  useEffect(() => {
    // IF id exist the initialize form data
    if (id) {
      thirdPartyCredentialsListByIdEditReadService(id)
        .then((response) => {
          setIsLoading(false);
          if (response.data.item) {
            var record = response.data.item;
            if (record.renewal_date) {
              record.renewal_date = moment(record.renewal_date);
            }
            console.log("record", record);
            //console.log("renewal_date", record.renewal_date);
            form.setFieldsValue(record);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }

    // Load users dropdown
    usersListService()
      .then((response) => {
        setIsLoading(false);
        var usersList = [];
        if (response.data.items && response.data.items.length > 0) {
          response.data.items.map((item) => {
            usersList.push({
              label: item.display_name,
              value: item._id,
            });
          });
        }
        setUsers(usersList);
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  }, [id, form]);

  const onFinish = (values) => {
    setIsLoading(true);

    // Update
    if (id) {
      thirdPartyCredentialsUpdateService(id, values)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          history.push("/third-party-credentials");
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    } else {
      // Create
      thirdPartyCredentialsCreateService(values)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          history.push("/third-party-credentials");
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  };

  return (
    <Form
      name="myForm"
      form={form}
      {...layout}
      onFinish={onFinish}
      initialValues={{ isPrivate: false }}
    >
      <Space>&nbsp;</Space>

      <Form.Item
        label="User"
        name="user"
        rules={[{ required: true, message: "Please select user" }]}
      >
        <Select
          onChange={(value) => form.setFieldsValue({ user: value })}
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {users.map((item, i) => {
            return (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        label="Title / Description"
        name="title"
        rules={[{ required: true, message: "Please input title" }]}
        help="Example: Linkedin, Office 365, Facebook, Twitter etc"
      >
        <Input ref={nameInput} />
      </Form.Item>

      <Form.Item
        label="Login URL"
        name="login_url"
        rules={[
          { required: true, message: "Please input Login URL for 3rd party" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Username"
        name="username"
        rules={[
          { required: true, message: "Please input username for 3rd party" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          { required: true, message: "Please input password for 3rd party" },
        ]}
        help="Password will be saved in encrypted form"
      >
        <Input.Password />
      </Form.Item>

      <Form.Item label="PIN" name="pin">
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="isPrivate"
        label="Credentials"
        rules={[{ required: true, message: "Please select" }]}
      >
        <Radio.Group>
          <Radio value={true}>Private</Radio>
          <Radio value={false}>Company wide</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="Notes" name="notes">
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="Renewal Date" name="renewal_date">
        <DatePicker />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" loading={isLoading} htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
