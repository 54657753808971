import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { PageHeader, Button, Spin, Typography, Row, Col, Space } from "antd";

import ReactHtmlParser from "react-html-parser";
import { useReactToPrint } from "react-to-print";

import { handleApiError, MyBreadcrumb, accessControls } from "utils";
import { knowledgeBaseViewListByIdService } from "services/knowledge_base";
import { usersGetLoginClientService } from "services/user";

import {
  galleryViewByIdService,
  photoListByGalleryIdService,
} from "services/gallery";

import { PrinterOutlined, EditOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import Gallery from "react-grid-gallery";

const { Title } = Typography;

export default function KnowledgeBaseViewPostDetail(props) {
  const history = useHistory();
  const { id } = props.match.params;
  const [isLoading, setIsLoading] = useState(false);
  const [post, setPost] = useState({});
  const [gallery, setGallery] = useState([]);
  const componentRef = useRef(null);
  const [permissions, setPermissions] = useState([]);
  const [activeClient, setActiveClient] = useState("");

  useEffect(() => {
    usersGetLoginClientService()
      .then((response) => {
        if (response.data.items && response.data.items.length > 0) {
          setActiveClient(response.data.items[0]);
        }
      })
      .catch((error) => {
        handleApiError(error);
      });
  }, []);

  useEffect(() => {
    loadPermissions();
  }, []);

  useEffect(() => {
    loadPost();
  }, []);

  const loadPermissions = () => {
    //********START  ACCESS CONTROL******** */
    accessControls.fetch().then((permissions) => {
      setPermissions(permissions);
    });
    //********END ACCESS CONTROL******** */
  };

  const loadPost = () => {
    setIsLoading(true);
    knowledgeBaseViewListByIdService(id)
      .then((response) => {
        setIsLoading(false);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          const item = response.data.items[0];
          setPost(item);
          if (item.gallery) {
            loadGallery(item.gallery);
            //loadGalleryPhotos(item.gallery);
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const loadGallery = async (galleryIds) => {
    setIsLoading(true);

    let galleryPromisesArr = [];

    for (let i = 0; i < galleryIds.length; i++) {
      galleryPromisesArr.push(
        new Promise((resolve, reject) => {
          galleryViewByIdService(galleryIds[i])
            .then((response) => {
              if (response.data.items && response.data.items.length > 0) {
                resolve(response.data.items[0]);
              }
            })
            .catch((err) => {
              reject(err);
            });
        }) // Promise - Push
      );
    }

    let photosPromisesArr = [];

    await Promise.all(galleryPromisesArr).then(async (galleries) => {
      for (let i = 0; i < galleries.length; i++) {
        photosPromisesArr.push(
          new Promise((resolve, reject) => {
            photoListByGalleryIdService(galleries[i]._id).then((response) => {
              if (response.data.items && response.data.items.length > 0) {
                const photoItem = response.data.items.map((photo) => {
                  return {
                    src: photo.photoUrl,
                    thumbnail: photo.thumbnailUrl,
                    thumbnailCaption: photo.title,
                    caption: photo.description,
                  };
                }); // map
                resolve({ ...galleries[i], photos: photoItem });
              } // ID
            });
          }) // Promise
        ); // push
      } // for
    }); // Promise

    await Promise.all(photosPromisesArr)
      .then((galleryAndPhotos) => {
        setGallery(galleryAndPhotos);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  var breadcrumb = [];
  breadcrumb.push({
    label: "Knowledge Base View",
    path: "/knowledge-base/view",
  });
  if (post.categories) {
    let categoryName = [];
    if (post.categories && post.categories.length > 0) {
      post.categories.map((item, index) => {
        breadcrumb.push({
          label: item.parent.name,
          path: "/knowledge-base/view",
        });
        categoryName.push(item.name);
      });
      categoryName = categoryName.join(", ");
    }
    breadcrumb.push({ label: categoryName, path: "/back" });
  }
  breadcrumb.push({ label: post.title, path: "" });

  const GalleryPlacement = ({ galleryItems }) => {
    if (galleryItems && galleryItems.length > 0) {
      let html = galleryItems.map((galleryItem) => {
        return (
          <>
            <Row>
              <Col span={24}>
                <h1>{galleryItem.title}</h1>
                <p>{galleryItem.description}</p>
                <Gallery images={galleryItem.photos} />
              </Col>
            </Row>
            <Space>&nbsp;</Space>
          </>
        );
      });
      return <div>HTML{html}</div>;
    } else {
      return null;
    }
  };

  const EditButton = () => {
    if (
      post.client === activeClient._id &&
      accessControls.can(permissions, "KBPost.update:any")
    ) {
      return (
        <Button
          key="1"
          onClick={(e) => {
            history.push("/knowledge-base/update/" + post._id);
          }}
          type="primary"
          shape="round"
          icon={<EditOutlined />}
        >
          Edit
        </Button>
      );
    } else {
      return null;
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{post.title}</title>
      </Helmet>
      <MyBreadcrumb items={breadcrumb} />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={post.title}
        extra={[
          <EditButton />,
          <Button
            key="1"
            onClick={handlePrint}
            type="primary"
            shape="round"
            icon={<PrinterOutlined />}
          >
            Print
          </Button>,
        ]}
      >
        <Spin spinning={isLoading} />
        <div className="kbPostViewDetail" ref={componentRef}>
          <Title className="showOnlyForPrint">{post.title}</Title>
          <div className="kbPostViewDetailContent">
            {post.gallery_placement === "BEFORE" && (
              <GalleryPlacement galleryItems={gallery} />
            )}
            {post.content &&
              post.content.length > 0 &&
              ReactHtmlParser(post.content)}
            {post.gallery_placement === "AFTER" && (
              <GalleryPlacement galleryItems={gallery} />
            )}
          </div>
        </div>
      </PageHeader>
    </div>
  );
}
