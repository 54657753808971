import React, { useEffect, useRef, useState } from 'react';
import { extensions } from 'utils';
import { message, Tooltip } from 'antd';

import {
    Table,
    Button,
    Card,
    Row,
    Col,
    Statistic,
    DatePicker,
    Descriptions,
    PageHeader,
    Space,
} from 'antd';

import {
    ArrowUpOutlined,
    ArrowDownOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    MinusOutlined,
    PlayCircleOutlined,
    PauseCircleOutlined,
    LoadingOutlined,
    DownloadOutlined,
} from '@ant-design/icons';

import { handleApiSuccess, handleApiError, MyBreadcrumb } from 'utils';
import { clientsSearchService } from 'services/client';

import { debounce } from 'lodash';
import moment from 'moment';
import { getCallRecordingService, getCallReportService, getCallReportStatsService } from 'services/call-report';

const { RangePicker } = DatePicker;

const CircleWithNumber = ({ percent, color }) => (
    <div
        style={{
            width: '100px',
            height: '100px',
            borderRadius: '50%',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px',
            fontWeight: 'bold',
            color: color,
            backgroundColor: 'white',
        }}
    >
        <svg
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                transform: 'rotate(-90deg)',
            }}
            width="100"
            height="100"
            viewBox="0 0 36 36"
        >
            <path
                style={{
                    fill: 'none',
                    stroke: '#e6e6e6',
                    strokeWidth: '3.8',
                }}
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
                style={{
                    fill: 'none',
                    stroke: color,
                    strokeWidth: '3.8',
                    strokeDasharray: `${percent}, 100`,
                }}
                d="M18 2.0845
                   a 15.9155 15.9155 0 0 1 0 31.831
                   a 15.9155 15.9155 0 0 1 0 -31.831"
            />
        </svg>
        {percent}%
    </div>
);

export default function CallReport() {
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState(null);
    const [stats, setStats] = useState(null);
    const pageSize = 10;
    const [offset, setOffset] = useState(0);
    const [direction, setDirection] = useState('inbound');
    const [dateRange, setDateRange] = useState({
        start: moment().startOf('month').format('YYYY-MM-DD'),
        end: moment().endOf('month').format('YYYY-MM-DD'),
    });
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentAudio, setCurrentAudio] = useState('');
    const [isAudioLoading, setIsAudioLoading] = useState(false);
    const [downloadAudio, setDownloadAudio] = useState('');
    const [isDownloadLoading, setIsDownloadLoading] = useState(false);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'xml_cdr_uuid',
            key: 'xml_cdr_uuid',
            //defaultSortOrder: "descend",
            className: 'hide',
            sorter: function (a, b, sortOrder) {
                return a._id.localeCompare(b._id);
            },
        },
        {
            title: 'Ext',
            dataIndex: 'extension_uuid',
            key: 'extension_uuid',
            render: (text, record) => {
                return extensions[record.extension_uuid];
            },
        },
        {
            title: 'Domain Name',
            dataIndex: 'domain_name',
            key: 'domain_name',
            sorter: function (a, b, sortOrder) {
                return a.domain_name.localeCompare(b.domain_name);
            },
        },
        {
            title: 'Direction',
            dataIndex: 'direction',
            key: 'direction',
            filters: [
                { text: 'Inbound', value: 'inbound' },
                { text: 'Outbound', value: 'outbound' },
            ],
            filterMultiple: false,
            onFilter: (value, record) => {
                setDirection(value);
                return dataSource.filter((item) => item.direction === value);
            },
        },
        {
            title: 'Caller Name',
            dataIndex: 'caller_id_name',
            key: 'caller_id_name',
            sorter: function (a, b, sortOrder) {
                return a.caller_id_name.localeCompare(b.caller_id_name);
            },
        },
        {
            title: 'Caller No.',
            dataIndex: 'caller_id_number',
            key: 'caller_id_number',
            sorter: function (a, b, sortOrder) {
                return a.caller_id_number.localeCompare(b.caller_id_number);
            },
        },
        {
            title: 'Destination No.',
            dataIndex: 'caller_destination',
            key: 'caller_destination',
            sorter: function (a, b, sortOrder) {
                return a.destination_number.localeCompare(b.destination_number);
            },
        },
        {
            title: 'Date',
            dataIndex: 'start_stamp',
            key: 'start_stamp',
            render: (text, record) => {
                return moment(record.start_stamp).format('D MMM YYYY');
            },
            sorter: function (a, b, sortOrder) {
                return a.start_stamp.localeCompare(b.start_stamp);
            },
        },
        {
            title: 'Time',
            dataIndex: 'start_stamp',
            key: 'start_stamp',
            render: (text, record) => {
                return moment(record.start_stamp).format('h:mm a');
            },
            sorter: function (a, b, sortOrder) {
                return a.start_stamp.localeCompare(b.start_stamp);
            },
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            render: (text, record) => {
                return moment.utc(record.duration * 1000).format('HH:mm:ss');
            },
            sorter: function (a, b, sortOrder) {
                return a.duration - b.duration;
            },
        },
        {
            title: 'Recording',
            dataIndex: 'record_name',
            key: 'record_name',
            render: (text, record) => {
                return (
                    record.record_name
                        ?
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}>
                            <Tooltip title="Play Audio">
                                <Button
                                    type='primary'
                                    shape='circle'
                                    icon={isPlaying && record.xml_cdr_uuid === currentAudio ? <PauseCircleOutlined /> : isAudioLoading && record.xml_cdr_uuid === currentAudio ? <LoadingOutlined spin /> : <PlayCircleOutlined />}
                                    onClick={() => handleRecordPlay(record.xml_cdr_uuid)}
                                    style={{ marginRight: '4px' }}
                                />
                            </Tooltip>
                            <Tooltip title='Download Audio'>
                                <Button
                                    type='primary'
                                    shape='circle'
                                    icon={isDownloadLoading && record.xml_cdr_uuid === downloadAudio ? <LoadingOutlined spin /> : <DownloadOutlined />}
                                    onClick={() => handleDownloadRecord(record.xml_cdr_uuid)}
                                />
                            </Tooltip>
                        </div>
                        :
                        <p>N/A</p>
                )
            },
        }
    ];

    const handleDownloadRecord = (uuid) => {
        if (isDownloadLoading) {
            message.error('Please wait while the audio is downloading', 3);
            return;
        }
        setDownloadAudio(uuid);
        setIsDownloadLoading(true);
        getCallRecordingService(uuid).then((response) => {
            if (response.data) {
                const base64String = response.data;
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'audio/mpeg' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${uuid}.mp3`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                setIsDownloadLoading(false);
            }
        }).catch((error) => {
            setIsDownloadLoading(false);
            console.log(error);
        });
    };

    const handleRecordPlay = (uuid) => {

        if (isAudioLoading) {
            message.error('Please wait while the audio is loading', 3);
            return;
        }

        if (audioRef.current && isPlaying && currentAudio === uuid) {
            audioRef.current.pause();
            setIsPlaying(false);
        }

        if (audioRef.current && !isPlaying && currentAudio === uuid) {
            audioRef.current.play();
            setIsPlaying(true);
        }

        if ((!audioRef.current || currentAudio !== uuid)) {
            setIsAudioLoading(true)
            if (audioRef.current) {
                audioRef.current.pause();
            }
            setIsPlaying(false)
            setCurrentAudio(uuid)
            getCallRecordingService(uuid).then((response) => {
                if (response.data) {
                    const base64String = response.data;
                    const byteCharacters = atob(base64String);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const blob = new Blob([byteArray], { type: 'audio/mpeg' });

                    // Create an object URL from the Blob
                    const audioUrl = URL.createObjectURL(blob);

                    // Play the audio
                    const audio = new Audio(audioUrl);
                    audioRef.current = audio;
                    audioRef.current.play();
                    setIsPlaying(true);
                    setIsAudioLoading(false);

                    audioRef.current.addEventListener('ended', () => {
                        setIsPlaying(false);
                    });
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    }

    useEffect(() => {
        const url = `/call-report?offset=${offset}&direction=${direction}&start=${dateRange.start}&end=${dateRange.end}`;
        loadData(url);
    }, [offset, direction, dateRange]);

    useEffect(() => {
        getCallReportStatsService()
            .then((response) => {
                if (response.data) {
                    setStats(response.data);
                }
            })
            .catch((error) => {
                handleApiError(error);
            });
    }, [])

    const loadData = (url) => {
        setIsLoading(true);
        getCallReportService(url)
            .then((response) => {
                setIsLoading(false);
                if (response.data && response.data.length > 0) {
                    setDataSource(response.data);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                handleApiError(error);
            });
    };

    const searchRecord = (q) => {
        setIsLoading(true);
        clientsSearchService(q)
            .then((response) => {
                setIsLoading(false);
                handleApiSuccess(response);
                //handleApiSuccess(response);
                if (response.data.items && response.data.items.length > 0) {
                    setDataSource(response.data.items);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                handleApiError(error);
            });
    };

    const delayedQuery = debounce((q) => searchRecord(q), 500);
    const onChange = (e) => {
        delayedQuery(e.target.value);
    };

    const handleTableChange = (data) => {
        if (data.direction !== direction) {
            setOffset(0);
        }
    };

    return (
        <div>
            <MyBreadcrumb items={[{ label: 'Call Report', path: '' }]} />
            <Space direction="vertical" style={{ width: '100%' }}>
                <Row gutter={24}>
                    {/* Metric 1 */}
                    <Col span={8}>
                        <Card
                            bordered={false}
                            style={{
                                borderRadius: '12px',
                                backgroundColor: '#ffffff',
                                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <Statistic
                                        title="Total Calls"
                                        value={stats && stats.totalCalls.value || 0}
                                        valueStyle={{ fontSize: '24px', fontWeight: 'bold' }}
                                        prefix={
                                            stats?.totalCalls.percent > 0 ? (
                                                <ArrowUpOutlined style={{ color: '#3f8600' }} />
                                            ) : stats?.totalCalls.percent < 0 ? (
                                                <ArrowDownOutlined style={{ color: '#cf1322' }} />
                                            ) : (
                                                <MinusOutlined style={{ color: '#696969' }} />
                                            )
                                        }
                                    />
                                    <p style={{ marginTop: '10px', color: '#888' }}>
                                        Since last 24 Hours
                                    </p>
                                </div>
                                <CircleWithNumber percent={stats && Math.abs(stats.totalCalls.percent) || 0} color={`${stats?.totalCalls.percent > 0 ? '#3f8600' : stats?.totalCalls.percent < 0 ? '#cf1322' : '#696969'}`} />
                            </div>
                        </Card>
                    </Col>

                    {/* Metric 2 */}
                    <Col span={8}>
                        <Card
                            bordered={false}
                            style={{
                                borderRadius: '12px',
                                backgroundColor: '#ffffff',
                                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <Statistic
                                        title="Total Minutes"
                                        value={stats && stats.totalMinutes.value || 0}
                                        precision={2}
                                        valueStyle={{ fontSize: '24px', fontWeight: 'bold' }}
                                        prefix={
                                            stats?.totalMinutes.percent > 0 ? (
                                                <ArrowUpOutlined style={{ color: '#3f8600' }} />
                                            ) : stats?.totalMinutes.percent < 0 ? (
                                                <ArrowDownOutlined style={{ color: '#cf1322' }} />
                                            ) : (
                                                <MinusOutlined style={{ color: '#696969' }} />
                                            )
                                        }
                                    />
                                    <p style={{ marginTop: '10px', color: '#888' }}>
                                        Compared to Last 24 Hours
                                    </p>
                                </div>
                                <CircleWithNumber percent={stats && Math.abs(stats.totalMinutes.percent) || 0} color={`${stats?.totalMinutes.percent > 0 ? '#3f8600' : stats?.totalMinutes.percent < 0 ? '#cf1322' : '#696969'}`} />
                            </div>
                        </Card>
                    </Col>

                    {/* Metric 3 */}
                    <Col span={8}>
                        <Card
                            bordered={false}
                            style={{
                                borderRadius: '12px',
                                backgroundColor: '#ffffff',
                                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <Statistic
                                        title="Missed Calls"
                                        value={stats && stats.totalMissedCalls.value || 0}
                                        valueStyle={{ fontSize: '24px', fontWeight: 'bold' }}
                                        prefix={
                                            stats?.totalMissedCalls.percent < 0 ? (
                                                <ArrowDownOutlined style={{ color: '#3f8600' }} />
                                            ) : stats?.totalMissedCalls.percent > 0 ? (
                                                <ArrowUpOutlined style={{ color: '#cf1322' }} />
                                            ) : (
                                                <MinusOutlined style={{ color: '#696969' }} />
                                            )
                                        }
                                    />
                                    <p style={{ marginTop: '10px', color: '#888' }}>
                                        in Last 24 Hours
                                    </p>
                                </div>
                                <CircleWithNumber percent={stats && Math.abs(stats.totalMissedCalls.percent) || 0} color={`${stats?.totalMissedCalls.percent < 0 ? '#3f8600' : stats?.totalMissedCalls.percent > 0 ? '#cf1322' : '#696969'}`} />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Space>

            <Space style={{ marginTop: 30, width: '100%' }} direction="vertical">
                <PageHeader
                    ghost={false}
                    title="Call Report"
                    subTitle="Find all the call records here"
                    extra={[
                        <RangePicker
                            showTime
                            onChange={(dates, dateStrings) => {
                                const [start, end] = dateStrings;
                                setDateRange({
                                    start: start,
                                    end: end,
                                });
                            }}
                        />,
                    ]}
                ></PageHeader>
            </Space>

            <div
                style={{
                    paddingBottom: '30px',
                    paddingRight: '30px',
                    textAlign: 'right',
                }}
            ></div>
            <Table
                style={{
                    padding: '30px',
                    borderRadius: '12px',
                    backgroundColor: '#ffffff',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                }}
                dataSource={dataSource}
                pagination={false}
                onChange={handleTableChange}
                columns={columns}
                loading={isLoading}
            />
            <div style={{ textAlign: 'right', marginTop: '20px' }}>
                <Button
                    type="primary"
                    icon={<ArrowLeftOutlined />}
                    onClick={() => setOffset(offset - pageSize)}
                    disabled={offset === 0}
                    style={{ marginRight: '10px' }}
                >
                    Previous
                </Button>
                <Button
                    type="primary"
                    icon={<ArrowRightOutlined />}
                    onClick={() => setOffset(offset + pageSize)}
                    disabled={dataSource && dataSource.length < pageSize}
                >
                    Next
                </Button>
            </div>
        </div>
    );
}
