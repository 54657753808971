import React from 'react';
import { PageHeader, Space, Divider, Tabs } from 'antd';

import { MyBreadcrumb } from 'utils';

import AccessControlManagement from 'utils/AccessControlManagement';

const { TabPane } = Tabs;

export default function AccessControl(props) {
  return (
    <div>
      <MyBreadcrumb
        items={[
          {
            label: 'Access control',
            path: '/access-control',
          },
          { label: 'Index', path: '' },
        ]}
      />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Access Control (Group permission)"
        subTitle=""
      >
        <h2>Note: Super Admin have all the accesses</h2>
        <h3>You can allow following roles to manage items</h3>
        <Divider />
        <Space
          size="small"
          style={{ width: '100%' }}
          direction="vertical"
        ></Space>

        <Tabs defaultActiveKey="1">
          <TabPane tab="30 - Reseller Non-Admin and Reseller Admin" key={30}>
            <AccessControlManagement role={30} />
          </TabPane>
          <TabPane tab="40 - Customer Company Admin" key={40}>
            <AccessControlManagement role={40} />
          </TabPane>
          <TabPane tab="50 - Customer Branch Admin" key={50}>
            <AccessControlManagement role={50} />
          </TabPane>
          <TabPane tab="60 - Customer Non Admin" key={60}>
            <AccessControlManagement role={60} />
          </TabPane>
          <TabPane tab="12 - Workspace Manager" key={12}>
            <AccessControlManagement role={12} />
          </TabPane>
        </Tabs>
      </PageHeader>
    </div>
  );
}
