import React from 'react';
import { PageHeader } from 'antd';
import { MyBreadcrumb } from 'utils';
import SalesForm from './form';
export default function CreateSalesPerson() {

    return (
        <div>
            <MyBreadcrumb items={[{ label: "Sales Persons", path: "/sales" }, { label: "Create", path: "" }]} />
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title="Create"
                subTitle=""
            >
                <SalesForm />
            </PageHeader>
        </div>
    );
}