import { axiosBase } from 'utils';

export function accessControlListService(role) {
    const axios = axiosBase.create();
    return axios({
        method: 'GET',
        url: '/access-control/role/' + role,
    });
}

export function accessControlCreateService(resource, action, role) {
    const axios = axiosBase.create();
    return axios({
        method: 'POST',
        data: { resource: resource, action: action },
        url: '/access-control/create/role/' + role,
    });
}

export function accessControlDeleteService(resource, action, role) {
    const axios = axiosBase.create();
    return axios({
        method: 'POST',
        data: { resource: resource, action: action },
        url: '/access-control/delete/role/' + role,
    });
}