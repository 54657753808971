import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Space, Button, Form, Input, Divider, Select, Checkbox } from "antd";
import {
  ispProviderListByIdService,
  ispProviderCreateService,
  ispProviderUpdateService,
} from "services/isp_provider";

import { companiesListService } from "services/company";

import {
  handleApiSuccess,
  handleApiError,
  CountriesJson,
  StatesJson,
  getServiceAreaName,
  accessControls,
} from "utils";

import InputMask from "react-input-mask";

import {
  LockOutlined,
  PhoneOutlined,
  IdcardOutlined,
  UserOutlined,
} from "@ant-design/icons";

const { Option } = Select;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 7 },
};
const tailLayout = {
  wrapperCol: { offset: 5, span: 7 },
};

export default function IspProviderForm(props) {
  const { id } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const history = useHistory();
  const [form] = Form.useForm();
  const firstInput = useRef(null);

  useEffect(() => {
    firstInput.current.focus();
  }, []);

  useEffect(() => {
    loadPermissions();
    // IF id exist the initialize form data
    if (id) {
      ispProviderListByIdService(id)
        .then((response) => {
          setIsLoading(false);
          if (response.data.items && response.data.items.length > 0) {
            form.setFieldsValue(response.data.items[0]);
            //form.setFieldsValue({ isp_service_area: "SELECT_STATE" });
            //form.setFieldsValue({ isp_service_area_states: ["AL", "FL"] });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  }, [id, form]);

  const loadPermissions = () => {
    //********START  ACCESS CONTROL******** */
    accessControls.fetch().then((permissions) => {
      setPermissions(permissions);
    });
    //********END ACCESS CONTROL******** */
  };

  const onFinish = (values) => {
    setIsLoading(true);

    console.log("values", values);

    // Update
    if (id) {
      ispProviderUpdateService(id, values)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          history.push("/manage-isp-provider");
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    } else {
      // Create
      ispProviderCreateService(values)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          history.push("/manage-isp-provider");
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  };

  const LandlineInput = (props) => (
    <InputMask
      mask="999-999-9999"
      value={props.value}
      onChange={props.onChange}
    >
      {(inputProps) => (
        <Input
          {...inputProps}
          style={{ width: "70%" }}
          prefix={<PhoneOutlined />}
        />
      )}
    </InputMask>
  );

  const EmailInput = (props) => (
    <Input {...props} style={{ width: "70%" }} prefix={<IdcardOutlined />} />
  );

  return (
    <Form name="myForm" form={form} {...layout} onFinish={onFinish}>
      <Divider orientation="left">
        <UserOutlined /> ISP Contact Information
      </Divider>
      <Form.Item
        label="Vendor Name"
        name="provider_name"
        rules={[{ required: true, message: "Please input Vendor Name" }]}
      >
        <Input ref={firstInput} />
      </Form.Item>
      <Form.Item label="Address 1" name="isp_contact_address1">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Address 2" name="isp_contact_address2">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="City" name="isp_contact_city">
        <Input />
      </Form.Item>
      <Form.Item label="State" name="isp_contact_state">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select state"
          optionFilterProp="children"
        >
          {StatesJson.map((item, i) => (
            <Option value={item.abbreviation}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Zip Code" name="isp_contact_zip">
        <Input />
      </Form.Item>
      <Form.Item label="Country" name="isp_contact_country">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select country"
          optionFilterProp="children"
        >
          {CountriesJson.map((item, i) => (
            <Option value={item.abbreviation}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Phone" name="isp_contact_phone">
        <LandlineInput />
      </Form.Item>
      <Form.Item label="Fax" name="isp_contact_fax">
        <LandlineInput />
      </Form.Item>

      <Divider orientation="left">Support Information</Divider>

      <Form.Item label="Support Email" name="isp_support_email">
        <EmailInput />
      </Form.Item>
      <Form.Item label="Support Website" name="isp_support_website">
        <Input />
      </Form.Item>
      <Form.Item label="Support Phone Number" name="isp_support_phone_number">
        <Input />
      </Form.Item>

      <Divider orientation="left">Billing Information</Divider>

      <Form.Item label="Billing Address 1" name="isp_billing_address1">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Billing Address 2" name="isp_billing_address2">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Billing City" name="isp_billing_city">
        <Input />
      </Form.Item>
      <Form.Item label="Billing State" name="isp_billing_state">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select state"
          optionFilterProp="children"
        >
          {StatesJson.map((item, i) => (
            <Option value={item.abbreviation}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Billing Zip Code" name="isp_billing_zip">
        <Input />
      </Form.Item>
      <Form.Item label="Billing Country" name="isp_billing_country">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select country"
          optionFilterProp="children"
        >
          {CountriesJson.map((item, i) => (
            <Option value={item.abbreviation}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Billing Email" name="isp_billing_email">
        <EmailInput />
      </Form.Item>
      <Form.Item label="Billing Phone" name="isp_billing_phone">
        <LandlineInput />
      </Form.Item>

      <Divider orientation="left">Termination Information</Divider>

      <Form.Item label="Termination Email" name="isp_termination_email">
        <EmailInput />
      </Form.Item>
      <Form.Item label="Termination Phone" name="isp_termination_phone">
        <LandlineInput />
      </Form.Item>
      <Form.Item label="Sales Email" name="isp_sales_email">
        <EmailInput />
      </Form.Item>
      <Form.Item label="Sales Phone" name="isp_sales_phone">
        <LandlineInput />
      </Form.Item>

      <Divider orientation="left">Service Areas</Divider>
      <Form.Item label="Service area" name="isp_service_area">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select service area"
          optionFilterProp="children"
        >
          <Option value="NATION_WIDE">
            {getServiceAreaName("NATION_WIDE")}
          </Option>
          <Option value="SELECT_STATE">
            {getServiceAreaName("SELECT_STATE")}
          </Option>
        </Select>
      </Form.Item>
      <Form.Item shouldUpdate wrapperCol={{}}>
        {(props) => {
          return (
            <Form.Item
              hidden={form.getFieldValue("isp_service_area") !== "SELECT_STATE"}
              label="Service area states"
              name="isp_service_area_states"
              rules={[
                {
                  required:
                    form.getFieldValue("isp_service_area") === "SELECT_STATE",
                  message: "Please select state",
                },
              ]}
              {...layout}
            >
              <Select mode="multiple" allowClear placeholder="Please select">
                {StatesJson.map((item, i) => (
                  <Option value={item.abbreviation}>{item.name}</Option>
                ))}
              </Select>
            </Form.Item>
          );
        }}
      </Form.Item>

      <Divider orientation="left">Notes</Divider>
      <Form.Item label="Notes" name="notes">
        <Input.TextArea />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" loading={isLoading} htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
