import { axiosBase } from "utils";

export function listQuoteService (id) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: '/workspace-quote/' + id,
  });
}

export function deleteQuoteService (id) {
  const axios = axiosBase.create();
  return axios({
    method: "DELETE",
    url: '/workspace-quote/' + id,
  });
}