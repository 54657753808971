import { axiosBase } from 'utils';

export function cellphoneCarriersService() {
    const axios = axiosBase.create();
    return axios({
        method: 'GET',
        url: '/lookup/cellphone-carriers'
    });
}

export function statesService() {
    const axios = axiosBase.create();
    return axios({
        method: 'GET',
        url: '/lookup/states'
    });
}