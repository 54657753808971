import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import {
  PageHeader,
  Upload,
  Modal,
  message,
  Spin,
  Space,
  Button,
  Form,
  Input,
} from "antd";
import Auth from "auth";
import {
  CloudUploadOutlined,
  PlusOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import { handleApiSuccess, handleApiError, MyBreadcrumb } from "utils";
import {
  galleryViewByIdService,
  photoListByGalleryIdService,
  photoDeleteByIdService,
  photoUpdateByIdService,
} from "services/gallery";

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 },
};
const tailLayout = {
  wrapperCol: { offset: 5, span: 7 },
};

export default function ViewList(props) {
  const { id } = props.match.params;
  const [photoUploading, setPhotoUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [data, setData] = useState({});
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [form] = Form.useForm();

  const _auth = Auth;

  useEffect(() => {
    // IF id exist the initialize form data
    if (id) {
      setIsLoading(true);
      galleryViewByIdService(id)
        .then((response) => {
          setIsLoading(false);
          if (response.data.items && response.data.items.length > 0) {
            var record = response.data.items[0];
            setData(record);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
      loadPhotosList(id);
    }
  }, [id]);

  const loadPhotosList = (galleryId) => {
    setIsLoading(true);
    photoListByGalleryIdService(galleryId)
      .then((response) => {
        setIsLoading(false);
        if (response.data.items && response.data.items.length > 0) {
          let _fileList = response.data.items.map(function (item) {
            return {
              uid: item._id,
              name: item.title,
              status: "done",
              url: item.thumbnailUrl,
              title: item.title,
              description: item.description,
              priority: 0,
            };
          });
          setFileList(_fileList);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const handleChange = ({ file, fileList }) => {
    if (file.status === "uploading") {
      setPhotoUploading(true);
    }
    if (file.status === "done") {
      setPhotoUploading(false);
      loadPhotosList(id);
    }
    setFileList([...fileList]);
  };

  const handleRemove = (info) => {
    var r = window.confirm("Are you sure you want to delete this!");
    if (r === true) {
      setIsLoading(true);
      photoDeleteByIdService(info.uid)
        .then((response) => {
          setIsLoading(false);
          loadPhotosList(id); // Refresh list
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    form.setFieldsValue({
      _id: file.uid,
      title: file.title,
      description: file.description,
      priority: file.priority,
    });
  };

  const handleCancel = () => setPreviewVisible(false);

  const onFinish = (values) => {
    setIsSaving(true);
    photoUpdateByIdService(values._id, values)
      .then((response) => {
        setIsSaving(false);
        handleApiSuccess(response);
        setPreviewVisible(false);
        loadPhotosList(id); // Refresh list
      })
      .catch((error) => {
        setIsSaving(false);
        handleApiError(error);
      });
  };

  const uploadButton = (
    <div>
      {photoUploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: "Gallery", path: "/gallery" },
          { label: "upload photos", path: "" },
        ]}
      />
      <PageHeader
        ghost={false}
        avatar={{ icon: <CloudUploadOutlined /> }}
        title={`Gallery: ${data.title} Upload Photos`}
      >
        <Spin spinning={isLoading} />
        {!isLoading && (
          <>
            <div className="galleryUpload">
              <Upload
                name="photo"
                listType="picture"
                className="upload-list-inline"
                fileList={fileList}
                headers={{ Authorization: `Bearer ${_auth.getToken()}` }}
                action={
                  process.env.REACT_APP_API_BASE_URL +
                  "/gallery/upload-photo?galleryId=" +
                  data._id
                }
                beforeUpload={beforeUpload}
                onPreview={handlePreview}
                onChange={handleChange}
                onRemove={handleRemove}
              >
                <Button
                  icon={
                    photoUploading ? <LoadingOutlined /> : <UploadOutlined />
                  }
                >
                  Upload
                </Button>
              </Upload>
            </div>
            <Modal
              visible={previewVisible}
              title="Update"
              footer={null}
              onCancel={handleCancel}
            >
              <img alt="example" style={{ width: "100%" }} src={previewImage} />
              <Form name="myForm" form={form} {...layout} onFinish={onFinish}>
                <Space>&nbsp;</Space>

                <Form.Item hidden={true} label="ID" name="_id">
                  <Input rows={4} />
                </Form.Item>

                <Form.Item
                  label="Photo Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Please enter gallery title",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item label="Description" name="description">
                  <Input.TextArea rows={4} />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button type="primary" loading={isSaving} htmlType="submit">
                    Save
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
          </>
        )}
      </PageHeader>
    </div>
  );
}

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng =
    file.type === "image/gif" ||
    file.type === "image/jpg" ||
    file.type === "image/jpeg" ||
    file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/JPEG/GIF/PNG file!");
  }
  //const isLt8M = file.size / 1024 / 1024 < 8;
  const isLt8M = file.size / 1024 / 1024 < 8;
  if (!isLt8M) {
    message.error("Image must smaller than 8MB!");
  }
  return isJpgOrPng && isLt8M;
}
