import React from 'react';
import { PageHeader } from 'antd';
import { MyBreadcrumb } from 'utils';
import WorkspaceForm from './form';
export default function WorkspaceUpdate(props) {
    const { id } = props.match.params;
    return (
        <div>
            <MyBreadcrumb items={[{ label: "Workspace", path: "/workspace" }, { label: "Update", path: "/workspace/update/:id" }]} />
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title="Update"
                subTitle=""
            >
                <WorkspaceForm id={id} />
            </PageHeader>
        </div>
    );
}