import { axiosBase } from "utils";

export function usersLoginService(email, password) {
  const axios = axiosBase.create();
  return axios({
    method: "POST",
    url: "/users/login",
    data: {
      email: email,
      password: password,
    },
  });
}

export function usersListService() {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/users",
  });
}

export function usersLatestService() {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/users/latest",
  });
}

export function usersListByIdService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/users/" + id,
  });
}

export function usersDetailByIdService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/users/detail/" + id,
  });
}

export function usersCreateService(values) {
  const axios = axiosBase.create();
  return axios({
    method: "POST",
    url: "/users",
    data: {
      role: values.role,
      is_ap_manager: values.is_ap_manager,
      email: values.email,
      password: values.password,
      company: values.company,
      branch: values.branch,
      title: values.title,
      notes: values.notes,
      photo: values.photo,
      display_name: values.display_name,
      first_name: values.first_name,
      last_name: values.last_name,
      cell_phone: values.cell_phone,
      phone: values.phone,
      ext: values.ext,
      fax: values.fax,
    },
  });
}

export function usersUpdateService(id, values) {
  const axios = axiosBase.create();
  return axios({
    method: "PATCH",
    url: "/users/" + id,
    data: {
      role: values.role,
      is_ap_manager: values.is_ap_manager,
      email: values.email,
      company: values.company,
      branch: values.branch,
      title: values.title,
      notes: values.notes,
      photo: values.photo,
      display_name: values.display_name,
      first_name: values.first_name,
      last_name: values.last_name,
      cell_phone: values.cell_phone,
      phone: values.phone,
      ext: values.ext,
      fax: values.fax,
    },
  });
}

export function usersUpdatePasswordService(id, values) {
  const axios = axiosBase.create();
  return axios({
    method: "PATCH",
    url: "/users/update-password/" + id,
    data: {
      password: values.password,
    },
  });
}

export function usersSetLoginClientService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/users/set-login-client/" + id,
  });
}

export function usersSetLoginNetworkService(payload) {
  const axios = axiosBase.create();
  return axios({
    method: "POST",
    url: "/users/set-login-network/",
    data: {
      networkBranchId: payload.networkBranchId,
      networkCustomerId: payload.networkCustomerId,
    },
  });
}

export function usersRoleService(companyId) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: `/users/list-roles/${companyId ? "?companyId=" + companyId : ""}`,
  });
}

export function usersGetLoginClientService() {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/users/get-login-client/",
  });
}

export function usersGetLoginNetworkService() {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/users/get-login-network/",
  });
}

export function usersDeleteService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "DELETE",
    url: "/users/" + id,
  });
}

export function usersSearchService(q) {
  const axios = axiosBase.create();
  if (q === "") {
    return axios({
      method: "GET",
      url: "/users",
    });
  } else {
    return axios({
      method: "GET",
      url: "/users/search?q=" + q,
    });
  }
}
