import { axiosBase } from "utils";

export function salesPersonListService() {
    const axios = axiosBase.create();
    return axios({
        method: "GET",
        url: "/sales-person",
    });
}

export function salesPersonListByIdService(id) {
    const axios = axiosBase.create();
    return axios({
        method: "GET",
        url: "/sales-person/" + id,
    })
}

export function createSalesPersonService(values) {
    const axios = axiosBase.create();
    return axios({
        method: "POST",
        url: "/sales-person",
        data: {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            cell_phone: values.cell_phone,
            office_phone: values.office_phone,
            ext: values.ext,
            fax: values.fax,
            note: values.note,
        },
    });
}

export function updateSalesPersonService(id, values) {
    const axios = axiosBase.create();
    return axios({
        method: "PUT",
        url: "/sales-person/" + id,
        data: {
            first_name: values.first_name,
            last_name: values.last_name,
            email: values.email,
            cell_phone: values.cell_phone,
            office_phone: values.office_phone,
            ext: values.ext,
            fax: values.fax,
            note: values.note,
        },
    })
}

export function deleteSalesPersonService(id) {
    const axios = axiosBase.create();
    return axios({
        method: "DELETE",
        url: "/sales-person/" + id
    })
}