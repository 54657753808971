/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useRef } from "react";
import { PageHeader, Button, Typography, Space } from "antd";
import { MyBreadcrumb } from "utils";
import { PrinterOutlined } from "@ant-design/icons";
import { companyDetailByIdService } from "services/company";
import { handleApiError } from "utils";
import { useReactToPrint } from "react-to-print";

const { Title } = Typography;
const companyInfo = [];
export default function View(props) {
  const { id } = props.match.params;
  const [isLoading, setIsLoading] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});
  const componentRef = useRef(null);

  useEffect(() => {
    // IF id exist the initialize form data
    if (id) {
      companyDetailByIdService(id).then((response) => {
        setIsLoading(false);
        if (response.data.items && response.data.items.length > 0) {
          setCompanyInfo(response.data.items[0]);
        }
      });
    }
  }, [id]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: "Companies", path: "/company" },
          { label: "View", path: "" },
        ]}
      />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="View"
        subTitle=""
        extra={[
          <Button
            key="1"
            onClick={handlePrint}
            type="primary"
            shape="round"
            icon={<PrinterOutlined />}
          >
            Print
          </Button>,
        ]}
      >
        {isLoading ? "Loading..." : ""}

        <div ref={componentRef}>
          <div className="descriptionView2-heading">
            <Title level={3}>{companyInfo?.company_name}</Title>
          </div>

          <div className="descriptionView2">
            <div className="box">
              <Title level={4}>Physical Address</Title>
              {companyInfo?.physical_address && (
                <div>{companyInfo?.physical_address}</div>
              )}
              {companyInfo?.physical_suite_apt && (
                <div>{companyInfo?.physical_suite_apt}</div>
              )}
              <Space className="flex">
                {companyInfo?.physical_city && (
                  <div>{companyInfo?.physical_city},</div>
                )}
                {companyInfo?.physical_state && (
                  <div>{companyInfo?.physical_state}</div>
                )}
                {companyInfo?.physical_zip && (
                  <div>{companyInfo?.physical_zip}</div>
                )}
              </Space>
              {companyInfo?.physical_map_url && (
                <div>
                  <a target="_blank" href={companyInfo?.physical_map_url}>
                    {companyInfo?.physical_map_url}
                  </a>
                </div>
              )}
              {companyInfo?.main_phone_1 && (
                <div>{companyInfo?.main_phone_1}</div>
              )}

              {companyInfo?.location_hours && (
                <div>{companyInfo?.location_hours}</div>
              )}
            </div>

            {(companyInfo?.main_contact ||
              companyInfo?.billing_contact ||
              companyInfo?.purchase_approval_contact ||
              companyInfo?.account_number ||
              companyInfo?.sub_account_number ||
              companyInfo?.building_id ||
              companyInfo?.market_name) && (
              <div className="box">
                <Title level={4}>Account Details</Title>
                {companyInfo?.main_contact && (
                  <div>
                    Main Contact: {companyInfo?.main_contact.display_name}
                  </div>
                )}
                {companyInfo?.billing_contact && (
                  <div>
                    Billing Contact: {companyInfo?.billing_contact.display_name}
                  </div>
                )}
                {companyInfo?.purchase_approval_contact && (
                  <div>
                    Purchase Approval Contact:{" "}
                    {companyInfo?.purchase_approval_contact.display_name}
                  </div>
                )}
                <br />
                {companyInfo?.account_number && (
                  <div>
                    <strong>Account Number: </strong>
                    {companyInfo?.account_number}
                  </div>
                )}
                {companyInfo?.sub_account_number && (
                  <div>
                    <strong>Sub Account Number: </strong>
                    {companyInfo?.sub_account_number}
                  </div>
                )}
                {companyInfo?.building_id && (
                  <div>
                    <strong>Building ID: </strong>
                    {companyInfo?.building_id}
                  </div>
                )}{" "}
                {companyInfo?.market_name && (
                  <div>
                    <strong>Market Name: </strong>
                    {companyInfo?.market_name}
                  </div>
                )}
              </div>
            )}

            {(companyInfo?.shipping_address_same_as_physical_address ||
              companyInfo?.shipping_address ||
              companyInfo?.shipping_suite_apt ||
              companyInfo?.shipping_city ||
              companyInfo?.shipping_state ||
              companyInfo?.shipping_zip ||
              companyInfo?.shipping_map_url) && (
              <div className="box">
                <Title level={4}>Shipping Address</Title>
                {companyInfo?.shipping_address_same_as_physical_address && (
                  <div>
                    Same as Physical Address{" "}
                    {companyInfo?.shipping_address_same_as_physical_address}
                  </div>
                )}

                {companyInfo?.shipping_address && (
                  <div>{companyInfo?.shipping_address}</div>
                )}
                {companyInfo?.shipping_suite_apt && (
                  <div>{companyInfo?.shipping_suite_apt}</div>
                )}
                <Space className="flex">
                  {companyInfo?.shipping_city && (
                    <div>{companyInfo?.shipping_city},</div>
                  )}
                  {companyInfo?.shipping_state && (
                    <div>{companyInfo?.shipping_state}</div>
                  )}
                  {companyInfo?.shipping_zip && (
                    <div>{companyInfo?.shipping_zip}</div>
                  )}
                </Space>
                {companyInfo?.shipping_map_url && (
                  <div>
                    <a target="_blank" href={companyInfo?.shipping_map_url}>
                      {companyInfo?.shipping_map_url}
                    </a>
                  </div>
                )}
              </div>
            )}

            {(companyInfo?.billing_address_same_as_shipping_address ||
              companyInfo?.billing_address ||
              companyInfo?.billing_suite_apt ||
              companyInfo?.billing_city ||
              companyInfo?.billing_state ||
              companyInfo?.billing_zip ||
              companyInfo?.billing_map_url) && (
              <div className="box">
                <Title level={4}>Billing Address</Title>
                {companyInfo?.billing_address_same_as_shipping_address && (
                  <div>
                    Same as Shipping Address{" "}
                    {companyInfo?.billing_address_same_as_shipping_address}
                  </div>
                )}

                {companyInfo?.billing_address && (
                  <div>{companyInfo?.billing_address}</div>
                )}
                {companyInfo?.billing_suite_apt && (
                  <div>{companyInfo?.billing_suite_apt}</div>
                )}
                <Space className="flex">
                  {companyInfo?.billing_city && (
                    <div>{companyInfo?.billing_city},</div>
                  )}
                  {companyInfo?.billing_state && (
                    <div>{companyInfo?.billing_state}</div>
                  )}
                  {companyInfo?.billing_zip && (
                    <div>{companyInfo?.billing_zip}</div>
                  )}
                </Space>
                {companyInfo?.billing_map_url && (
                  <div>
                    <a target="_blank" href={companyInfo?.billing_map_url}>
                      {companyInfo?.billing_map_url}
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>

          {(companyInfo?.main_phone_1 ||
            companyInfo?.main_phone_2 ||
            companyInfo?.main_phone_3 ||
            companyInfo?.main_phone_4 ||
            companyInfo?.main_phone_5 ||
            companyInfo?.cell_phone_1 ||
            companyInfo?.cell_phone_2 ||
            companyInfo?.cell_phone_3 ||
            companyInfo?.cell_fax_1 ||
            companyInfo?.cell_fax_2 ||
            companyInfo?.cell_fax_3) && (
            <div className="descriptionView2">
              <div className="box">
                <Title level={4}>
                  <strong>Contact Details</strong>
                </Title>
                {companyInfo?.main_phone_1 && (
                  <div>
                    {companyInfo?.main_phone_1}, EXT{" "}
                    {companyInfo?.main_phone_1_ext}
                  </div>
                )}
                {companyInfo?.main_phone_2 && (
                  <div>
                    {companyInfo?.main_phone_2}, EXT{" "}
                    {companyInfo?.main_phone_2_ext}
                  </div>
                )}
                {companyInfo?.main_phone_3 && (
                  <div>
                    {companyInfo?.main_phone_3}, EXT{" "}
                    {companyInfo?.main_phone_3_ext}
                  </div>
                )}
                {companyInfo?.main_phone_4 && (
                  <div>
                    {companyInfo?.main_phone_4}, EXT{" "}
                    {companyInfo?.main_phone_4_ext}
                  </div>
                )}
                {companyInfo?.main_phone_5 && (
                  <div>
                    {companyInfo?.main_phone_5}, EXT{" "}
                    {companyInfo?.main_phone_5_ext}
                  </div>
                )}

                {companyInfo?.cell_phone_1 && (
                  <div>
                    {companyInfo?.cell_phone_1}, Carrier{" "}
                    {companyInfo?.cell_phone_1_carrier}
                  </div>
                )}
                {companyInfo?.cell_phone_2_carrier && (
                  <div>
                    {companyInfo?.cell_phone_2}, Carrier{" "}
                    {companyInfo?.cell_phone_2_carrier}
                  </div>
                )}
                {companyInfo?.cell_phone_3_carrier && (
                  <div>
                    {companyInfo?.cell_phone_3}, Carrier{" "}
                    {companyInfo?.cell_phone_3_carrier}
                  </div>
                )}

                {companyInfo?.fax_1 && <div>Fax 1: {companyInfo?.fax_1}</div>}
                {companyInfo?.fax_2 && <div>Fax 2: {companyInfo?.fax_2}</div>}
                {companyInfo?.fax_3 && <div>Fax 3: {companyInfo?.fax_3}</div>}

                {companyInfo?.location_hours && (
                  <div>Location Hours: {companyInfo?.location_hours}</div>
                )}
              </div>
            </div>
          )}
        </div>

        {companyInfo?.notes && (
          <div className="descriptionView2">
            <div className="box-row">
              <strong>Notes: </strong>
              {companyInfo?.notes ? <span>{companyInfo?.notes}</span> : "NA"}
            </div>
          </div>
        )}
      </PageHeader>
    </div>
  );
}
