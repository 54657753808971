import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Divider,
  Select,
  Checkbox,
} from 'antd';

import { usersListService } from 'services/user';

import { handleApiSuccess, handleApiError } from 'utils';
import {
  workspacePropertyCreateService,
  workspacePropertyListByIdService,
  workspacePropertyUpdateService,
} from 'services/workspace_property';
import moment from 'moment';
import { salesPersonListService } from 'services/sales_person';
const isIp = require('is-ip');

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 7 },
};
const tailLayout = {
  wrapperCol: { offset: 5, span: 7 },
};

const { Option } = Select;

const amenities = [
  'Alexa in Office',
  'Airport Location',
  'Beer on Tap',
  'Bicycle Storage',
  'Break-Out Areas',
  'Business Lounge',
  'Business Park Location',
  'Café',
  'City/Town Centre',
  'Coat/Umbrella Storage',
  'Complimentary Coffee',
  'Complimentary Tea',
  'Complimentary Coffee & Tea',
  'Complementary Fruit-infused Water, Coffee, & Tea',
  'Complementary Cookies',
  'Conference Rooms',
  'Contactless Entry',
  'Copier',
  'Directory Board Entry',
  'Downtown',
  'Echo Dot in Office',
  'Elevator',
  'Faster Internet Options',
  'Free Wi-Fi',
  'Fully Furnished',
  'Gym and Fitness Room',
  'Handicap Accessible',
  'Historic District',
  'Live Receptionist',
  'Live Call Answering',
  'Lockers',
  'Logo in Lobby',
  'Logo on Door',
  'Mail Room',
  'Major Transport Connections',
  'Meeting Rooms',
  'Monitor Leasing',
  'On-site Lunch Restaurant',
  'On-site Restaurant',
  'On-site Sandwich / Coffee Bar',
  'On-site Networking Events',
  'On-site Professional Development Events',
  'Outside Seating Area / Terrace',
  'Parking',
  'Pet Friendly',
  'Phones',
  'Phone Booths',
  'Printer',
  'Projectors',
  'Quiet Rooms',
  'Rail/Tram Access',
  'Sandwich Service',
  'Security Cameras',
  '24-hour Surveillance Monitoring',
  'Secure Underground Parking',
  'Shipping Services',
  'Showers',
  'Speakers in Offices',
  'Suspended Ceilings',
  'Theater District',
  'TVs in Conference Rooms',
  'TV in Office',
  'TV Service',
  'Umbrella Storage',
  'Valet Parking',
  'Vending Machines',
  'Virtual Offices',
  'Wellness Rooms',
  'Whiteboards',
];

const spaceTypes = [
  'Conference Rooms',
  'Coworking',
  'Day Offices',
  'Event/Party Space',
  'Full Floors',
  'Fully Furnished',
  'Hot Desks – Dedicated',
  'Hot Desks – Non-Dedicated',
  'Hourly Offices',
  'Interview Rooms',
  'Long Term Office Leases',
  'Lounge Space',
  'Meeting Rooms',
  'Multi-Day Passes',
  'Podcast Rooms',
  'Private Offices',
  'Suites Large & Small',
  'Team Rooms',
  'Training Rooms',
  'Traditional Office Space',
  'Virtual Offices',
  'Webcast Rooms',
];

const techAmenities = [
  "Bandwidth Upgrades",
  "Call Answering",
  "Phone Services",
  "Private VLAN",
  "Public IPs"
]

export default function CreateUpdateForm(props) {
  const { id } = props;
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    salesPersonListService().then((response) => {
      if (response.data.items && response.data.items.length > 0) {
        setUsers(response.data.items);
      }
    });
  }, []);

  useEffect(() => {
    form.setFieldValue('workspace_location', params.workspaceLocationId);
    form.setFieldValue('workspace_slug', params.workspaceSlug);
    // IF id exist the initialize form data
    if (id) {
      workspacePropertyListByIdService(id)
        .then((response) => {
          setIsLoading(false);
          if (response.data.item) {
            const record = response.data.item;
            form.setFieldsValue({
              title: record.title,
              description: record.description,
              number_of_people: record.number_of_people,
              price: record.price,
              sales_agent: record.sales_agent._id,
              amenities: record.amenities,
              space: record.space,
              space_types: record.space_types,
              technology: record.technology,
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  }, [id, form]);

  const onFinish = (values) => {
    // Update
    if (id) {
      console.log(values);
      workspacePropertyUpdateService(id, values)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response); //params.workspaceLocationId
          history.push(`/workspace/${params.workspaceLocationId}/${params.workspaceSlug}/property`);
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    } else {
      // Create
      workspacePropertyCreateService(values)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          history.push(`/workspace/${params.workspaceLocationId}/property`);
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  };

  return (
    <Form name="myForm" form={form} {...layout} onFinish={onFinish}>
      <div
        style={{
          backgroundColor: '#FAFAFA',
          border: '1px solid #EAEAEA',
          padding: '30px 5px 5px 5px',
          marginBottom: 30,
        }}
      >
        <Form.Item
          label="Workspace location"
          name="workspace_location"
          hidden={true}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Workspace slug"
          name="workspace_slug"
          hidden={true}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Title" name="title" required>
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Number of people" name="number_of_people" required>
          <InputNumber min={0} max={10000} />
        </Form.Item>
        <Form.Item label="Price" name="price">
          <InputNumber
            min={0}
            max={10000}
            defaultValue={1000}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
      </div>

      <Form.Item
        name="sales_agent"
        label="Sales Agent"
        rules={[{ required: true, message: 'Please select sales agent' }]}
      >
        <Select
          autoComplete="sales_agent"
          showSearch
          placeholder="Select a sale agent"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value="">Select a sale agent</Option>
          {users.map((item, i) => (
            <Option value={item._id}>{item.first_name} {item.last_name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Divider orientation="left">Amenities</Divider>
      <Form.Item label="Amenities" name="amenities">
        <Checkbox.Group
          style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr' }}
          options={amenities}
        />
      </Form.Item>
      <Divider orientation="left">Space</Divider>
      <Form.Item label="Space" name="space">
        <Input.TextArea placeholder='ex. 325 sq.ft' />
      </Form.Item>
      <Divider orientation="left">Space Types</Divider>
      <Form.Item label="Space Types" name="space_types">
        <Checkbox.Group
          style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr' }}
          options={spaceTypes}
        />
      </Form.Item>
      <Divider orientation="left">Technical Amenities</Divider>
      <Form.Item label="Technical Amenities" name="technology">
        <Checkbox.Group
          style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr' }}
          options={techAmenities}
        />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" loading={isLoading} htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
