/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useRef } from "react";
import {
  PageHeader,
  Button,
  Drawer,
  Form,
  Input,
  Table,
  Space,
  Modal,
  InputNumber,
  Tooltip,
  Select,
} from "antd";
import {
  MyBreadcrumb,
  axiosBase,
  handleApiSuccess,
  handleApiError,
} from "utils";
import {
  UserOutlined,
  EditOutlined,
  DeleteOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import { companyDetailByIdService } from "services/company";

import AccessControlManagement from "utils/AccessControlManagement";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export default function Role(props) {
  const axios = axiosBase.create();
  const { id } = props.match.params;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [editInfo, setEditInfo] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataSource, setDataSource] = useState(null);

  const formEl = useRef(null);

  const roleColumns = [
    {
      title: "Role",
      dataIndex: "role_value",
      key: "role_value",
    },
    {
      title: "Base Role",
      dataIndex: "base_role",
      key: "base_role",
      width: 200,
      render: (text, record) => {
        console.log(record);
        switch (record.base_role) {
          case 40:
            return `40 - Company Admin`;
          case 50:
            return `50 - Branch Admin`;
          default:
            return record.base_role;
        }
      },
    },
    {
      title: "Role Name",
      dataIndex: "role_name",
      key: "role_name",
      width: 250,
      render: (text, record) => {
        return record.role_name;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip key="Edit" title="View">
            <Button
              onClick={(e) => {
                editRole(record);
              }}
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip key="View role" title="View">
            <Button
              onClick={(e) => {
                loadRoleAndResources(record);
              }}
              type="primary"
              shape="circle"
              icon={<KeyOutlined />}
            />
          </Tooltip>
          <Tooltip title="Delete role">
            <Button
              onClick={(e) => {
                deleteRole(record);
              }}
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    // IF id exist the initialize form data
    if (id) {
      companyDetailByIdService(id).then((response) => {
        setIsLoading(false);
        if (response.data.items && response.data.items.length > 0) {
          setCompanyInfo(response.data.items[0]);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    loadDataSource();
  }, []);

  const loadRoleAndResources = (record) => {
    setEditInfo(record);
    showDrawer();
  };

  const loadDataSource = () => {
    setIsLoading(true);
    axios({
      method: "GET",
      url: "/role/company/" + id,
    })
      .then((response) => {
        setIsLoading(false);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        } else {
          setDataSource([]);
        }
        handleApiSuccess(response);
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmitButton = () => {
    formEl.current && formEl.current.submit();
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const deleteRole = (record) => {
    setIsSubmitting(true);
    // Create
    axios({
      method: "DELETE",
      url: "/role/" + record._id,
    })
      .then((response) => {
        setIsLoading(false);
        handleApiSuccess(response);
        loadDataSource();
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const editRole = (record) => {
    setIsModalOpen(true);
    form.setFieldValue("_id", record._id);
    form.setFieldValue("base_role", record.base_role);
    form.setFieldValue("role_name", record.role_name);
  };

  const onFinish = (formData) => {
    setIsSubmitting(true);
    // Create
    axios({
      method: "POST",
      url: "/role",
      data: { ...formData, company: id },
    })
      .then((response) => {
        setIsLoading(false);
        form.resetFields();
        handleCancel();
        handleApiSuccess(response);
        loadDataSource();
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: "Companies", path: "/company" },
          { label: "View", path: "" },
        ]}
      />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={`${companyInfo?.company_name} Custom Roles`}
        subTitle=""
        extra={[
          <Button
            key="1"
            onClick={() => showModal()}
            type="primary"
            shape="round"
          >
            Create New
          </Button>,
        ]}
      >
        {isLoading ? "Loading..." : ""}

        <div></div>
      </PageHeader>
      <Table
        pagination={false}
        loading={isLoading}
        columns={roleColumns}
        dataSource={dataSource}
      />
      <Drawer
        title={`${editInfo.role_name} Roles Management`}
        placement="right"
        size={"large"}
        onClose={() => onClose()}
        open={open}
      >
        <AccessControlManagement role={editInfo.role_value} />
      </Drawer>

      <Modal
        title={!form.getFieldValue("_id") ? "Create Role" : "Update Role"}
        open={isModalOpen}
        onOk={handleSubmitButton}
        okText={!form.getFieldValue("_id") ? "Create New" : "Update"}
        onCancel={handleCancel}
      >
        <Form
          ref={formEl}
          name="myForm"
          form={form}
          {...layout}
          onFinish={onFinish}
        >
          <Form.Item label="ID" name="_id" noStyle>
            <Input type="hidden" />
          </Form.Item>

          <Form.Item label="Base role" name="base_role">
            <Select placeholder="Select base role">
              <Select.Option value={40}>40 - Company Admin</Select.Option>
              <Select.Option value={50}>50 - Branch Admin</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Role Name"
            name="role_name"
            rules={[{ required: true, message: "Please enter role name" }]}
            help={
              <p style={{ fontSize: 13, marginTop: 8 }}>
                The Role Name is a friendly name for an access control role, you
                can give a name like `Accountant` `Security guard` .etc"
              </p>
            }
          >
            <Input size="large" prefix={<UserOutlined />} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
