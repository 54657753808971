import { axiosBase } from "utils";

export function workspaceListByCompanyService(id) {
    const axios = axiosBase.create();
    return axios({
        method: "GET",
        url: "/branches/company/" + id,
    });
}

export function workspaceCreateService(values) {
    const axios = axiosBase.create();
    return axios({
        method: "POST",
        url: "/workspace-location",
        data: {
            company: values.company,
            branch: values.branch,
            slug: values.slug,
            market_name: values.market_name,
            opened: values.opened,
            voicemail: values.voicemail,
            internet_service_provider: values.internet_service_provider,
            cheapest_office: values.cheapest_office,
            amenities: values.amenities,
            space_types: values.space_types,
        },
    });
}

export function workspaceListService() {
    const axios = axiosBase.create();
    return axios({
        method: "GET",
        url: "/workspace-location",
    });
}

export function workspaceListByIdService(id) {
    const axios = axiosBase.create();
    return axios({
        method: "GET",
        url: "/workspace-location/" + id,
    });
}

export function workspaceUpdateService(id, values) {
    const axios = axiosBase.create();
    return axios({
        method: "PUT",
        url: "/workspace-location/" + id,
        data: {
            company: values.company,
            branch: values.branch,
            slug: values.slug,
            market_name: values.market_name,
            opened: values.opened,
            voicemail: values.voicemail,
            internet_service_provider: values.internet_service_provider,
            cheapest_office: values.cheapest_office,
            amenities: values.amenities,
            space_types: values.space_types,
        },
    });

}

export function workspaceDeleteService(id){
    const axios = axiosBase.create();
    return axios({
        method: "DELETE",
        url: "/workspace-location/" + id,
    });
}