import React from 'react';
import { Input, Row, Col, Divider, Form, Button } from 'antd';

import { TagOutlined, DollarCircleOutlined, RightOutlined } from '@ant-design/icons';

function Clock() {
    return (
        <div>
            <div className="site-layout-background meter">
                <Form>
                    <Row justify="space-between">
                        <Col span={8}>
                            <Form.Item
                                name="task"
                                rules={[{ required: true, message: "Please enter task or Project info" }]}
                            >
                                <Input placeholder="Task @Project" />
                            </Form.Item>

                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="description"
                                rules={[{ required: true, message: "Please enter task description" }]}
                            >
                                <Input placeholder="What are you working on?" />
                            </Form.Item>

                        </Col>
                        <Col span={1} style={{ textAlign: "center" }}>
                            <TagOutlined style={{ fontSize: 24 }} />
                        </Col>
                        <Col span={1} style={{ textAlign: "center" }}>
                            <DollarCircleOutlined style={{ fontSize: 24 }} />
                        </Col>
                        <Col span={2} style={{ textAlign: "center" }}>
                            00:00:00
                </Col>
                        <Col span={2}>
                            <Form.Item>
                                <Button shape="round" type="primary" htmlType="submit">Start <RightOutlined /></Button>
                            </Form.Item>

                        </Col>
                    </Row>
                </Form>
            </div>
            <Divider orientation="left" style={{ color: '#333', fontWeight: 'normal' }}></Divider>
        </div>
    );
}

export default Clock;