import { axiosBase } from 'utils';

export function clientsListService() {
    const axios = axiosBase.create();
    return axios({
        method: 'GET',
        url: '/clients',
    });
}

export function clientsListByIdService(id) {
    const axios = axiosBase.create();
    return axios({
        method: 'GET',
        url: '/clients/' + id,
    });
}

export function clientsCreateService(values) {
    const axios = axiosBase.create();
    return axios({
        method: 'POST',
        url: '/clients',
        data: {
            email: values.email,
            client_name: values.client_name,
            address: values.address,
            suite_apt: values.suite_apt,
            city: values.city,
            state: values.state,
            zip: values.zip,
            phone: values.phone,
            ext: values.ext,
            fax: values.fax
        }
    });
}

export function clientsUpdateService(id, values) {
    const axios = axiosBase.create();
    return axios({
        method: 'PATCH',
        url: '/clients/' + id,
        data: {
            email: values.email,
            client_name: values.client_name,
            address: values.address,
            suite_apt: values.suite_apt,
            city: values.city,
            state: values.state,
            zip: values.zip,
            phone: values.phone,
            ext: values.ext,
            fax: values.fax
        }
    });
}

export function clientsDeleteService(id) {
    const axios = axiosBase.create();
    return axios({
        method: 'DELETE',
        url: '/clients/' + id,
    });
}

export function clientsSearchService(q) {
    const axios = axiosBase.create();
    if (q === "") {
        return axios({
            method: 'GET',
            url: '/clients',
        });
    } else {
        return axios({
            method: 'GET',
            url: '/clients/search?q=' + q,
        });
    }
}

