import React, { useState } from "react";

import { PageHeader, Menu, Descriptions, Table, Empty, Typography } from "antd";
import Swal from "sweetalert2";
import { MyBreadcrumb, handleApiError, handleApiSuccess } from "utils";
import { axiosBase } from "utils";

import { DatabaseOutlined } from "@ant-design/icons";
import { set } from "lodash";

const mapLabels = {
  id: "ID",
  username: "Username",
  value: "Password",
  pass: "Password",
  reply: "Response",
  authdate: "Date",
  nasipaddress: "Onsite Device",
  calledstationid: "Device MAC & SSID",
  nasporttype: "Device Type",
  acctstarttime: "Date",
  callingstationid: "User MAC",
  acctterminatecause: "Terminate Cause",
  servicetype: "Service Type",
  framedprotocol: "Framed Protocol",
  framedipaddress: "IP Address",
};

const checkPasswordColumns = [
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Password",
    dataIndex: "value",
    key: "value",
  },
];

const loginAttemptsColumns = [
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Password",
    dataIndex: "value",
    key: "value",
  },
  {
    title: "Response",
    dataIndex: "reply",
    key: "reply",
  },
  {
    title: "Date",
    dataIndex: "authdate",
    key: "authdate",
  },
];

const ipDetailsColumns = [
  {
    title: "Onsite Device",
    dataIndex: "nasipaddress",
    key: "nasipaddress",
  },
  {
    title: "Device MAC & SSID",
    dataIndex: "calledstationid",
    key: "calledstationid",
  },
  {
    title: "Device Type",
    dataIndex: "nasporttype",
    key: "nasporttype",
  },
  {
    title: "Date",
    dataIndex: "acctstarttime",
    key: "acctstarttime",
  },
  {
    title: "User MAC",
    dataIndex: "callingstationid",
    key: "callingstationid",
  },
  {
    title: "Terminate Cause",
    dataIndex: "acctterminatecause",
    key: "acctterminatecause",
  },
  {
    title: "Service Type",
    dataIndex: "servicetype",
    key: "servicetype",
  },
  /*
  {
    title: "Framed Protocol",
    dataIndex: "framedprotocol",
    key: "framedprotocol",
  },
  */
  {
    title: "IP Address",
    dataIndex: "framedipaddress",
    key: "framedipaddress",
  },
];

export default function Index() {
  const axios = axiosBase.create();
  const [items, setItems] = useState([]);
  const [type, setType] = useState("checkPassword");
  const [username, setUsername] = useState("");

  const onClick = async (e) => {
    if (!username || username === "") {
      const { value: username } = await Swal.fire({
        title: "Input username",
        input: "text",
        inputLabel: "Wi-Fi username",
        inputPlaceholder: "Enter username",
      });
      if (username) {
        setType(e.key);
        setUsername(username);
        loadReport(e.key, username);
      }
    } else {
      setType(e.key);
      loadReport(e.key, username);
    }
  };

  const loadReport = (type, username) => {
    axios({
      method: "GET",
      url: `/raddb-reports?type=${type}&username=${username}`,
    })
      .then((response) => {
        handleApiSuccess(response);
        setItems(response.data.items);
      })
      .catch((error) => {
        setItems([]);
        handleApiError(error);
      });
  };

  const clearAllReports = () => {
    window.location.reload();
  };

  return (
    <>
      <MyBreadcrumb
        items={[
          { label: "Home", path: "/" },
          { label: "Login Attempt Information", path: "" },
        ]}
      />
      <PageHeader
        ghost={false}
        title={`Login Attempt Information`}
        onBack={username && username !== "" ? () => clearAllReports() : null}
        subTitle={
          username && username !== "" ? (
            <Typography.Title
              style={{ margin: 0, padding: 0, color: "#666" }}
              level={4}
            >{`Login Attempt Information for ${username}`}</Typography.Title>
          ) : (
            ""
          )
        }
        style={{ minHeight: "100vh" }}
      >
        <Menu onClick={onClick} mode="horizontal">
          <Menu.Item key="checkPassword" icon={<DatabaseOutlined />}>
            Check Password
          </Menu.Item>
          <Menu.Item key="loginAttempts" icon={<DatabaseOutlined />}>
            Login Attempts
          </Menu.Item>
          <Menu.Item key="ipDetails" icon={<DatabaseOutlined />}>
            Show IP Details
          </Menu.Item>
        </Menu>

        <div style={{ marginTop: 20 }}>
          {items.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ paddingTop: 30 }}
            />
          ) : null}

          {type === "checkPassword" && items.length > 0 ? (
            <Table dataSource={items} columns={checkPasswordColumns} />
          ) : null}

          {type === "loginAttempts" && items.length > 0 ? (
            <Table dataSource={items} columns={loginAttemptsColumns} />
          ) : null}

          {type === "ipDetails" && items.length > 0 ? (
            <Table dataSource={items} columns={ipDetailsColumns} />
          ) : null}
        </div>
        <div style={{ clear: "both" }}>&nbsp;</div>
      </PageHeader>
    </>
  );
}
