import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Space,
  Button,
  Form,
  Input,
  Divider,
  Select,
  DatePicker,
  Typography,
} from "antd";

import { branchesListByCompanyIdService } from "services/branch";

import {
  handleApiSuccess,
  handleApiError,
  CountriesJson,
  StatesJson,
  axiosBase,
  getServiceAreaName,
} from "utils";

import InputMask from "react-input-mask";

import {
  LockOutlined,
  ShopOutlined,
  PhoneOutlined,
  IdcardOutlined,
  UserOutlined,
} from "@ant-design/icons";

import moment from "moment";

const { Option } = Select;

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 7 },
};
const tailLayout = {
  wrapperCol: { offset: 5, span: 7 },
};

export default function UtilityProvidersForm(props) {
  const axios = axiosBase.create();
  const { id } = props;

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();

  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [utilityProviders, setUtilityProviders] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // IF id exist the initialize form data

    if (id && axios && form && props) {
      axios({
        method: "GET",
        url: "/utility-provider/" + id,
      })
        .then((response) => {
          setIsLoading(false);
          if (response.data.item) {
            let formData = response.data.item;

            if (formData.contract_start !== "") {
              formData.contract_start = moment(formData.contract_start);
            }
            if (formData.contract_end !== "") {
              formData.contract_end = moment(formData.contract_end);
            }

            form.setFieldsValue(formData);
            // If company was set for category then pull list of branches
            if (response.data.item.company) {
              branchesListByCompanyIdService(response.data.item.company)
                .then((response) => {
                  setBranches(response.data.items);
                })
                .catch((error) => {
                  handleApiError(error);
                });
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  }, [id]);

  useEffect(() => {
    console.log("useEffect-****************");
    //Load companies dropdown
    axios({
      method: "GET",
      url: "/companies",
    }).then((response) => {
      if (response.data.items && response.data.items.length > 0) {
        setCompanies(response.data.items);
      }
    });

    axios({
      method: "GET",
      url: "/utility-provider",
    }).then((response) => {
      if (response.data.items && response.data.items.length > 0) {
        setUtilityProviders(response.data.items);
      }
    });

    //Load users dropdown
    axios({
      method: "GET",
      url: "/users/ap-managers",
    }).then((response) => {
      if (response.data.items && response.data.items.length > 0) {
        setUsers(response.data.items);
      }
    });
  }, []);

  const onFinish = (values) => {
    //setIsLoading(true);

    if (id) {
      // Update
      axios({
        method: "PATCH",
        url: "/utility-provider/" + id,
        data: values,
      })
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          window.scrollTo(0, 0);
          history.push("/utility-providers");
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    } else {
      // Create
      axios({
        method: "POST",
        url: "/utility-provider",
        data: values,
      })
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          history.goBack();
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  };

  const LandlineInput = (props) => (
    <InputMask
      mask="999-999-9999"
      value={props.value}
      onChange={props.onChange}
    >
      {(inputProps) => (
        <Input
          {...inputProps}
          style={{ width: "70%" }}
          prefix={<PhoneOutlined />}
        />
      )}
    </InputMask>
  );

  const EmailInput = (props) => (
    <Input {...props} style={{ width: "70%" }} prefix={<IdcardOutlined />} />
  );

  const onCompanyChange = (value) => {
    branchesListByCompanyIdService(value)
      .then((response) => {
        setBranches(response.data.items);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  return (
    <Form name="myForm" form={form} {...layout} onFinish={onFinish}>
      <Form.Item
        label="Company"
        name="company"
        rules={[{ required: true, message: "Please select Company" }]}
      >
        <Select
          autoComplete="nope"
          showSearch
          placeholder="Select a company"
          optionFilterProp="children"
          onChange={(value) => onCompanyChange(value)}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value="">Select</Option>
          {companies.map((item, i) => (
            <Option value={item._id}>{item.company_name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Branch"
        name="branch"
        rules={[{ required: true, message: "Please select Company & Branch" }]}
      >
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value="">Select</Option>
          {branches.map((item, i) => (
            <Option value={item._id}>{item.branch_name}</Option>
          ))}
        </Select>
      </Form.Item>

      <div
        style={{
          backgroundColor: "#FAFAFA",
          border: "1px solid #EAEAEA",
          padding: "30px 5px 5px 5px",
          marginBottom: 30,
        }}
      >
        <Divider orientation="left">
          <UserOutlined /> Utility Provider
        </Divider>
        <Form.Item
          label="Utility Provider"
          name="utility_provider"
          rules={[
            { required: true, message: "Please select Utility Provider" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Sales Agent Email" name="sales_agent_email">
          <EmailInput />
        </Form.Item>
        <Form.Item label="Sales Agent Phone" name="sales_agent_phone">
          <LandlineInput />
        </Form.Item>
        <Form.Item label="Sales Agent Name" name="sales_agent_name">
          <Input />
        </Form.Item>
        <Form.Item label="Notes" name="notes">
          <Input.TextArea />
        </Form.Item>

        <Divider orientation="left">
          <UserOutlined /> Provider's - Contact Information
        </Divider>

        <Form.Item label="Address 1" name="provider_contact_address1">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Address 2" name="provider_contact_address2">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="City" name="provider_contact_city">
          <Input />
        </Form.Item>
        <Form.Item label="State" name="provider_contact_state">
          <Select
            autoComplete="nope"
            showSearch
            style={{ width: 200 }}
            placeholder="Select state"
            optionFilterProp="children"
          >
            {StatesJson.map((item, i) => (
              <Option value={item.abbreviation}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Zip Code" name="provider_contact_zip">
          <Input />
        </Form.Item>
        <Form.Item label="Country" name="provider_contact_country">
          <Select
            autoComplete="nope"
            showSearch
            style={{ width: 200 }}
            placeholder="Select country"
            optionFilterProp="children"
          >
            {CountriesJson.map((item, i) => (
              <Option value={item.abbreviation}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Phone" name="provider_contact_phone">
          <LandlineInput />
        </Form.Item>
        <Form.Item label="Fax" name="provider_contact_fax">
          <LandlineInput />
        </Form.Item>

        <Divider orientation="left">Provider's - Support Information</Divider>

        <Form.Item label="Support Email" name="provider_support_email">
          <EmailInput />
        </Form.Item>
        <Form.Item label="Support Website" name="provider_support_website">
          <Input />
        </Form.Item>
        <Form.Item label="Support Phone Number" name="provider_support_phone">
          <Input />
        </Form.Item>

        <Divider orientation="left">Provider's - Billing Information</Divider>

        <Form.Item label="Billing Address 1" name="provider_billing_address1">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Billing Address 2" name="provider_billing_address2">
          <Input.TextArea />
        </Form.Item>
        <Form.Item label="Billing City" name="provider_billing_city">
          <Input />
        </Form.Item>
        <Form.Item label="Billing State" name="provider_billing_state">
          <Select
            autoComplete="nope"
            showSearch
            style={{ width: 200 }}
            placeholder="Select state"
            optionFilterProp="children"
          >
            {StatesJson.map((item, i) => (
              <Option value={item.abbreviation}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Billing Zip Code" name="provider_billing_zip">
          <Input />
        </Form.Item>
        <Form.Item label="Billing Country" name="provider_billing_country">
          <Select
            autoComplete="nope"
            showSearch
            style={{ width: 200 }}
            placeholder="Select country"
            optionFilterProp="children"
          >
            {CountriesJson.map((item, i) => (
              <Option value={item.abbreviation}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Billing Email" name="provider_billing_email">
          <EmailInput />
        </Form.Item>
        <Form.Item label="Billing Phone" name="provider_billing_phone">
          <LandlineInput />
        </Form.Item>

        <Divider orientation="left">
          Provider's - Termination Information
        </Divider>

        <Form.Item label="Termination Email" name="provider_termination_email">
          <EmailInput />
        </Form.Item>
        <Form.Item label="Termination Phone" name="provider_termination_phone">
          <LandlineInput />
        </Form.Item>
        <Form.Item label="Sales Email" name="provider_termination_sales_email">
          <EmailInput />
        </Form.Item>
        <Form.Item label="Sales Phone" name="provider_termination_sales_phone">
          <LandlineInput />
        </Form.Item>

        <Divider orientation="left">Provider's - Service Areas</Divider>
        <Form.Item label="Service area" name="provider_service_area">
          <Select
            autoComplete="nope"
            showSearch
            style={{ width: 200 }}
            placeholder="Select service area"
            optionFilterProp="children"
          >
            <Option value="NATION_WIDE">
              {getServiceAreaName("NATION_WIDE")}
            </Option>
            <Option value="SELECT_STATE">
              {getServiceAreaName("SELECT_STATE")}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate wrapperCol={{}}>
          {(props) => {
            return (
              <Form.Item
                hidden={
                  form.getFieldValue("provider_service_area") !== "SELECT_STATE"
                }
                label="Service area states"
                name="provider_service_area_states"
                rules={[
                  {
                    required:
                      form.getFieldValue("provider_service_area") ===
                      "SELECT_STATE",
                    message: "Please select state",
                  },
                ]}
                {...layout}
              >
                <Select mode="multiple" allowClear placeholder="Please select">
                  {StatesJson.map((item, i) => (
                    <Option value={item.abbreviation}>{item.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            );
          }}
        </Form.Item>

        <Divider orientation="left">Provider's - Notes</Divider>
        <Form.Item label="Notes" name="provider_notes">
          <Input.TextArea />
        </Form.Item>
      </div>

      <Divider orientation="left">
        <LockOutlined /> Branch Profile
      </Divider>
      <Space>&nbsp;</Space>

      <Form.Item label="Account Number" name="account_number">
        <Input />
      </Form.Item>
      <Form.Item label="AP Vendor ID" name="ap_vendor_id">
        <Input />
      </Form.Item>
      <Form.Item label="AP Service Code" name="ap_service_code">
        <Input />
      </Form.Item>
      <Form.Item label="Status" name="status">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select"
          optionFilterProp="children"
        >
          <Option value="ACTIVE">Active</Option>
          <Option value="INACTIVE_TERMINATED">Inactive/Terminated</Option>
          <Option value="TO_BE_RENEWED">To Be Renewed</Option>
          <Option value="TO_BE_CANCELED">To Be Cancelled</Option>
          <Option value="ACTIVE_PRIMARY">Active Primary</Option>
          <Option value="ACTIVE_SECONDARY">Active Secondary</Option>
        </Select>
      </Form.Item>
      <Form.Item label="Auto-Pay" name="auto_pay">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select"
          optionFilterProp="children"
        >
          <Option value="YES">Yes</Option>
          <Option value="NO">No</Option>
        </Select>
      </Form.Item>

      <Form.Item label="Payment Timeline" name="payment_timeline">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select"
          optionFilterProp="children"
        >
          <Option value="DAILY">Daily</Option>
          <Option value="WEEKLY">Weekly</Option>
          <Option value="MONTHLY">Monthly</Option>
          <Option value="QUARTERLY">Quarterly</Option>
          <Option value="YEARLY">Yearly</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="Where Invoice Sent"
        name="where_invoice_sent"
        help="ex. Mailed to Property, Mailed to PO Box, Email ap@company.com"
      >
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="AP Manager" name="ap_manager">
        <Select
          autoComplete="nope"
          showSearch
          placeholder="Select"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value="">Select</Option>
          {users.map((item, i) => (
            <Option value={item._id}>{item.display_name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Circuit ID / Meter ID" name="circuit_id_meter_id">
        <Input />
      </Form.Item>

      <Form.Item label="Referral Partner" name="referral_partner">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select"
          optionFilterProp="children"
        >
          <Option value="YES">Yes</Option>
          <Option value="NO">No</Option>
        </Select>
      </Form.Item>

      <Form.Item label="Referred By" name="referred_by">
        <Select
          autoComplete="nope"
          showSearch
          placeholder="Select"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value="">Select</Option>
          {utilityProviders.map((item, i) => (
            <Option value={item._id}>{item.utility_provider}</Option>
          ))}
        </Select>
      </Form.Item>

      <Divider orientation="left">
        <UserOutlined /> Notices Address
      </Divider>
      <Form.Item label="Address 1" name="notices_address1">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Address 2" name="notices_address2">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="City" name="notices_city">
        <Input />
      </Form.Item>
      <Form.Item label="State" name="notices_state">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select state"
          optionFilterProp="children"
        >
          {StatesJson.map((item, i) => (
            <Option value={item.abbreviation}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Zip Code" name="notices_zip">
        <Input />
      </Form.Item>
      <Form.Item label="Country" name="notices_country">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select country"
          optionFilterProp="children"
        >
          {CountriesJson.map((item, i) => (
            <Option value={item.abbreviation}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Divider orientation="left">
        <ShopOutlined /> Contract Information
      </Divider>
      <Space>&nbsp;</Space>
      <Form.Item label="Monthly Fee" name="monthly_fee">
        <Input />
      </Form.Item>
      <Form.Item label="Contract Start" name="contract_start">
        <DatePicker />
      </Form.Item>
      <Form.Item label="Contract End" name="contract_end">
        <DatePicker />
      </Form.Item>
      <Form.Item label="Installation Fee" name="installation_fee">
        <Input />
      </Form.Item>
      <Form.Item label="Contract Signed By" name="contract_signedby">
        <Input />
      </Form.Item>
      <Form.Item label="SLA" name="sla">
        <Input />
      </Form.Item>
      <Divider orientation="left">
        <ShopOutlined /> Credentials
      </Divider>
      <Space>&nbsp;</Space>
      <Form.Item label="Primary on Account" name="primary_on_account">
        <Input />
      </Form.Item>
      <Form.Item label="PIN" name="pin">
        <Input />
      </Form.Item>
      <Form.Item label="Security Question" name="security_question">
        <Input />
      </Form.Item>
      <Form.Item label="Phone Number" name="phone_number">
        <LandlineInput />
      </Form.Item>
      <Form.Item label="Username" name="username">
        <Input />
      </Form.Item>
      <Form.Item label="Password" name="password">
        <Input />
      </Form.Item>
      <Form.Item label="Website" name="website">
        <Input />
      </Form.Item>
      <Form.Item label="Email" name="email">
        <EmailInput />
      </Form.Item>
      <Divider orientation="left">
        <UserOutlined /> Location
      </Divider>
      <Form.Item label="Location Name" name="location_name">
        <Input />
      </Form.Item>

      <Form.Item label="Node ID" name="node_id">
        <Input />
      </Form.Item>
      <Divider orientation="left">
        <ShopOutlined /> Billing Address
      </Divider>
      <Form.Item label="Billing Name" name="billing_name">
        <Input />
      </Form.Item>
      <Form.Item label="Billing Address 1" name="billing_address1">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Billing Address 2" name="billing_address2">
        <Input.TextArea />
      </Form.Item>
      <Form.Item label="Billing City" name="billing_city">
        <Input />
      </Form.Item>
      <Form.Item label="Billing State" name="billing_state">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select state"
          optionFilterProp="children"
        >
          {StatesJson.map((item, i) => (
            <Option value={item.abbreviation}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Billing Zip Code" name="billing_zip">
        <Input />
      </Form.Item>
      <Form.Item label="Billing Country" name="billing_country">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select country"
          optionFilterProp="children"
        >
          {CountriesJson.map((item, i) => (
            <Option value={item.abbreviation}>{item.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Billing Contact" name="billing_contact">
        <Input />
      </Form.Item>
      <Form.Item label="Billing Phone" name="billing_phone">
        <LandlineInput />
      </Form.Item>
      <Form.Item label="Billing Email" name="billing_email">
        <Input />
      </Form.Item>
      <Form.Item label="Billing Fax" name="billing_fax">
        <LandlineInput />
      </Form.Item>
      <Divider orientation="left">
        <ShopOutlined /> Site Information
      </Divider>
      <Form.Item
        label="Service Equipment Location"
        name="service_equipment_location"
      >
        <Input />
      </Form.Item>
      <Space>&nbsp;</Space>

      <Form.Item {...tailLayout}>
        <Button type="primary" loading={isLoading} htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
