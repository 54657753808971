import { axiosBase } from "utils";

export function branchesListService() {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/branches",
  });
}

export function branchesLatestService() {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/branches/latest",
  });
}

export function branchesListByIdService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/branches/" + id,
  });
}

export function branchesListByClientIdService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/branches/client/" + id,
  });
}

export function branchesListByCompanyIdService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/branches/company/" + id,
  });
}

export function branchesCreateService(values) {
  const axios = axiosBase.create();
  return axios({
    method: "POST",
    url: "/branches",
    data: {
      company: values.company,
      branch_name: values.branch_name,

      account_number: values.account_number,
      sub_account_number: values.sub_account_number,
      building_id: values.building_id,
      market_name: values.market_name,

      main_contact: values.main_contact,
      billing_contact: values.billing_contact,
      purchase_approval_contact: values.purchase_approval_contact,

      physical_address: values.physical_address,
      physical_suite_apt: values.physical_suite_apt,
      physical_city: values.physical_city,
      physical_state: values.physical_state,
      physical_zip: values.physical_zip,
      physical_map_url: values.physical_map_url,

      shipping_address_same_as_physical_address:
        values.shipping_address_same_as_physical_address,
      shipping_address: values.shipping_address,
      shipping_suite_apt: values.shipping_suite_apt,
      shipping_city: values.shipping_city,
      shipping_state: values.shipping_state,
      shipping_zip: values.shipping_zip,
      shipping_map_url: values.shipping_map_url,

      billing_address_same_as_shipping_address:
        values.billing_address_same_as_shipping_address,
      billing_address: values.billing_address,
      billing_suite_apt: values.billing_suite_apt,
      billing_city: values.billing_city,
      billing_state: values.billing_state,
      billing_zip: values.billing_zip,
      billing_map_url: values.billing_map_url,

      notes: values.notes,
      website: values.website,

      main_phone_1: values.main_phone_1,
      main_phone_2: values.main_phone_2,
      main_phone_3: values.main_phone_3,
      main_phone_4: values.main_phone_4,
      main_phone_5: values.main_phone_5,

      main_phone_1_ext: values.main_phone_1_ext,
      main_phone_2_ext: values.main_phone_2_ext,
      main_phone_3_ext: values.main_phone_3_ext,
      main_phone_4_ext: values.main_phone_4_ext,
      main_phone_5_ext: values.main_phone_5_ext,

      cell_phone_1: values.cell_phone_1,
      cell_phone_2: values.cell_phone_2,
      cell_phone_3: values.cell_phone_3,

      cell_phone_1_carrier: values.cell_phone_1_carrier,
      cell_phone_2_carrier: values.cell_phone_2_carrier,
      cell_phone_3_carrier: values.cell_phone_3_carrier,

      fax_1: values.fax_1,
      fax_2: values.fax_2,
      fax_3: values.fax_3,
      location_hours: values.location_hours,

      shipping_company: values.shipping_company,
      shipping_company_start_time: values.shipping_company_start_time,
      shipping_company_start_time_am_pm:
        values.shipping_company_start_time_am_pm,
      shipping_company_stop_time: values.shipping_company_stop_time,
      shipping_company_stop_time_am_pm: values.shipping_company_stop_time_am_pm,
    },
  });
}

export function branchesUpdateService(id, values) {
  const axios = axiosBase.create();
  return axios({
    method: "PATCH",
    url: "/branches/" + id,
    data: {
      company: values.company,
      branch_name: values.branch_name,

      account_number: values.account_number,
      sub_account_number: values.sub_account_number,
      building_id: values.building_id,
      market_name: values.market_name,

      main_contact: values.main_contact,
      billing_contact: values.billing_contact,
      purchase_approval_contact: values.purchase_approval_contact,

      physical_address: values.physical_address,
      physical_suite_apt: values.physical_suite_apt,
      physical_city: values.physical_city,
      physical_state: values.physical_state,
      physical_zip: values.physical_zip,
      physical_map_url: values.physical_map_url,

      shipping_address_same_as_physical_address:
        values.shipping_address_same_as_physical_address,
      shipping_address: values.shipping_address,
      shipping_suite_apt: values.shipping_suite_apt,
      shipping_city: values.shipping_city,
      shipping_state: values.shipping_state,
      shipping_zip: values.shipping_zip,
      shipping_map_url: values.shipping_map_url,

      billing_address_same_as_shipping_address:
        values.billing_address_same_as_shipping_address,
      billing_address: values.billing_address,
      billing_suite_apt: values.billing_suite_apt,
      billing_city: values.billing_city,
      billing_state: values.billing_state,
      billing_zip: values.billing_zip,
      billing_map_url: values.billing_map_url,

      notes: values.notes,
      website: values.website,

      main_phone_1: values.main_phone_1,
      main_phone_2: values.main_phone_2,
      main_phone_3: values.main_phone_3,
      main_phone_4: values.main_phone_4,
      main_phone_5: values.main_phone_5,

      main_phone_1_ext: values.main_phone_1_ext,
      main_phone_2_ext: values.main_phone_2_ext,
      main_phone_3_ext: values.main_phone_3_ext,
      main_phone_4_ext: values.main_phone_4_ext,
      main_phone_5_ext: values.main_phone_5_ext,

      cell_phone_1: values.cell_phone_1,
      cell_phone_2: values.cell_phone_2,
      cell_phone_3: values.cell_phone_3,

      cell_phone_1_carrier: values.cell_phone_1_carrier,
      cell_phone_2_carrier: values.cell_phone_2_carrier,
      cell_phone_3_carrier: values.cell_phone_3_carrier,

      fax_1: values.fax_1,
      fax_2: values.fax_2,
      fax_3: values.fax_3,

      location_hours: values.location_hours,

      shipping_company: values.shipping_company,
      shipping_company_start_time: values.shipping_company_start_time,
      shipping_company_start_time_am_pm:
        values.shipping_company_start_time_am_pm,
      shipping_company_stop_time: values.shipping_company_stop_time,
      shipping_company_stop_time_am_pm: values.shipping_company_stop_time_am_pm,
      users_acl: values.users_acl, 
    },
  });
}

export function branchesDeleteService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "DELETE",
    url: "/branches/" + id,
  });
}

export function branchesSearchService(q) {
  const axios = axiosBase.create();
  if (q === "") {
    return axios({
      method: "GET",
      url: "/branches",
    });
  } else {
    return axios({
      method: "GET",
      url: "/branches/search?q=" + q,
    });
  }
}
