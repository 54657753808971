import { axiosBase } from "utils";

export function getCallReportService (url) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: url,
  });
}

export function getCallReportStatsService () {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/call-report/stats",
  });
}

export function getCallRecordingService (uuid) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    params: {uuid},
    url: "/call-report/recording",
  });
}