import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Form, Input, Select, Switch } from "antd";
import {
  categoriesCreateService,
  categoriesParentListService,
  categoriesListByIdService,
  categoriesUpdateService,
  categoriesListService,
} from "services/category";
import { companiesListService } from "services/company";
import { branchesListByCompanyIdService } from "services/branch";

import { handleApiSuccess, handleApiError } from "utils";

const { Option } = Select;

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 8 },
};
const tailLayout = {
  wrapperCol: { offset: 3, span: 8 },
};

export default function CategoriesForm(props) {
  const { id } = props;

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [isThisParentCategory, setIsThisParentCategory] = useState(false);

  useEffect(() => {
    // IF id exist the initialize form data
    if (id) {
      categoriesListByIdService(id)
        .then((response) => {
          setIsLoading(false);
          if (response.data.items && response.data.items.length > 0) {
            console.log("response.data.items", response.data.items);
            //return false;

            var record = response.data.items[0];
            record.is_this_parent_category = record.parent ? false : true;
            if (record.parent) {
              record["parent"] = record.parent._id;
            } else {
              record["parent"] = null;
            }
            //delete(record['parent']);
            form.setFieldsValue(record);
            setIsThisParentCategory(record.is_this_parent_category);
            // If company was set for category then pull list of branches
            if (response.data.items[0].company) {
              branchesListByCompanyIdService(response.data.items[0].company)
                .then((response) => {
                  setBranches(response.data.items);
                })
                .catch((error) => {
                  handleApiError(error);
                });
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }

    categoriesParentListService().then((response) => {
      if (response.data.items && response.data.items.length > 0) {
        setCategories(response.data.items);
      }
    });

    //Load companies dropdown
    companiesListService().then((response) => {
      if (response.data.items && response.data.items.length > 0) {
        setCompanies(response.data.items);
      }
    });
  }, [id, form]);

  const onFinish = (values) => {
    setIsLoading(true);

    // Update
    if (id) {
      categoriesUpdateService(id, values)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          history.push("/" + props.type + "/categories");
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    } else {
      // Create
      switch (props.type) {
        case "knowledge-base":
          values.type = "KNOWLEDGE_BASE";
          break;
        default:
          values.type = "";
          break;
      }
      categoriesCreateService(values)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          history.push("/" + props.type + "/categories");
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  };

  const onCompanyChange = (value) => {
    branchesListByCompanyIdService(value)
      .then((response) => {
        setBranches(response.data.items);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  function isThisParentCategoryOnChange(checked) {
    setIsThisParentCategory(checked);
  }

  return (
    <Form name="myForm" form={form} {...layout} onFinish={onFinish}>
      <div
        style={{
          backgroundColor: "#FAFAFA",
          border: "1px solid #EAEAEA",
          padding: "30px 5px 5px 5px",
          marginBottom: 30,
        }}
      >
        <Form.Item label="Company" name="company">
          <Select
            autoComplete="company"
            showSearch
            placeholder="Select a company"
            optionFilterProp="children"
            onChange={(value) => onCompanyChange(value)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="">Select a company</Option>
            {companies.map((item, i) => (
              <Option value={item._id}>{item.company_name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Branch" name="branch">
          <Select
            autoComplete="branch"
            showSearch
            style={{ width: 200 }}
            placeholder="Select a branch"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="">Select a branch</Option>
            {branches.map((item, i) => (
              <Option value={item._id}>{item.branch_name}</Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <Form.Item
        label="This is parent category"
        name="is_this_parent_category"
        valuePropName="checked"
      >
        <Switch onChange={isThisParentCategoryOnChange} />
      </Form.Item>

      {!isThisParentCategory && (
        <Form.Item
          label="Parent Category"
          name="parent"
          rules={[
            {
              required: !isThisParentCategory,
              message: "Please select parent category",
            },
          ]}
        >
          <Select
            autoComplete="category"
            showSearch
            placeholder="Select a category"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option key="default" value="">
              Select a parent category
            </Option>
            {categories.map((item, i) => (
              <Option key={i} value={item._id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item
        label="Category Name"
        name="name"
        rules={[{ required: true, message: "Please input category name" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Description" name="description">
        <Input.TextArea />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" loading={isLoading} htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
