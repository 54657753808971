import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  PageHeader,
  Typography,
  Space,
  Spin,
  Input,
  Table,
  Tooltip,
  Button,
} from "antd";

import { ReadOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import {
  handleApiError,
  handleApiSuccess,
  MyBreadcrumb,
  accessControls,
} from "utils";
import { categoriesListService } from "services/category";
import {
  knowledgeBaseSearchService,
  knowledgeBaseDeleteService,
} from "services/knowledge_base";

import { groupBy, debounce, filter } from "lodash";
const { Search } = Input;

export default function KnowledgeBaseView() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesOriginal, setCategoriesOriginal] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const { Title } = Typography;

  useEffect(() => {
    const columns = [
      {
        title: "Post Title",
        dataIndex: "title",
        key: "title",
        defaultSortOrder: "ascend",
        sorter: function (a, b, sortOrder) {
          return a.title.localeCompare(b.title);
        },
        render: (text, record) => {
          return (
            <Link to={"/knowledge-base/view/post-" + record._id}>
              <h4>{record.title}</h4>
            </Link>
          );
        },
      },
      {
        title: "ID",
        dataIndex: "_id",
        key: "_id",
        className: "hide",
        sorter: function (a, b, sortOrder) {
          return a._id.localeCompare(b._id);
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <Space size="middle">
            {accessControls.can(permissions, "KBPost.update:any") && (
              <Tooltip title="Edit">
                <Button
                  size="small"
                  onClick={(e) => {
                    history.push("/knowledge-base/update/" + record._id);
                  }}
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                />
              </Tooltip>
            )}
            {accessControls.can(permissions, "KBPost.delete:any") && (
              <Tooltip title="Disable">
                <Button
                  size="small"
                  onClick={(e) => deleteRecord(record._id)}
                  shape="circle"
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            )}
          </Space>
        ),
      },
    ];
    setTableColumns(columns);
  }, [permissions, history]);

  useEffect(() => {
    loadCategories();
    loadPermissions();
  }, []);

  const loadPermissions = () => {
    //********START  ACCESS CONTROL******** */
    accessControls.fetch().then((permissions) => {
      console.log(permissions);
      setPermissions(permissions);
    });
    //********END ACCESS CONTROL******** */
  };

  const deleteRecord = (id) => {
    setIsLoading(true);
    knowledgeBaseDeleteService(id)
      .then((response) => {
        setIsLoading(false);
        handleApiSuccess(response);
        history.push("/knowledge-base");
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const searchRecord = (q) => {
    setIsLoading(true);
    setSearchQuery(q);
    if (q == "") {
      setDataSource([]);
      setIsLoading(false);
      return false;
    }
    knowledgeBaseSearchService(q)
      .then((response) => {
        setIsLoading(false);
        handleApiSuccess(response);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        } else {
          setDataSource([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const filterCategories = async (e) => {
    const q = e.target.value;
    const reArrange = [];

    if (q !== "") {
      for await (const [key, value] of Object.entries(categoriesOriginal)) {
        let result = await filter(value, function (item) {
          //return startsWith(q.toLowerCase(), item.name.toLowerCase());
          return item.name.toLowerCase().indexOf(q.toLowerCase()) !== -1;
        });
        reArrange[key] = result;
      }
      setCategories(reArrange);
    } else {
      setCategories(categoriesOriginal);
    }
  };

  const delayedQuery = debounce((q) => searchRecord(q), 500);
  const onChange = (e) => {
    delayedQuery(e.target.value);
  };

  const loadCategories = () => {
    setIsLoading(true);
    categoriesListService()
      .then((response) => {
        setIsLoading(false);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          let data = groupBy(response.data.items, (item) => {
            if (item.parent) {
              return item.parent.name;
            }
          });
          delete data[undefined];
          setCategories(data);
          setCategoriesOriginal(data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: "Knowledge Base Categories", path: "/knowledge-base" },
        ]}
      />
      <PageHeader
        ghost={false}
        avatar={{ icon: <ReadOutlined /> }}
        title="Knowledge Base"
        subTitle={
          <Search
            key={0}
            name="posts_search"
            allowClear={true}
            onChange={onChange}
            loading={isLoading}
            placeholder="Search KB posts"
            onSearch={(value) => searchRecord(value)}
            style={{ width: 200 }}
          />
        }
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Spin spinning={isLoading} />

          {searchQuery !== "" && (
            <Table
              dataSource={dataSource}
              columns={tableColumns}
              loading={isLoading}
              rowKey="_id"
            />
          )}

          {searchQuery === "" && (
            <div>
              <Title level={3}>List of Categories & Sub Categories</Title>
              <Space
                direction="vertical"
                style={{ width: "100%" }}
                size="middle"
              >
                <Search
                  style={{ maxWidth: "300px" }}
                  onChange={(value) => filterCategories(value)}
                  placeholder="Start typing category name to filter"
                />
                {Object.keys(categories).map((key, index) => (
                  <Table
                    key={key + index}
                    rowKey={(record) => record._id}
                    dataSource={categories[key]}
                    pagination={{
                      pageSize: 5,
                      size: "small",
                      position: ["bottomLeft"],
                      hideOnSinglePage: true,
                    }}
                    columns={[
                      {
                        key: "name",
                        title: key,
                        dataIndex: "name",
                        defaultSortOrder: "ascend",
                        sorter: function (a, b, sortOrder) {
                          return a.name.localeCompare(b.name);
                        },
                        render: (text, record) => {
                          return (
                            <Link
                              to={
                                "/knowledge-base/manage/category-" + record._id
                              }
                            >
                              {record.name || "N/A"}
                            </Link>
                          );
                        },
                      },
                    ]}
                  />
                ))}
              </Space>
            </div>
          )}
        </Space>
      </PageHeader>
    </div>
  );
}
