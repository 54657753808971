import React, { useEffect, useState, useRef } from 'react';

import { useHistory } from 'react-router-dom';

import { Table, Space, Tooltip, Button, PageHeader, Input, Avatar } from 'antd';

import {
  EditOutlined,
  DeleteOutlined,
  LockOutlined,
  PlusCircleOutlined,
  TeamOutlined,
  UserOutlined,
  PrinterOutlined,
  EyeOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  handleApiSuccess,
  handleApiError,
  MyBreadcrumb,
  accessControls,
  confirmDelete,
} from 'utils';
import {
  usersListService,
  usersDeleteService,
  usersSearchService,
} from 'services/user';

import { debounce, filter } from 'lodash';
import Swal from 'sweetalert2';

const { Search } = Input;

export default function UsersList() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [permissions, setPermissions] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => {
      return searchedColumn === dataIndex && searchText && searchText !== '' ? (
        <span
          style={{
            backgroundColor: '#ffc069',
          }}
        >
          {text}
        </span>
      ) : (
        text
      );
    },
  });

  const columns = [
    {
      title: 'Photo',
      key: 'photo',
      render: (text, record) => {
        if (record.photo !== '') {
          return <Avatar src={record.photo} />;
        } else {
          return <Avatar icon={<UserOutlined />} />;
        }
      },
    },

    {
      title: 'ID',
      dataIndex: '_id',
      key: '_id',
      className: 'hide',
      //defaultSortOrder: "descend",
      sorter: function (a, b, sortOrder) {
        return a._id.localeCompare(b._id);
      },
    },
    {
      title: 'Display Name',
      dataIndex: 'display_name',
      key: 'display_name',
      sorter: function (a, b, sortOrder) {
        a = a.display_name || '';
        b = b.display_name || '';
        return a.localeCompare(b);
      },
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      render: (text, record) =>
        record.company ? record.company.company_name : null,
      sorter: function (a, b, sortOrder) {
        a = a?.company?.company_name || '';
        b = b?.company?.company_name || '';
        return a.localeCompare(b);
      },
    },
    {
      title: 'Branch',
      dataIndex: 'branch',
      key: 'branch',
      render: (text, record) =>
        record.branch ? record.branch.branch_name : null,
      sorter: function (a, b, sortOrder) {
        a = a?.branch?.branch_name || '';
        b = b?.branch?.branch_name || '';
        return a.localeCompare(b);
      },
    },

    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      ...getColumnSearchProps('role'),
    },
    {
      title: 'Email/Username',
      dataIndex: 'email',
      key: 'email',
      sorter: function (a, b, sortOrder) {
        a = a.email || '';
        b = b.email || '';
        return a.localeCompare(b);
      },
    },
    {
      title: 'Cell Phone',
      dataIndex: 'cell_phone',
      key: 'cell_phone',
      sorter: function (a, b, sortOrder) {
        a = a.cell_phone || '';
        b = b.cell_phone || '';
        return a.localeCompare(b);
      },
    },
    {
      title: 'Third-Party Credentials',
      dataIndex: '',
      key: 'Third-Party-credentials',
      align: 'center',
      render: (text, record) => {
        return <a href={`/third-party-credentials?uid=${record._id}`}>View</a>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {accessControls.can(permissions, 'User.read:any') && (
            <Tooltip key="view" title="View">
              <Button
                onClick={(e) => {
                  history.push('/users/view/' + record._id);
                }}
                type="primary"
                shape="circle"
                icon={<EyeOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, 'User.update:any') && (
            <Tooltip title="Edit">
              <Button
                onClick={(e) => {
                  history.push('/users/update/' + record._id);
                }}
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, 'User.update:any') && (
            <Tooltip title="Change Password">
              <Button
                onClick={(e) => {
                  history.push('/users/update-password/' + record._id);
                }}
                shape="circle"
                icon={<LockOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, 'User.delete:any') && (
            <Tooltip title="Disable">
              <Button
                onClick={(e) => deleteRecord(record._id)}
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    loadData();
    loadPermissions();
  }, []);

  const loadPermissions = () => {
    //********START  ACCESS CONTROL******** */
    accessControls.fetch().then((permissions) => {
      console.log(permissions);
      setPermissions(permissions);
    });
    //********END ACCESS CONTROL******** */
  };

  const loadData = () => {
    setIsLoading(true);
    usersListService()
      .then((response) => {
        setIsLoading(false);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          // change role to string
          const dataItems = response.data.items.map((item) => {
            switch (item.role) {
              case 10:
                item.role = `10 - Super Admin`;
                break;
              case 12:
                item.role = `12 - Workspace Managers`;
                break;
              case 30:
                item.role = `30 - Reseller Non-Admin and Reseller Admin`;
                break;
              case 40:
                item.role = `40 - Customer Company Admin`;
                break;
              case 50:
                item.role = `50 - Customer Branch Admin`;
                break;
              case 60:
                item.role = `60 - Customer Non Admin`;
                break;
              default:
                item.role = item.role + ' - Custom Role';
            }
            return item;
          });
          setDataSource(dataItems);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const deleteRecord = (id) => {
    confirmDelete().then(() => {
      setIsLoading(true);
      usersDeleteService(id)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          loadData();
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    });
  };

  const searchRecord = (q) => {
    setIsLoading(true);
    usersSearchService(q)
      .then((response) => {
        setIsLoading(false);
        handleApiSuccess(response);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        } else {
          setDataSource([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const delayedQuery = debounce((q) => searchRecord(q), 500);
  const onChange = (e) => {
    delayedQuery(e.target.value);
  };

  return (
    <div>
      <MyBreadcrumb items={[{ label: 'Users', path: '' }]} />
      <PageHeader
        ghost={false}
        avatar={{ icon: <TeamOutlined /> }}
        title="Users"
        subTitle={
          <Search
            allowClear={true}
            onChange={onChange}
            loading={isLoading}
            placeholder="Filter search result"
            onSearch={(value) => searchRecord(value)}
            style={{ width: 200 }}
          />
        }
        extra={[
          <Button
            key="create"
            shape="round"
            onClick={(e) => history.push('/users/print')}
            type="primary"
            icon={<PrinterOutlined />}
          >
            Print View
          </Button>,
          <>
            {accessControls.can(permissions, 'User.create:any') ? (
              <Button
                key="create"
                shape="round"
                onClick={(e) => history.push('/users/create')}
                type="primary"
                icon={<PlusCircleOutlined />}
              >
                Add New
              </Button>
            ) : (
              ''
            )}
          </>,
        ]}
      ></PageHeader>
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
        rowKey="_id"
      />
    </div>
  );
}
