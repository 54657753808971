import { axiosBase } from 'utils';

export function qosoptionsListService() {
    const axios = axiosBase.create();
    return axios({
        method: 'GET',
        url: '/qosoptions',
    });
}

export function qosoptionsListByIdService(id) {
    const axios = axiosBase.create();
    return axios({
        method: 'GET',
        url: '/qosoptions/' + id,
    });
}

export function qosoptionsCreateService(values) {
    const axios = axiosBase.create();
    return axios({
        method: 'POST',
        url: '/qosoptions',
        data: {
            qosstring: values.qosstring,
            qosdevice: values.qosdevice
        }
    });
}

export function qosoptionsUpdateService(id, values) {
    const axios = axiosBase.create();
    return axios({
        method: 'PATCH',
        url: '/qosoptions/' + id,
        data: {
            qosstring: values.qosstring,
            qosdevice: values.qosdevice
        }
    });
}

export function qosoptionsDeleteService(id) {
    const axios = axiosBase.create();
    return axios({
        method: 'DELETE',
        url: '/qosoptions/' + id,
    });
}

export function qosoptionsSearchService(q) {
    const axios = axiosBase.create();
    if (q === "") {
        return axios({
            method: 'GET',
            url: '/qosoptions',
        });
    } else {
        return axios({
            method: 'GET',
            url: '/qosoptions/search?q=' + q,
        });
    }
}