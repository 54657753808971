import React from "react";
import { PageHeader } from "antd";
import { MyBreadcrumb } from "utils";
import IspForm from "./form";
export default function Create() {
  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: "Service Providers", path: "/service-providers" },
          { label: "Create", path: "" },
        ]}
      />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Create"
        subTitle=""
      >
        <IspForm />
      </PageHeader>
    </div>
  );
}
