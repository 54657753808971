import React, { useEffect, useState } from 'react';

import { useHistory, Link } from 'react-router-dom';
import { useParams } from 'react-router';

import {
  Table,
  Space,
  Tooltip,
  Button,
  PageHeader,
  Input,
  Typography,
} from 'antd';

import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  TeamOutlined,
  FileImageOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';

import {
  handleApiSuccess,
  handleApiError,
  MyBreadcrumb,
  accessControls,
  confirmDelete,
  formatBytes,
} from 'utils';
import { branchesSearchService } from 'services/branch';

import { debounce, set } from 'lodash';

import {
  workspaceUploadListService,
  workspaceUploadDeleteService,
} from 'services/workspace_uploads';

const { Text } = Typography;

const { Search } = Input;

export default function WorkspaceList() {
  const history = useHistory();
  const params = useParams();
  const { objectId, objectType } = params;

  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      key: '_id',
      //defaultSortOrder: "descend",
      className: 'hide',
      sorter: function (a, b, sortOrder) {
        return a._id.localeCompare(b._id);
      },
    },

    {
      title: 'Document Type',
      dataIndex: 'document_type',
      key: 'document_type',
      render: (text, record) => {
        switch (text) {
          case 'PICTURE':
            return <Text>Picture</Text>;
          case 'FLOOR_PLAN':
            return <Text>Floor Plan</Text>;
          case 'COLLATERAL':
            return <Text>Collateral</Text>;
          case 'FEATURED_PIC':
            return <Text>Featured Picture</Text>;
          default:
            return <Text>{text}</Text>;
        }
      },
    },
    {
      title: 'File Type',
      dataIndex: 'file_type',
      key: 'file_type',
      render: (text, record) =>
        text === 'pdf' || text === 'doc' ? (
          <Link to={{ pathname: record?.file_url }} target="_blank">
            <Space>
              <FilePdfOutlined
                size={64}
                style={{ color: 'red', fontSize: 24 }}
              />
              <Text>PDF</Text>
            </Space>
          </Link>
        ) : (
          <Link to={{ pathname: record?.file_url }} target="_blank">
            <Space>
              <FileImageOutlined
                size={64}
                style={{ color: 'green', fontSize: 24 }}
              />
              <Text>Picture</Text>
            </Space>
          </Link>
        ),
    },
    {
      title: 'Size',
      dataIndex: 'file_size',
      key: 'file_size',
      style: { color: 'green' },
      render: (text, record) => (
        <Text type="success">{formatBytes(record.file_size)}</Text>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: function (a, b) {
        a = a.market_name || '';
        b = b.market_name || '';
        return a.localeCompare(b);
      },
    },

    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {accessControls.can(permissions, 'WorkspaceManagement.read:any') && (
            <Tooltip title="Edit">
              <Button
                onClick={(e) => {
                  history.push(
                    `/workspace/${objectId}/${objectType}/upload/update/${record._id}`,
                  );
                }}
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, 'WorkspaceManagement.read:any') && (
            <Tooltip title="Disable">
              <Button
                onClick={(e) => deleteRecord(record._id, objectType)}
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    loadData();
    loadPermissions();
  }, []);

  const loadPermissions = () => {
    //********START  ACCESS CONTROL******** */
    accessControls.fetch().then((permissions) => {
      permissions['Workspace' + '.' + 'read:any'] = true;
      permissions['Workspace' + '.' + 'create:any'] = true;
      permissions['Workspace' + '.' + 'update:any'] = true;
      permissions['Workspace' + '.' + 'delete:any'] = true;
      console.log(permissions);
      setPermissions(permissions);
    });
    //********END ACCESS CONTROL******** */
  };

  const loadData = () => {
    setIsLoading(true);
    workspaceUploadListService(objectId, objectType)
      .then((response) => {
        setIsLoading(false);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
          console.log(response.data.items);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const deleteRecord = (id) => {
    confirmDelete().then(() => {
      setIsLoading(true);
      workspaceUploadDeleteService(id)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          loadData();
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    });
  };

  const searchRecord = (q) => {
    setIsLoading(true);
    branchesSearchService(q)
      .then((response) => {
        setIsLoading(false);
        handleApiSuccess(response);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        } else {
          setDataSource([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setDataSource([]);
        handleApiError(error);
      });
  };

  const delayedQuery = debounce((q) => searchRecord(q), 500);

  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: 'Workspace', path: '/workspace' },
          { label: 'Uploads', path: '' },
        ]}
      />
      <PageHeader
        ghost={false}
        avatar={{ icon: <TeamOutlined /> }}
        title="Uploads"
        extra={
          accessControls.can(permissions, 'WorkspaceManagement.read:any')
            ? [
                <Button
                  shape="round"
                  onClick={(e) =>
                    history.push(
                      `/workspace/${objectId}/${objectType}/upload/create`,
                    )
                  }
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Add New
                </Button>,
              ]
            : ''
        }
      ></PageHeader>
      <Table dataSource={dataSource} columns={columns} loading={isLoading} />
    </div>
  );
}
