import { axiosBase } from "utils";

export function thirdPartyCredentialsListService() {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/third-party-credentials",
  });
}

export function thirdPartyCredentialsListWithPasswordsService() {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/third-party-credentials/with-passwords",
  });
}

export function thirdPartyCredentialsListByIdService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/third-party-credentials/" + id,
  });
}

export function thirdPartyCredentialsListByIdEditReadService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/third-party-credentials/" + id + "/edit-read",
  });
}

export function thirdPartyCredentialsCreateService(values) {
  const axios = axiosBase.create();
  return axios({
    method: "POST",
    url: "/third-party-credentials",
    data: {
      user: values.user,
      title: values.title,
      login_url: values.login_url,
      username: values.username,
      password: values.password,
      pin: values.pin,
      notes: values.notes,
      renewal_date: values.renewal_date,
    },
  });
}

export function thirdPartyCredentialsUpdateService(id, values) {
  const axios = axiosBase.create();
  return axios({
    method: "PATCH",
    url: "/third-party-credentials/" + id,
    data: {
      user: values.user,
      title: values.title,
      login_url: values.login_url,
      username: values.username,
      password: values.password,
      pin: values.pin,
      isPrivate: values.isPrivate,
      notes: values.notes,
      renewal_date: values.renewal_date,
    },
  });
}

export function thirdPartyCredentialsDeleteService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "DELETE",
    url: "/third-party-credentials/" + id,
  });
}

export function thirdPartyCredentialsShowPasswordService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/third-party-credentials/" + id + "/show-password",
  });
}

export function thirdPartyCredentialsSearchService(q, showPasswords) {
  const axios = axiosBase.create();
  let queryString = [];
  if (q && q !== "") {
    queryString.push(`q=${q}`);
  }
  if (showPasswords) {
    queryString.push(`showPasswords=true`);
  }
  queryString = queryString.join("&");
  return axios({
    method: "GET",
    url: `/third-party-credentials?${queryString}`,
  });
}
