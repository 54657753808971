import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import {
  Table,
  Space,
  Tooltip,
  Checkbox,
  Button,
  PageHeader,
  Input,
} from 'antd';

import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  TeamOutlined,
  UploadOutlined,
  MessageOutlined
} from '@ant-design/icons';

import {
  handleApiSuccess,
  handleApiError,
  MyBreadcrumb,
  accessControls,
  confirmDelete,
} from 'utils';
import { branchesSearchService } from 'services/branch';

import { debounce } from 'lodash';
import {
  workspacePropertyListService,
  workspacePropertyDeleteService,
} from 'services/workspace_property';

const { Search } = Input;

export default function List() {
  const history = useHistory();
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [permissions, setPermissions] = useState([]);

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      key: '_id',
      //defaultSortOrder: "descend",
      className: 'hide',
      sorter: function (a, b, sortOrder) {
        return a && b ? a._id.localeCompare(b._id) : null;
      },
    },

    {
      title: 'Property Title',
      dataIndex: 'title',
      key: 'title',
      sorter: function (a, b) {
        a = a.title || '';
        b = b.title || '';
        return a && b ? a.localeCompare(b) : null;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {accessControls.can(permissions, 'WorkspaceManagement.read:any') && (
            <Tooltip
              key="quotes"
              title="View Quotes for this property"
            >
              <Button
                onClick={(e) => {
                  history.push(
                    `/workspace/${params.workspaceLocationId}/property/quotes/${record._id}`,
                  );
                }}
                type="primary"
                shape="circle"
                icon={<MessageOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, 'WorkspaceManagement.read:any') && (
            <Tooltip
              key="upload"
              title="Attach document & pictures to this property"
            >
              <Button
                onClick={(e) => {
                  history.push(`/workspace/${record._id}/property/upload`);
                }}
                type="primary"
                shape="circle"
                icon={<UploadOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, 'WorkspaceManagement.read:any') && (
            <Tooltip title="Edit">
              <Button
                onClick={(e) => {
                  history.push(
                    `/workspace/${params.workspaceLocationId}/${params.workspaceSlug}/property/update/${record._id}`,
                  );
                }}
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, 'WorkspaceManagement.read:any') && (
            <Tooltip title="Disable">
              <Button
                onClick={(e) => deleteRecord(record._id)}
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    loadData();
    loadPermissions();
  }, []);

  const loadPermissions = () => {
    //********START  ACCESS CONTROL******** */
    accessControls.fetch().then((permissions) => {
      permissions['Workspace' + '.' + 'read:any'] = true;
      permissions['Workspace' + '.' + 'create:any'] = true;
      permissions['Workspace' + '.' + 'update:any'] = true;
      permissions['Workspace' + '.' + 'delete:any'] = true;
      console.log(permissions);
      setPermissions(permissions);
    });
    //********END ACCESS CONTROL******** */
  };

  const loadData = () => {
    setIsLoading(true);
    workspacePropertyListService(params.workspaceLocationId)
      .then((response) => {
        console.log(response.data);
        setIsLoading(false);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
          console.log(response.data.items);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const deleteRecord = (id) => {
    confirmDelete().then(() => {
      setIsLoading(true);
      workspacePropertyDeleteService(id)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          loadData();
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    });
  };

  const searchRecord = (q) => {
    setIsLoading(true);
    branchesSearchService(q)
      .then((response) => {
        setIsLoading(false);
        handleApiSuccess(response);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        } else {
          setDataSource([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setDataSource([]);
        handleApiError(error);
      });
  };

  const delayedQuery = debounce((q) => searchRecord(q), 500);
  const onChange = (e) => {
    delayedQuery(e.target.value);
  };

  return (
    <div>
      <MyBreadcrumb items={[{ label: 'Workspace property', path: '' }]} />
      <PageHeader
        ghost={false}
        avatar={{ icon: <TeamOutlined /> }}
        title="Workspace property"
        subTitle={
          <Search
            allowClear={true}
            onChange={onChange}
            loading={isLoading}
            placeholder="Filter search result"
            onSearch={(value) => searchRecord(value)}
            style={{ width: 200 }}
          />
        }
        extra={
          accessControls.can(permissions, 'WorkspaceManagement.read:any')
            ? [
                <Button
                  shape="round"
                  onClick={(e) =>
                    history.push(
                      `/workspace/${params.workspaceLocationId}/${params.workspaceSlug}/property/create`,
                    )
                  }
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Add New
                </Button>,
              ]
            : ''
        }
      ></PageHeader>
      <Table dataSource={dataSource} columns={columns} loading={isLoading} />
    </div>
  );
}
