import React from "react";
import { PageHeader } from "antd";
import { MyBreadcrumb } from "utils";
import IspProviderForm from "./form";
export default function IspProviderUpdate(props) {
  const { id } = props.match.params;
  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: "ISP Vendor", path: "/manage-isp-provider" },
          { label: "Update", path: "" },
        ]}
      />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Update"
        subTitle=""
      >
        <IspProviderForm id={id} />
      </PageHeader>
    </div>
  );
}
