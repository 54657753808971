import { axiosBase } from "utils";

export function knowledgeBaseListService() {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/knowledge-base",
  });
}

export function knowledgeBaseListServiceByCategoryId(id) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/knowledge-base/category/" + id,
  });
}

export function knowledgeBaseViewListServiceByCategoryId(id) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/knowledge-base/category/" + id + "?master=true",
  });
}

export function knowledgeBaseListByIdService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/knowledge-base/" + id,
  });
}

export function knowledgeBaseViewListByIdService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "GET",
    url: "/knowledge-base/" + id + "?master=true",
  });
}

export function knowledgeBaseCreateService(values) {
  const axios = axiosBase.create();
  return axios({
    method: "POST",
    url: "/knowledge-base",
    data: {
      title: values.title,
      slug: values.slug,
      short_description: values.short_description,
      encrypt: values.encrypt,
      gallery: values.gallery == null ? [] : values.gallery,
      gallery_placement:
        values.gallery_placement == null ? "" : values.gallery_placement,
      content: values.content,
      status: values.status,
      categories: values.categories,
    },
  });
}

export function knowledgeBaseUpdateService(id, values) {
  console.log("values", values);
  const axios = axiosBase.create();
  return axios({
    method: "PATCH",
    url: "/knowledge-base/" + id,
    data: {
      title: values.title,
      slug: values.slug,
      short_description: values.short_description,
      encrypt: values.encrypt,
      gallery: values.gallery == null ? [] : values.gallery,
      gallery_placement:
        values.gallery_placement == null ? "" : values.gallery_placement,
      content: values.content,
      status: values.status,
      categories: values.categories,
    },
  });
}

export function knowledgeBaseDeleteService(id) {
  const axios = axiosBase.create();
  return axios({
    method: "DELETE",
    url: "/knowledge-base/" + id,
  });
}

export function knowledgeBaseSearchService(q) {
  const axios = axiosBase.create();
  if (q === "") {
    return axios({
      method: "GET",
      url: "/knowledge-base",
    });
  } else {
    return axios({
      method: "GET",
      url: "/knowledge-base/search?q=" + q,
    });
  }
}
