import React from 'react';
import { PageHeader } from 'antd';
import { MyBreadcrumb } from 'utils';
import CreateUpdateForm from './form';
export default function Create() {
  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: 'Home', path: '/' },
          { label: 'Workspace', path: '/workspace' },
          { label: 'Create', path: '/create' },
        ]}
      />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Create"
        subTitle=""
      >
        <CreateUpdateForm />
      </PageHeader>
    </div>
  );
}
