import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import {
    Table,
    Space,
    Tooltip,
    Checkbox,
    Button,
    PageHeader,
    Input,
} from 'antd';

import {
    DeleteOutlined,
    MessageOutlined
} from '@ant-design/icons';

import {
    handleApiSuccess,
    handleApiError,
    MyBreadcrumb,
    accessControls,
    confirmDelete,
} from 'utils';

// import { debounce } from 'lodash';
import { deleteQuoteService, listQuoteService } from 'services/workspace_quote';

// const { Search } = Input;

export default function List() {
    const history = useHistory();
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState(null);
    const [permissions, setPermissions] = useState([]);

    const columns = [
      {
        title: 'ID',
        dataIndex: '_id',
        key: '_id',
        //defaultSortOrder: "descend",
        className: 'hide',
        sorter: function (a, b, sortOrder) {
          return a && b ? a._id.localeCompare(b._id) : null;
        },
      },

      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: function (a, b, sortOrder) {
          return a && b ? a.name.localeCompare(b.name) : null;
        },
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: 'Date & Time',
        dataIndex: 'dateTime',
        key: 'dateTime',
        render: (text, record) => (
          <Space size="middle">
            {new Date(record.dateTime).toLocaleString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
            })}
          </Space>
        ),
      },
      {
        title: 'Note',
        dataIndex: 'note',
        key: 'note',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
        <Space size="middle">
          {accessControls.can(permissions, 'WorkspaceManagement.read:any') && (
          <Tooltip title="Delete a Quote">
            <Button
            onClick={(e) => deleteRecord(record._id)}
            shape="circle"
            icon={<DeleteOutlined />}
            />
          </Tooltip>
          )}
        </Space>
        ),
      },
    ];

    useEffect(() => {
        loadData();
        loadPermissions();
    }, []);

    const loadPermissions = () => {
        //********START  ACCESS CONTROL******** */
        accessControls.fetch().then((permissions) => {
            permissions['Workspace' + '.' + 'read:any'] = true;
            permissions['Workspace' + '.' + 'create:any'] = true;
            permissions['Workspace' + '.' + 'update:any'] = true;
            permissions['Workspace' + '.' + 'delete:any'] = true;
            console.log(permissions);
            setPermissions(permissions);
        });
        //********END ACCESS CONTROL******** */
    };

    const loadData = () => {
        setIsLoading(true);
        listQuoteService(params.id)
            .then((response) => {
                console.log(response.data);
                setIsLoading(false);
                //handleApiSuccess(response);
                if (response.data.items && response.data.items.length > 0) {
                    setDataSource(response.data.items);
                    console.log(response.data.items);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                handleApiError(error);
            });
    };

      const deleteRecord = (id) => {
        confirmDelete().then(() => {
          setIsLoading(true);
          deleteQuoteService(id)
            .then((response) => {
              setIsLoading(false);
              handleApiSuccess(response);
              loadData();
            })
            .catch((error) => {
              setIsLoading(false);
              handleApiError(error);
            });
        });
      };

    // const searchRecord = (q) => {
    //     setIsLoading(true);
    //     branchesSearchService(q)
    //         .then((response) => {
    //             setIsLoading(false);
    //             handleApiSuccess(response);
    //             //handleApiSuccess(response);
    //             if (response.data.items && response.data.items.length > 0) {
    //                 setDataSource(response.data.items);
    //             } else {
    //                 setDataSource([]);
    //             }
    //         })
    //         .catch((error) => {
    //             setIsLoading(false);
    //             setDataSource([]);
    //             handleApiError(error);
    //         });
    // };

    // const delayedQuery = debounce((q) => searchRecord(q), 500);
    // const onChange = (e) => {
    //     delayedQuery(e.target.value);
    // };

    return (
        <div>
            <MyBreadcrumb items={[{ label: 'Workspace property Quotes', path: '' }]} />
            <PageHeader
                ghost={false}
                avatar={{ icon: <MessageOutlined /> }}
                title="Workspace property Quotes"
            ></PageHeader>
            <Table dataSource={dataSource} columns={columns} loading={isLoading} />
        </div>
    );
}