import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { Table, Space, Tooltip, Button, PageHeader, Input, Avatar } from "antd";

import {
    EditOutlined,
    DeleteOutlined,
    PlusCircleOutlined,
    TeamOutlined,
} from "@ant-design/icons";
import {
    handleApiSuccess,
    handleApiError,
    MyBreadcrumb,
    accessControls,
    confirmDelete,
} from "utils";

import { debounce } from "lodash";
import Swal from "sweetalert2";
import { deleteSalesPersonService, salesPersonListService } from "services/sales_person";

const { Search } = Input;

export default function Salesperson() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [dataSource, setDataSource] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const columns = [
        {
            title: "ID",
            dataIndex: "_id",
            key: "_id",
            className: "hide",
            //defaultSortOrder: "descend",
            sorter: function (a, b, sortOrder) {
                return a._id.localeCompare(b._id);
            },
        },
        {
            title: "First Name",
            dataIndex: "first_name",
            key: "first_name",
            sorter: function (a, b, sortOrder) {
                a = a.first_name || "";
                b = b.first_name || "";
                return a.localeCompare(b);
            },
        },
        {
            title: "Last Name",
            dataIndex: "last_name",
            key: "last_name",
            sorter: function (a, b, sortOrder) {
                a = a.last_name || "";
                b = b.last_name || "";
                return a.localeCompare(b);
            },
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: function (a, b, sortOrder) {
                a = a.email || "";
                b = b.email || "";
                return a.localeCompare(b);
            },
        },
        {
            title: "Cell Phone",
            dataIndex: "cell_phone",
            key: "cell_phone",
            sorter: function (a, b, sortOrder) {
                a = a.cell_phone || "";
                b = b.cell_phone || "";
                return a.localeCompare(b);
            },
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) => (
                <Space size="middle">
                    {accessControls.can(permissions, 'WorkspaceManagement.read:any') && (
                        <Tooltip title="Edit">
                            <Button
                                onClick={(e) => {
                                    history.push("/sales/update/" + record._id);
                                }}
                                type="primary"
                                shape="circle"
                                icon={<EditOutlined />}
                            />
                        </Tooltip>
                    )}
                    {accessControls.can(permissions, 'WorkspaceManagement.read:any') && (
                        <Tooltip title="Disable">
                            <Button
                                onClick={(e) => deleteRecord(record._id)}
                                shape="circle"
                                icon={<DeleteOutlined />}
                            />
                        </Tooltip>
                    )}
                </Space>
            ),
        },
    ];

    useEffect(() => {
        loadData();
        loadPermissions();
    }, []);

    const loadPermissions = () => {
        //********START  ACCESS CONTROL******** */
        accessControls.fetch().then((permissions) => {
            permissions['Workspace' + '.' + 'read:any'] = true;
            permissions['Workspace' + '.' + 'create:any'] = true;
            permissions['Workspace' + '.' + 'update:any'] = true;
            permissions['Workspace' + '.' + 'delete:any'] = true;
            setPermissions(permissions);
        });
        //********END ACCESS CONTROL******** */
    };

    const loadData = () => {
        setIsLoading(true);
        salesPersonListService()
            .then((response) => {
                setIsLoading(false);
                if (response.data.items && response.data.items.length > 0) {
                    setDataSource(response.data.items);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                handleApiError(error);
            });
    };

    const deleteRecord = (id) => {
        confirmDelete().then(() => {
            setIsLoading(true);
            deleteSalesPersonService(id)
                .then((response) => {
                    setIsLoading(false);
                    handleApiSuccess(response);
                    loadData();
                })
                .catch((error) => {
                    setIsLoading(false);
                    handleApiError(error);
                });
        });
    };

    // const searchRecord = (q) => {
    //     setIsLoading(true);
    //     salesPersonSearchService(q)
    //         .then((response) => {
    //             setIsLoading(false);
    //             handleApiSuccess(response);
    //             //handleApiSuccess(response);
    //             if (response.data.items && response.data.items.length > 0) {
    //                 setDataSource(response.data.items);
    //             } else {
    //                 setDataSource([]);
    //             }
    //         })
    //         .catch((error) => {
    //             setIsLoading(false);
    //             handleApiError(error);
    //         });
    // };

    // const delayedQuery = debounce((q) => searchRecord(q), 500);
    // const onChange = (e) => {
    //     delayedQuery(e.target.value);
    // };

    return (
        <div>
            <MyBreadcrumb items={[{ label: "Sales Persons", path: "" }]} />
            <PageHeader
                ghost={false}
                avatar={{ icon: <TeamOutlined /> }}
                title="Sales Persons"
                extra={[
                    <>
                        {accessControls.can(permissions, 'WorkspaceManagement.read:any') ? (
                            <Button
                                key="create"
                                shape="round"
                                onClick={(e) => history.push("/sales/create")}
                                type="primary"
                                icon={<PlusCircleOutlined />}
                            >
                                Add New
                            </Button>
                        ) : (
                            ""
                        )}
                    </>,
                ]}
            ></PageHeader>
            <Table
                dataSource={dataSource}
                columns={columns}
                loading={isLoading}
                rowKey="_id"
            />
        </div>
    );
}