import React, { useState, useRef, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  Spin,
  Button,
  Form,
  Input,
  Row,
  Col,
  Card,
  Select,
  Checkbox,
  Space,
  List,
  Typography,
  Switch,
} from "antd";

import {
  knowledgeBaseCreateService,
  knowledgeBaseListByIdService,
  knowledgeBaseUpdateService,
} from "services/knowledge_base";
import { categoriesListService } from "services/category";

import { galleryListService } from "services/gallery";

import { handleApiSuccess, handleApiError } from "utils";
import { Editor } from "@tinymce/tinymce-react";

import { groupBy, filter, startsWith } from "lodash";

import Auth from "auth";

import { LoadingOutlined } from "@ant-design/icons";

const _auth = Auth;

const loaderIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: {},
};

const { Option } = Select;
const { Title } = Typography;
const { Search } = Input;

export default function KnowledgeBaseForm(props) {
  const { id } = props;

  const [isEditorLoading, setIsEditorLoading] = useState(true);

  const [encrypt, setEncrypt] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();

  const [categoriesListDefault, setCategoriesListDefault] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [categories, setCategories] = useState([]);
  const [galleryList, setGalleryList] = useState([]);

  const handleEditorChange = (content, editor) => {
    form.setFieldsValue({ content: content });
    //console.log('Content was updated:', content);
  };

  const firstInput = useRef(null);
  useEffect(() => {
    firstInput.current.focus();
  }, []);

  useEffect(() => {
    // IF id exist the initialize form data
    setIsLoading(true);
    if (id) {
      knowledgeBaseListByIdService(id)
        .then((response) => {
          setIsLoading(false);
          if (response.data.items && response.data.items.length > 0) {
            let data = response.data.items[0];
            if (!data.gallery || data.gallery.length === 0) {
              data.gallery = [];
            }

            if (data.gallery_placement === "") {
              data.gallery_placement = null;
            }

            form.setFieldsValue(data);

            setEncrypt(data.encrypt && data.encrypt === "YES" ? true : false);

            var cats = [];
            data.categories.map((item, i) => cats.push(item._id));
            setCategories(cats);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }

    categoriesListService()
      .then((response) => {
        setIsLoading(false);
        if (response.data.items && response.data.items.length > 0) {
          let data = groupBy(response.data.items, (item) => {
            if (item.parent) {
              return item.parent.name;
            }
          });
          delete data[undefined];
          setCategoriesListDefault(response.data.items);
          setCategoriesList(data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });

    galleryListService()
      .then((response) => {
        setIsLoading(false);
        if (response.data.items && response.data.items.length > 0) {
          setGalleryList(response.data.items);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  }, [id, form]);

  const onFinish = (values) => {
    // Clean content before save
    if (
      values.content &&
      values.content.level &&
      values.content.level.content
    ) {
      values.content = values.content.level.content;
    }

    if (categories.length > 0) {
      values.categories = categories;
    } else {
      values.categories = [];
    }

    values.encrypt = encrypt === true ? "YES" : "NO";

    /*
    if (!values.gallery || typeof values.gallery != "string") {
      values.gallery = "";
    }
    */

    setIsSaving(true);
    // Update
    if (id) {
      knowledgeBaseUpdateService(id, values)
        .then((response) => {
          setIsSaving(false);
          handleApiSuccess(response);
          history.push("/knowledge-base");
        })
        .catch((error) => {
          setIsSaving(false);
          handleApiError(error);
        });
    } else {
      // Create
      knowledgeBaseCreateService(values)
        .then((response) => {
          setIsSaving(false);
          handleApiSuccess(response);
          history.push("/knowledge-base");
        })
        .catch((error) => {
          setIsSaving(false);
          handleApiError(error);
        });
    }
  };

  const handleStatusChange = (value) => {
    form.setFieldsValue({ status: value });
  };

  const onGroupCheckboxChange = (selectedCats) => {
    setCategories(selectedCats);
  };

  const categorySearch = (value) => {
    var result = filter(categoriesListDefault, function (item) {
      return item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      //return startsWith(item.name.toLowerCase(), value.toLowerCase());
    });

    let data = groupBy(result, (item) => {
      if (item.parent) {
        return item.parent.name;
      }
    });
    delete data[undefined];
    setCategoriesList(data);
  };

  return (
    <Form name="kbForm" form={form} {...layout} onFinish={onFinish}>
      <Spin spinning={isLoading} />
      <Row gutter={16}>
        <Col md={18}>
          <Form.Item
            label="Title"
            name="title"
            help="Add post content heading."
            rules={[{ required: true, message: "Please input post title" }]}
          >
            <Input ref={firstInput} size="large" />
          </Form.Item>

          <Form.Item
            label="Description"
            name="short_description"
            help="A short description explain what is the this post about."
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            label="Encrypt Content"
            name="encrypt"
            help="If encrypt is on, content of this post will be encrypted before store in database, and decrypted when we get that content."
          >
            <Switch
              onChange={(checked) => setEncrypt(checked)}
              checked={encrypt}
            />
          </Form.Item>

          <Form.Item label="Gallery" name="gallery">
            <Select
              mode="multiple"
              autoComplete="gallery"
              showSearch
              placeholder="Select a gallery"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">Select a gallery</Option>
              {galleryList.map((item, i) => (
                <Option value={item._id}>{item.title}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Gallery Placement"
            name="gallery_placement"
            dependencies={["gallery"]}
            rules={[
              { required: false, message: "Please select gallery placement" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  console.log("value", value);
                  console.log("gallery", getFieldValue("gallery"));
                  if (
                    value ||
                    !getFieldValue("gallery") ||
                    getFieldValue("gallery") === null ||
                    getFieldValue("gallery").length === 0
                  ) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Please select gallery placement")
                  );
                },
              }),
            ]}
          >
            <Select
              autoComplete="gallery_placement"
              showSearch
              placeholder="Select a gallery placement"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">Select a gallery placement</Option>
              <Option value="BEFORE">Before Content</Option>
              <Option value="AFTER">After Content</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Content" name="content">
            <Editor
              apiKey="erba5tuvlzej8os0sfmpoopsyyobk0uf7i7z88ysbur64y3e"
              //initialValue={form.getFieldValue('content')}
              init={{
                height: 500,
                menubar: false,
                branding: false,

                plugins:
                  "image code print preview fullpage  searchreplace autolink directionality  visualblocks visualchars fullscreen image link    table charmap hr pagebreak nonbreaking  toc insertdatetime advlist lists textcolor wordcount   imagetools    contextmenu colorpicker textpattern media ",
                toolbar:
                  "formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | table | image | numlist bullist outdent indent  | removeformat |undo redo | fontsizeselect  | ",

                image_title: true,
                automatic_uploads: true,
                images_upload_url:
                  process.env.REACT_APP_API_BASE_URL +
                  "/knowledge-base/upload-media?token=" +
                  _auth.getToken(),
                file_picker_types: "image",
              }}
              onEditorChange={handleEditorChange}
              onInit={(e) => {
                setIsEditorLoading(false);
              }}
            />
          </Form.Item>
          {isEditorLoading ? <Spin indicator={loaderIcon} /> : ""}
        </Col>
        <Col md={6}>
          <Form.Item {...tailLayout}>
            <Button block type="primary" loading={isSaving} htmlType="submit">
              SAVE THIS POST
            </Button>
          </Form.Item>

          <Form.Item initialValue="PRIVATE" name="status">
            <Select style={{ marginTop: 15 }} onChange={handleStatusChange}>
              <Option key="DRAFT" value="DRAFT">
                DRAFT - Keep this post inactive
              </Option>
              <Option key="PRIVATE" value="PRIVATE">
                PRIVATE - Post visible for internal
              </Option>
              <Option key="PUBLISH" value="PUBLISH">
                PUBLISH - Post visible for public
              </Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Card
              title="Category"
              extra={<Link to="/knowledge-base/categories">Manage</Link>}
            >
              <Search
                placeholder="Search category"
                onSearch={(value) => categorySearch(value)}
                onChange={(e) => categorySearch(e.target.value)}
              />

              <Checkbox.Group
                style={{ width: "100%" }}
                value={categories}
                onChange={onGroupCheckboxChange}
              >
                <Space direction="vertical" style={{ width: "100%" }}>
                  {Object.keys(categoriesList).map((key) => (
                    <List
                      itemLayout="horizontal"
                      dataSource={categoriesList[key]}
                      header={
                        <Title type="secondary" level={4}>
                          {key}
                        </Title>
                      }
                      renderItem={(item, index) => (
                        <List.Item>
                          <Checkbox key={index} value={item._id}>
                            {item.name}
                          </Checkbox>
                        </List.Item>
                      )}
                    />
                  ))}
                </Space>
              </Checkbox.Group>
            </Card>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}
