import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { Table, Space, Tooltip, Button, PageHeader, Input } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  TeamOutlined,
} from "@ant-design/icons";

import {
  handleApiSuccess,
  handleApiError,
  MyBreadcrumb,
  confirmDelete,
} from "utils";
import {
  clientsListService,
  clientsDeleteService,
  clientsSearchService,
} from "services/client";

import { debounce } from "lodash";

const { Search } = Input;

export default function ClientsList() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState(null);

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      //defaultSortOrder: "descend",
      className: "hide",
      sorter: function (a, b, sortOrder) {
        return a._id.localeCompare(b._id);
      },
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      sorter: function (a, b, sortOrder) {
        return a.client_name.localeCompare(b.client_name);
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: function (a, b, sortOrder) {
        return a.email.localeCompare(b.email);
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            <Button
              onClick={(e) => {
                history.push("/clients/update/" + record._id);
              }}
              type="primary"
              shape="circle"
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Disable">
            <Button
              onClick={(e) => deleteRecord(record._id)}
              shape="circle"
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setIsLoading(true);
    clientsListService()
      .then((response) => {
        setIsLoading(false);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const deleteRecord = (id) => {
    confirmDelete().then(() => {
      setIsLoading(true);
      clientsDeleteService(id)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          loadData();
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    });
  };

  const searchRecord = (q) => {
    setIsLoading(true);
    clientsSearchService(q)
      .then((response) => {
        setIsLoading(false);
        handleApiSuccess(response);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const delayedQuery = debounce((q) => searchRecord(q), 500);
  const onChange = (e) => {
    delayedQuery(e.target.value);
  };

  return (
    <div>
      <MyBreadcrumb items={[{ label: "Resellers", path: "" }]} />
      <PageHeader
        ghost={false}
        avatar={{ icon: <TeamOutlined /> }}
        title="Resellers"
        subTitle={
          <Search
            allowClear={true}
            onChange={onChange}
            loading={isLoading}
            placeholder="Filter search result"
            onSearch={(value) => searchRecord(value)}
            style={{ width: 200 }}
          />
        }
        extra={[
          <Button
            key="create"
            shape="round"
            onClick={(e) => history.push("/clients/create")}
            type="primary"
            icon={<PlusCircleOutlined />}
          >
            Add New
          </Button>,
        ]}
      ></PageHeader>
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
        rowKey="_id"
      />
    </div>
  );
}
