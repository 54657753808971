/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useRef } from "react";
import { PageHeader, Button, Typography, Descriptions, Space } from "antd";
import { MyBreadcrumb } from "utils";
import { PrinterOutlined } from "@ant-design/icons";
import { branchesListByIdService } from "services/branch";
import { handleApiError } from "utils";
import { useReactToPrint } from "react-to-print";

const { Title } = Typography;
const branchInfo = [];
export default function View(props) {
  const { id } = props.match.params;
  const [isLoading, setIsLoading] = useState(false);
  const [branchInfo, setBranchInfo] = useState({});
  const componentRef = useRef(null);

  useEffect(() => {
    // IF id exist the initialize form data
    if (id) {
      branchesListByIdService(id).then((response) => {
        setIsLoading(false);
        if (response.data.items && response.data.items.length > 0) {
          setBranchInfo(response.data.items[0]);
        }
      });
    }
  }, [id]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: "Branches", path: "/branch" },
          { label: "View", path: "" },
        ]}
      />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="View"
        subTitle=""
        extra={[
          <Button
            key="1"
            onClick={handlePrint}
            type="primary"
            shape="round"
            icon={<PrinterOutlined />}
          >
            Print
          </Button>,
        ]}
      >
        {isLoading ? "Loading..." : ""}

        <div ref={componentRef}>
          <div className="descriptionView2-heading">
            <Title level={3}>{branchInfo?.branch_name}</Title>
          </div>

          <div className="descriptionView2">
            <div className="box">
              <Title level={4}>Physical Address</Title>
              {branchInfo?.physical_address && (
                <div>{branchInfo?.physical_address}</div>
              )}
              {branchInfo?.physical_suite_apt && (
                <div>{branchInfo?.physical_suite_apt}</div>
              )}
              <Space className="flex">
                {branchInfo?.physical_city && (
                  <div>{branchInfo?.physical_city},</div>
                )}
                {branchInfo?.physical_state && (
                  <div>{branchInfo?.physical_state}</div>
                )}
                {branchInfo?.physical_zip && (
                  <div>{branchInfo?.physical_zip}</div>
                )}
              </Space>
              {branchInfo?.physical_map_url && (
                <div>
                  <a target="_blank" href={branchInfo?.physical_map_url}>
                    {branchInfo?.physical_map_url}
                  </a>
                </div>
              )}
              {branchInfo?.main_phone_1 && (
                <div>{branchInfo?.main_phone_1}</div>
              )}

              {branchInfo?.location_hours && (
                <div>{branchInfo?.location_hours}</div>
              )}
            </div>

            {(branchInfo?.main_contact ||
              branchInfo?.billing_contact ||
              branchInfo?.purchase_approval_contact ||
              branchInfo?.account_number ||
              branchInfo?.sub_account_number ||
              branchInfo?.building_id ||
              branchInfo?.market_name) && (
              <div className="box">
                <Title level={4}>Account Details</Title>
                {branchInfo?.main_contact && (
                  <div>Main Contact: {branchInfo?.main_contact}</div>
                )}
                {branchInfo?.billing_contact && (
                  <div>Billing Contact: {branchInfo?.billing_contact}</div>
                )}
                {branchInfo?.purchase_approval_contact && (
                  <div>
                    Purchase Approval Contact{" "}
                    {branchInfo?.purchase_approval_contact}
                  </div>
                )}
                <br />
                {branchInfo?.account_number && (
                  <div>
                    <strong>Account Number: </strong>
                    {branchInfo?.account_number}
                  </div>
                )}
                {branchInfo?.sub_account_number && (
                  <div>
                    <strong>Sub Account Number: </strong>
                    {branchInfo?.sub_account_number}
                  </div>
                )}
                {branchInfo?.building_id && (
                  <div>
                    <strong>Building ID: </strong>
                    {branchInfo?.building_id}
                  </div>
                )}{" "}
                {branchInfo?.market_name && (
                  <div>
                    <strong>Market Name: </strong>
                    {branchInfo?.market_name}
                  </div>
                )}
              </div>
            )}

            {(branchInfo?.shipping_address_same_as_physical_address ||
              branchInfo?.shipping_address ||
              branchInfo?.shipping_suite_apt ||
              branchInfo?.shipping_city ||
              branchInfo?.shipping_state ||
              branchInfo?.shipping_zip ||
              branchInfo?.shipping_map_url) && (
              <div className="box">
                <Title level={4}>Shipping Address</Title>
                {branchInfo?.shipping_address_same_as_physical_address &&
                  branchInfo?.shipping_address_same_as_physical_address ===
                    "YES" && (
                    <div>
                      Same as Physical Address{" "}
                      {branchInfo?.shipping_address_same_as_physical_address}
                    </div>
                  )}

                {branchInfo?.shipping_address_same_as_physical_address &&
                  branchInfo?.shipping_address_same_as_physical_address ===
                    "NO" && (
                    <>
                      {branchInfo?.shipping_address && (
                        <div>{branchInfo?.shipping_address}</div>
                      )}
                      {branchInfo?.shipping_suite_apt && (
                        <div>{branchInfo?.shipping_suite_apt}</div>
                      )}
                      <Space className="flex">
                        {branchInfo?.shipping_city && (
                          <div>{branchInfo?.shipping_city},</div>
                        )}
                        {branchInfo?.shipping_state && (
                          <div>{branchInfo?.shipping_state}</div>
                        )}
                        {branchInfo?.shipping_zip && (
                          <div>{branchInfo?.shipping_zip}</div>
                        )}
                      </Space>
                      {branchInfo?.shipping_map_url && (
                        <div>
                          <a
                            target="_blank"
                            href={branchInfo?.shipping_map_url}
                          >
                            {branchInfo?.shipping_map_url}
                          </a>
                        </div>
                      )}
                    </>
                  )}
              </div>
            )}

            {(branchInfo?.billing_address_same_as_shipping_address ||
              branchInfo?.billing_address ||
              branchInfo?.billing_suite_apt ||
              branchInfo?.billing_city ||
              branchInfo?.billing_state ||
              branchInfo?.billing_zip ||
              branchInfo?.billing_map_url) && (
              <div className="box">
                <Title level={4}>Billing Address</Title>
                {branchInfo?.billing_address_same_as_shipping_address &&
                  branchInfo?.billing_address_same_as_shipping_address ===
                    "YES" && (
                    <div>
                      Same as Shipping Address{" "}
                      {branchInfo?.billing_address_same_as_shipping_address}
                    </div>
                  )}

                {branchInfo?.billing_address_same_as_shipping_address &&
                  branchInfo?.billing_address_same_as_shipping_address ===
                    "NO" && (
                    <>
                      {branchInfo?.billing_address && (
                        <div>{branchInfo?.billing_address}</div>
                      )}
                      {branchInfo?.billing_suite_apt && (
                        <div>{branchInfo?.billing_suite_apt}</div>
                      )}
                      <Space className="flex">
                        {branchInfo?.billing_city && (
                          <div>{branchInfo?.billing_city},</div>
                        )}
                        {branchInfo?.billing_state && (
                          <div>{branchInfo?.billing_state}</div>
                        )}
                        {branchInfo?.billing_zip && (
                          <div>{branchInfo?.billing_zip}</div>
                        )}
                      </Space>
                      {branchInfo?.billing_map_url && (
                        <div>
                          <a target="_blank" href={branchInfo?.billing_map_url}>
                            {branchInfo?.billing_map_url}
                          </a>
                        </div>
                      )}
                    </>
                  )}
              </div>
            )}
          </div>

          {(branchInfo?.main_phone_1 ||
            branchInfo?.main_phone_2 ||
            branchInfo?.main_phone_3 ||
            branchInfo?.main_phone_4 ||
            branchInfo?.main_phone_5 ||
            branchInfo?.cell_phone_1 ||
            branchInfo?.cell_phone_2 ||
            branchInfo?.cell_phone_3 ||
            branchInfo?.cell_fax_1 ||
            branchInfo?.cell_fax_2 ||
            branchInfo?.cell_fax_3) && (
            <div className="descriptionView2">
              <div className="box">
                <Title level={4}>
                  <strong>Contact Details</strong>
                </Title>
                {branchInfo?.main_phone_1 && (
                  <div>
                    {branchInfo?.main_phone_1}, EXT{" "}
                    {branchInfo?.main_phone_1_ext}
                  </div>
                )}
                {branchInfo?.main_phone_2 && (
                  <div>
                    {branchInfo?.main_phone_2}, EXT{" "}
                    {branchInfo?.main_phone_2_ext}
                  </div>
                )}
                {branchInfo?.main_phone_3 && (
                  <div>
                    {branchInfo?.main_phone_3}, EXT{" "}
                    {branchInfo?.main_phone_3_ext}
                  </div>
                )}
                {branchInfo?.main_phone_4 && (
                  <div>
                    {branchInfo?.main_phone_4}, EXT{" "}
                    {branchInfo?.main_phone_4_ext}
                  </div>
                )}
                {branchInfo?.main_phone_5 && (
                  <div>
                    {branchInfo?.main_phone_5}, EXT{" "}
                    {branchInfo?.main_phone_5_ext}
                  </div>
                )}

                {branchInfo?.cell_phone_1 && (
                  <div>
                    {branchInfo?.cell_phone_1}, Carrier{" "}
                    {branchInfo?.cell_phone_1_carrier}
                  </div>
                )}
                {branchInfo?.cell_phone_2_carrier && (
                  <div>
                    {branchInfo?.cell_phone_2}, Carrier{" "}
                    {branchInfo?.cell_phone_2_carrier}
                  </div>
                )}
                {branchInfo?.cell_phone_3_carrier && (
                  <div>
                    {branchInfo?.cell_phone_3}, Carrier{" "}
                    {branchInfo?.cell_phone_3_carrier}
                  </div>
                )}

                {branchInfo?.fax_1 && <div>Fax 1: {branchInfo?.fax_1}</div>}
                {branchInfo?.fax_2 && <div>Fax 2: {branchInfo?.fax_2}</div>}
                {branchInfo?.fax_3 && <div>Fax 3: {branchInfo?.fax_3}</div>}

                {branchInfo?.location_hours && (
                  <div>Location Hours: {branchInfo?.location_hours}</div>
                )}
              </div>
            </div>
          )}

          {(branchInfo?.shipping_company ||
            branchInfo?.shipping_company_start_time ||
            branchInfo?.shipping_company_stop_time) && (
            <div className="descriptionView2">
              <div className="box">
                <Title level={4}>
                  <strong>Shipping Pickup/Delivery times</strong>
                </Title>

                {branchInfo?.shipping_company && (
                  <div>Shipping Company: {branchInfo?.shipping_company}</div>
                )}
                {branchInfo?.shipping_company_start_time && (
                  <div>
                    Start time: {branchInfo?.shipping_company_start_time}:00{" "}
                    {branchInfo?.shipping_company_start_time_am_pm}
                  </div>
                )}
                {branchInfo?.shipping_company_stop_time && (
                  <div>
                    Stop time: {branchInfo?.shipping_company_stop_time}
                    :00 {branchInfo?.shipping_company_stop_time_am_pm}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {branchInfo?.notes && (
          <div className="descriptionView2">
            <div className="box-row">
              <strong>Notes: </strong>
              {branchInfo?.notes ? <span>{branchInfo?.notes}</span> : "NA"}
            </div>
          </div>
        )}
      </PageHeader>
    </div>
  );
}
