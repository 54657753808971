import React, { useState, useEffect, useRef } from "react";
import { PageHeader, Button, Typography, Space, Row, Col } from "antd";
import { MyBreadcrumb } from "utils";
import { PrinterOutlined } from "@ant-design/icons";
import { handleApiError, axiosBase, renderInfoBlock } from "utils";
import { useReactToPrint } from "react-to-print";

const { Title } = Typography;
export default function View(props) {
  const { id } = props.match.params;
  const axios = axiosBase.create();
  const [isLoading, setIsLoading] = useState(false);
  const [item, setItem] = useState({});
  const componentRef = useRef(null);

  useEffect(() => {
    // IF id exist the initialize form data
    console.log("props", props);
    if (id) {
      axios({
        method: "GET",
        url: "/utility-provider/view/" + id,
      })
        .then((response) => {
          setIsLoading(false);
          if (response.data.item) {
            setItem(response.data.item);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  }, [id]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: "Utility Providers", path: "/utility-providers" },
          { label: "View", path: "" },
        ]}
      />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="View"
        subTitle=""
        extra={[
          <Button
            key="1"
            onClick={handlePrint}
            type="primary"
            shape="round"
            icon={<PrinterOutlined />}
          >
            Print
          </Button>,
        ]}
      >
        {isLoading ? "Loading..." : ""}

        <div ref={componentRef}>
          <div className="descriptionView2-heading">
            <Title level={3}>
              Utility Providers - {item?.utility_provider}
            </Title>
            <Title level={3}>
              Status:{" "}
              {item.status === "ACTIVE" ? (
                <span className="text-green">Active</span>
              ) : (
                <span className="text-yellow">{item.status}</span>
              )}
            </Title>
            {item.monthly_fee && (
              <Title level={3}>
                Monthly Fee:{" "}
                <span className="text-green">$ {item.monthly_fee}</span>
              </Title>
            )}
          </div>

          <div style={{ maxWidth: 900, margin: "auto" }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} xl={12}>
                {renderInfoBlock({
                  heading: "General Info",
                  source: item,
                  items: [
                    "account_number",
                    "ap_vendor_id",
                    "ap_service_code",
                    "status",
                    "auto_pay",
                    "payment_timeline",
                    "where_invoice_sent",
                    {
                      key: "AP Manager",
                      value: item.ap_manager
                        ? item.ap_manager.display_name
                        : "",
                    },
                    "circuit_id_meter_id",
                    "referral_partner",
                    {
                      key: "Referred By",
                      value: item.referred_by
                        ? item.referred_by.utility_provider
                        : "",
                    },
                  ],
                })}
              </Col>
              <Col xs={24} xl={12}>
                {renderInfoBlock({
                  heading: "Notices Address",
                  source: item,
                  items: [
                    "notices_address1",
                    "notices_address2",
                    "notices_city",
                    "notices_state",
                    "notices_zip",
                    "notices_country",
                  ],
                })}
              </Col>
              <Col xs={24} xl={12}>
                {renderInfoBlock({
                  heading: "Contract Information",
                  source: item,
                  items: [
                    "monthly_fee",
                    "contract_start",
                    "contract_end",
                    "installation_fee",
                    "contract_signedby",
                    "sla",
                  ],
                })}
              </Col>

              <Col xs={24} xl={12}>
                {renderInfoBlock({
                  heading: "Credentials",
                  source: item,
                  items: [
                    "primary_on_account",
                    "pin",
                    "security_question",
                    "phone_number",
                    "username",
                    "password",
                    "website",
                    "email",
                  ],
                })}
              </Col>

              <Col xs={24} xl={12}>
                {renderInfoBlock({
                  heading: "Location",
                  source: item,
                  items: ["location_name", "node_id"],
                })}
              </Col>

              <Col xs={24} xl={12}>
                {renderInfoBlock({
                  heading: "Billing Address",
                  source: item,
                  items: [
                    "billing_name",
                    "billing_address1",
                    "billing_address2",
                    "billing_country",
                    "billing_state",
                    "billing_city",
                    "billing_zip",
                    "billing_contact",
                    "billing_phone",
                    "billing_email",
                    "billing_fax",
                  ],
                })}
              </Col>

              <Col xs={24} xl={12}>
                {renderInfoBlock({
                  heading: "Site Information",
                  source: item,
                  items: ["service_equipment_location"],
                })}
              </Col>

              <Col xs={24} xl={12}>
                {renderInfoBlock({
                  heading: "Utility Provider Information",
                  source: item,
                  items: [
                    "utility_provider",
                    "sales_agent_email",
                    "sales_agent_phone",
                    "sales_agent_name",
                    "notes",
                  ],
                })}
              </Col>

              <Col xs={24} xl={12}>
                {renderInfoBlock({
                  heading: "Utility Provider Contact Information",
                  source: item,
                  items: [
                    "provider_contact_address1",
                    "provider_contact_address2",
                    "provider_contact_country",
                    "provider_contact_state",
                    "provider_contact_city",
                    "provider_contact_zip",
                    "provider_contact_phone",
                    "provider_contact_fax",
                  ],
                })}
              </Col>

              <Col xs={24} xl={12}>
                {renderInfoBlock({
                  heading: "Utility Provider Support Information",
                  source: item,
                  items: [
                    "provider_support_email",
                    "provider_support_website",
                    "provider_support_phone",
                  ],
                })}
              </Col>

              <Col xs={24} xl={12}>
                {renderInfoBlock({
                  heading: "Utility Provider Billing Information",
                  source: item,
                  items: [
                    "provider_billing_address1",
                    "provider_billing_address2",
                    "provider_billing_country",
                    "provider_billing_state",
                    "provider_billing_city",
                    "provider_billing_zip",
                    "provider_billing_phone",
                    "provider_billing_fax",
                  ],
                })}
              </Col>

              <Col xs={24} xl={12}>
                {renderInfoBlock({
                  heading: "Utility Provider Termination Information",
                  source: item,
                  items: [
                    "provider_termination_email",
                    "provider_termination_phone",
                    "provider_termination_sales_email",
                    "provider_termination_sales_phone",
                  ],
                })}
              </Col>

              <Col xs={24} xl={12}>
                {renderInfoBlock({
                  heading: "Utility Provider Service Area",
                  source: item,
                  items: [
                    "provider_service_area",
                    "provider_service_area_states",
                  ],
                })}
              </Col>

              <Col xs={24} xl={12}>
                {renderInfoBlock({
                  heading: "Utility Provider Notes",
                  source: item,
                  items: ["provider_notes"],
                })}
              </Col>
            </Row>
          </div>
        </div>
      </PageHeader>
    </div>
  );
}
