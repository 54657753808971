import React from "react";
import { PageHeader } from "antd";
import { MyBreadcrumb } from "utils";
import CountriesForm from "./form";
export default function ThirdPartyCredentialsCreate() {
  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: "Home", path: "/" },
          {
            label: "Third Party Credentials",
            path: "/third-party-credentials",
          },
          { label: "Create", path: "" },
        ]}
      />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Create"
        subTitle=""
      >
        <CountriesForm />
      </PageHeader>
    </div>
  );
}
