import React from 'react';
import { PageHeader } from 'antd';

import { MyBreadcrumb } from 'utils';
import KnowledgeBaseForm from './form';
export default function KnowledgeBaseCreate() {

    return (
        <div>
            <MyBreadcrumb items={[{ label: "Knowledge Base", path: "/knowledge-base" }, { label: "Create", path: "" }]} />
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title="Create"
                subTitle=""
            >
                <KnowledgeBaseForm />
            </PageHeader>
        </div>
    );
}