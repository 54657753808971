import React from "react";
import { PageHeader } from "antd";
import { MyBreadcrumb } from "utils";
import CountriesForm from "./form";
export default function ThirdPartyCredentialsUpdate(props) {
  const { id } = props.match.params;
  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: "Home", path: "/" },
          {
            label: "Third Party Credentials",
            path: "/third-party-credentials",
          },
          { label: "Update", path: "" },
        ]}
      />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Update"
        subTitle=""
      >
        <CountriesForm id={id} />
      </PageHeader>
    </div>
  );
}
