const Auth = {
    isAuthenticated(cb) {
        const token = localStorage.token;
        if (token && token !== "") {
            return true;
        } else {
            return false;
        }
    },
    getToken() {
        return localStorage.token;
    },
    authenticate(token, cb) {
        localStorage.setItem("token", token);
        setTimeout(cb, 100); // fake async
    },
    signout(cb) {
        localStorage.removeItem("token");
        setTimeout(cb, 100);
    }
};
export default Auth;