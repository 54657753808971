import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Table, Space, Tooltip, Button, PageHeader, Input, Anchor } from "antd";

import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  TeamOutlined,
  EnvironmentOutlined,
  EyeOutlined,
  KeyOutlined,
} from "@ant-design/icons";

import {
  handleApiSuccess,
  handleApiError,
  MyBreadcrumb,
  accessControls,
  confirmDelete,
} from "utils";
import {
  companiesListService,
  companiesDeleteService,
  companiesSearchService,
} from "services/company";

import { debounce } from "lodash";

const { Search } = Input;
const { Link } = Anchor;

export default function CompaniesList() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [permissions, setPermissions] = useState([]);

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      //defaultSortOrder: "descend",
      className: "hide",
      sorter: function (a, b, sortOrder) {
        return a._id.localeCompare(b._id);
      },
    },
    {
      title: "Company",
      dataIndex: "company_name",
      key: "company_name",
      sorter: function (a, b, sortOrder) {
        if (!a.company_name) {
          return null;
        }
        return a.company_name.localeCompare(b.company_name);
      },
    },
    {
      title: "Address",
      dataIndex: "physical_address",
      key: "physical_address",
      render: (text, record) => {
        if (record.physical_address) {
          return (
            <a
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
              href={record.physical_map_url}
              title="View location map"
            >
              <EnvironmentOutlined /> {record.physical_address}
            </a>
          );
        }
      },
    },
    {
      title: "City",
      dataIndex: "physical_city",
      key: "physical_city",
      sorter: function (a, b, sortOrder) {
        if (!a.physical_city) {
          return null;
        }
        return a.physical_city.localeCompare(b.physical_city);
      },
    },
    {
      title: "State",
      dataIndex: "physical_state",
      key: "physical_state",
      sorter: function (a, b, sortOrder) {
        if (!a.physical_state) {
          return null;
        }
        return a.physical_state.localeCompare(b.physical_state);
      },
    },
    {
      title: "Zip",
      dataIndex: "physical_zip",
      key: "physical_zip",
      sorter: function (a, b, sortOrder) {
        if (!a.physical_zip) {
          return null;
        }
        return a.physical_zip.localeCompare(b.physical_zip);
      },
    },
    {
      title: "Phone",
      dataIndex: "main_phone_1",
      key: "main_phone_1",
    },
    {
      title: "Fax",
      dataIndex: "fax_1",
      key: "fax_1",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          {accessControls.can(permissions, "Company.read:any") && (
            <Tooltip key="view" title="View">
              <Button
                onClick={(e) => {
                  history.push("/companies/view/" + record._id);
                }}
                type="primary"
                shape="circle"
                icon={<EyeOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, "AccessControl.read:any") && (
            <Tooltip title="Roles & Access control">
              <Button
                onClick={(e) => {
                  history.push("/companies/role/" + record._id);
                }}
                type="primary"
                shape="circle"
                icon={<KeyOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, "Company.update:any") && (
            <Tooltip title="Edit">
              <Button
                onClick={(e) => {
                  history.push("/companies/update/" + record._id);
                }}
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, "Company.delete:any") && (
            <Tooltip title="Disable">
              <Button
                onClick={(e) => deleteRecord(record._id)}
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    loadData();
    loadPermissions();
  }, []);

  const loadPermissions = () => {
    //********START  ACCESS CONTROL******** */
    accessControls.fetch().then((permissions) => {
      //console.log(permissions);
      setPermissions(permissions);
    });
    //********END ACCESS CONTROL******** */
  };

  const loadData = () => {
    setIsLoading(true);
    companiesListService()
      .then((response) => {
        setIsLoading(false);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const deleteRecord = (id) => {
    confirmDelete().then(() => {
      setIsLoading(true);
      companiesDeleteService(id)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          loadData();
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    });
  };

  const searchRecord = (q) => {
    setIsLoading(true);
    companiesSearchService(q)
      .then((response) => {
        setIsLoading(false);
        handleApiSuccess(response);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        } else {
          setDataSource([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setDataSource([]);
        handleApiError(error);
      });
  };

  const delayedQuery = debounce((q) => searchRecord(q), 500);
  const onChange = (e) => {
    delayedQuery(e.target.value);
  };

  return (
    <div>
      <MyBreadcrumb items={[{ label: "Companies", path: "/" }]} />
      <PageHeader
        ghost={false}
        avatar={{ icon: <TeamOutlined /> }}
        title="Companies"
        subTitle={
          <Search
            allowClear={true}
            onChange={onChange}
            loading={isLoading}
            placeholder="Filter search result"
            onSearch={(value) => searchRecord(value)}
            style={{ width: 200 }}
          />
        }
        extra={
          accessControls.can(permissions, "Company.create:any")
            ? [
                <Button
                  key="create"
                  shape="round"
                  onClick={(e) => history.push("/companies/create")}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Add New
                </Button>,
              ]
            : ""
        }
      ></PageHeader>
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
        rowKey="_id"
      />
    </div>
  );
}
