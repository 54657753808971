import { axiosBase } from 'utils';

export function workspacePropertyListService(workspaceLocationId) {
  const axios = axiosBase.create();
  return axios({
    method: 'GET',
    url:
      '/workspace-property/' + workspaceLocationId + '/list-location-property',
  });
}

export function workspacePropertyListByIdService(id) {
  const axios = axiosBase.create();
  return axios({
    method: 'GET',
    url: '/workspace-property/' + id,
  });
}

export function workspacePropertyCreateService(values) {
  const axios = axiosBase.create();
  return axios({
    method: 'POST',
    url: '/workspace-property',
    data: values,
  });
}

export function workspacePropertyUpdateService(id, values) {
  const axios = axiosBase.create();
  return axios({
    method: 'PUT',
    url: '/workspace-property/' + id,
    data: values,
  });
}

export function workspacePropertyDeleteService(id) {
  const axios = axiosBase.create();
  return axios({
    method: 'DELETE',
    url: '/workspace-property/' + id,
  });
}
