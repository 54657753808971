import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Space, Button, Form, Input, Select } from "antd";
import {
  galleryCreateService,
  galleryViewByIdService,
  galleryUpdateService,
} from "services/gallery";
import { companiesListService } from "services/company";
import { branchesListByCompanyIdService } from "services/branch";

import { handleApiSuccess, handleApiError } from "utils";
const { Option } = Select;
const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 7 },
};
const tailLayout = {
  wrapperCol: { offset: 5, span: 7 },
};

export default function GalleryForm(props) {
  const { id } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);

  const history = useHistory();
  const [form] = Form.useForm();

  useEffect(() => {
    // IF id exist the initialize form data
    if (id) {
      galleryViewByIdService(id)
        .then((response) => {
          setIsLoading(false);
          if (response.data.items && response.data.items.length > 0) {
            var record = response.data.items[0];
            form.setFieldsValue(record);
            // If company was set for category then pull list of branches
            if (response.data.items[0].company) {
              branchesListByCompanyIdService(response.data.items[0].company)
                .then((response) => {
                  setBranches(response.data.items);
                })
                .catch((error) => {
                  handleApiError(error);
                });
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
    //Load companies dropdown
    companiesListService().then((response) => {
      if (response.data.items && response.data.items.length > 0) {
        setCompanies(response.data.items);
      }
    });
  }, [id, form]);

  const onCompanyChange = (value) => {
    branchesListByCompanyIdService(value)
      .then((response) => {
        setBranches(response.data.items);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const onFinish = (values) => {
    setIsLoading(true);

    // Update
    if (id) {
      galleryUpdateService(id, values)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          history.push("/gallery");
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    } else {
      // Create
      galleryCreateService(values)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          history.push("/gallery");
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  };

  return (
    <Form name="myForm" form={form} {...layout} onFinish={onFinish}>
      <Space>&nbsp;</Space>

      <div
        style={{
          backgroundColor: "#FAFAFA",
          border: "1px solid #EAEAEA",
          padding: "30px 5px 5px 5px",
          marginBottom: 30,
        }}
      >
        <Form.Item label="Company" name="company">
          <Select
            autoComplete="company"
            showSearch
            placeholder="Select a company"
            optionFilterProp="children"
            onChange={(value) => onCompanyChange(value)}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="">Select a company</Option>
            {companies.map((item, i) => (
              <Option value={item._id}>{item.company_name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Branch" name="branch">
          <Select
            autoComplete="branch"
            showSearch
            placeholder="Select a branch"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="">Select a branch</Option>
            {branches.map((item, i) => (
              <Option value={item._id}>{item.branch_name}</Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <Form.Item
        label="Gallery Title"
        name="title"
        rules={[{ required: true, message: "Please enter gallery title" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Description" name="description">
        <Input.TextArea rows={4} />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" loading={isLoading} htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
