import React from 'react';
import { PageHeader } from 'antd';
import { MyBreadcrumb } from 'utils';
import CategoriesForm from './form';
export default function CategoriesCreate(props) {

    return (
        <div>
            <MyBreadcrumb items={[{
                label: "Categories", path: "/knowledge-base/categories"
            }, { label: "Create", path: "" }]} />
            < PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title="Create"
                subTitle=""
            >
                <CategoriesForm type={props.match.params.type} />
            </PageHeader>
        </div >
    );
}