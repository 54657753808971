import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import {
  Table,
  Space,
  Tooltip,
  Checkbox,
  Button,
  PageHeader,
  Input,
} from 'antd';

import {
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
  TeamOutlined,
  EyeOutlined,
  ClusterOutlined,
  EnvironmentOutlined,
  UploadOutlined,
} from '@ant-design/icons';

import {
  handleApiSuccess,
  handleApiError,
  MyBreadcrumb,
  accessControls,
  confirmDelete,
} from 'utils';
import {
  branchesListService,
  branchesDeleteService,
  branchesSearchService,
} from 'services/branch';

import { debounce } from 'lodash';
import {
  workspaceDeleteService,
  workspaceListService,
} from 'services/workspace';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const { Search } = Input;

export default function WorkspaceList() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [permissions, setPermissions] = useState([]);

  const arr = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];

  const columns = [
    {
      title: 'ID',
      dataIndex: '_id',
      key: '_id',
      //defaultSortOrder: "descend",
      className: 'hide',
      sorter: function (a, b, sortOrder) {
        return a && b ? a._id.localeCompare(b._id) : null;
      },
    },

    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      defaultSortOrder: 'ascend',
      render: (text, record) => record.company.company_name,
      sorter: function (a, b, sortOrder) {
        if (a.company && b.company) {
          return a.company.company_name.localeCompare(b.company.company_name);
        } else {
          return null;
        }
      },
    },
    {
      title: 'Branch',
      dataIndex: 'branch',
      key: 'branch',
      defaultSortOrder: 'ascend',
      render: (text, record) => record.branch.branch_name,
      sorter: function (a, b, sortOrder) {
        return a && b && a.branch && b.branch
          ? a.branch.branch_name.localeCompare(b.branch.branch_name)
          : null;
      },
    },
    {
      title: 'Market Name',
      dataIndex: 'market_name',
      key: 'market_name',
      sorter: function (a, b) {
        a = a.market_name || '';
        b = b.market_name || '';
        return a && b ? a.localeCompare(b) : null;
      },
    },

    {
      title: 'Opened',
      dataIndex: 'opened',
      key: 'opened',
      render: (text, record) => {
        //create date out of date object
        const date = new Date(record.opened);
        return date.toLocaleDateString('en-US');
      },
    },
    {
      title: 'Voicemail Number',
      dataIndex: 'voicemail',
      key: 'voicemail_number',
    },
    {
      title: 'Internet Provider',
      dataIndex: 'internet_service_provider',
      key: 'internet_service_provider',
    },

    {
      title: 'Cheapest Office Available',
      dataIndex: 'cheapest_office',
      key: 'cheapest_office',
      render: (text, record) => {
        return !record.cheapest_office ? '' : '$' + record.cheapest_office;
      },
    },
    {
      title: 'Properties',
      dataIndex: 'propertyCount',
      key: 'propertyCount',
      align: 'center',
      render: (text, record) => {
        return (
          <Tooltip key="view" title="Manage property under this location">
            <Link
              to={`/workspace/${record._id}/${record.slug}/property`}
              style={{ fontSize: '16px', color: 'blue', fontWeight: 'bold' }}
            >
              {record.propertyCount || 0}
            </Link>
          </Tooltip>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          {accessControls.can(permissions, 'WorkspaceManagement.read:any') && (
            <Tooltip
              key="upload"
              title="Attach document & pictures to this location"
            >
              <Button
                onClick={(e) => {
                  history.push(`/workspace/${record._id}/location/upload`);
                }}
                type="primary"
                shape="circle"
                icon={<UploadOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, 'WorkspaceManagement.read:any') && (
            <Tooltip title="Edit">
              <Button
                onClick={(e) => {
                  history.push('/workspace/update/' + record._id);
                }}
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
              />
            </Tooltip>
          )}
          {accessControls.can(permissions, 'WorkspaceManagement.read:any') && (
            <Tooltip title="Disable">
              <Button
                onClick={(e) => deleteRecord(record._id)}
                shape="circle"
                icon={<DeleteOutlined />}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    loadData();
    loadPermissions();
  }, []);

  const loadPermissions = () => {
    //********START  ACCESS CONTROL******** */
    accessControls.fetch().then((permissions) => {
      permissions['Workspace' + '.' + 'read:any'] = true;
      permissions['Workspace' + '.' + 'create:any'] = true;
      permissions['Workspace' + '.' + 'update:any'] = true;
      permissions['Workspace' + '.' + 'delete:any'] = true;
      console.log(permissions);
      setPermissions(permissions);
    });
    //********END ACCESS CONTROL******** */
  };

  const loadData = () => {
    setIsLoading(true);
    workspaceListService()
      .then((response) => {
        setIsLoading(false);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
          console.log(response.data.items);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        handleApiError(error);
      });
  };

  const deleteRecord = (id) => {
    confirmDelete().then(() => {
      setIsLoading(true);
      workspaceDeleteService(id)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          loadData();
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    });
  };

  const searchRecord = (q) => {
    setIsLoading(true);
    branchesSearchService(q)
      .then((response) => {
        setIsLoading(false);
        handleApiSuccess(response);
        //handleApiSuccess(response);
        if (response.data.items && response.data.items.length > 0) {
          setDataSource(response.data.items);
        } else {
          setDataSource([]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setDataSource([]);
        handleApiError(error);
      });
  };

  const delayedQuery = debounce((q) => searchRecord(q), 500);
  const onChange = (e) => {
    delayedQuery(e.target.value);
  };

  return (
    <div>
      <MyBreadcrumb items={[{ label: 'Workspace', path: '' }]} />
      <PageHeader
        ghost={false}
        avatar={{ icon: <TeamOutlined /> }}
        title="Workspace"
        subTitle={
          <Search
            allowClear={true}
            onChange={onChange}
            loading={isLoading}
            placeholder="Filter search result"
            onSearch={(value) => searchRecord(value)}
            style={{ width: 200 }}
          />
        }
        extra={
          accessControls.can(permissions, 'WorkspaceManagement.read:any')
            ? [
                <Button
                  shape="round"
                  onClick={(e) => history.push('/workspace/create')}
                  type="primary"
                  icon={<PlusCircleOutlined />}
                >
                  Add New
                </Button>,
              ]
            : ''
        }
      ></PageHeader>
      <Table dataSource={dataSource} columns={columns} loading={isLoading} />
    </div>
  );
}
