import React, { useState, useEffect } from 'react';
import { PageHeader, Collapse, Checkbox, TreeSelect, Space, Typography } from 'antd';

import { categoriesListByIdService, categoriesShareSettingsService } from 'services/category';
import { clientsListService } from 'services/client';
import { branchesListByClientIdService } from 'services/branch';

import { usersGetLoginClientService } from 'services/user';


import { MyBreadcrumb, handleApiSuccess, handleApiError } from 'utils';

const { Panel } = Collapse;
const { SHOW_PARENT } = TreeSelect;

/*
const treeData = [
    {
        title: 'Node1',
        value: '0-0',
        key: '0-0',
        children: [
            {
                title: 'Child Node1',
                value: '0-0-0',
                key: '0-0-0',
            },
        ],
    },
    {
        title: 'Node2',
        value: '0-1',
        key: '0-1',
        children: [
            {
                title: 'Child Node3',
                value: '0-1-0',
                key: '0-1-0',
            },
            {
                title: 'Child Node4',
                value: '0-1-1',
                key: '0-1-1',
            },
            {
                title: 'Child Node5',
                value: '0-1-2',
                key: '0-1-2',
            },
        ],
    },
];
*/

export default function CategoriesShare(props) {
    const { id } = props.match.params;

    const [isLoading, setIsLoading] = useState(false);
    const [category, setCategory] = useState([]);
    const [clients, setClients] = useState([]);

    const [clientId, setClientId] = useState(null);
    const [treeData, setTreeData] = useState([]);
    const [treeDataStore, setTreeDataStore] = useState([]);

    const [allShare, setAllShare] = useState(false);

    useEffect(() => {
        loadCategoryInfo();
        loadClients();
    }, []);

    const loadCategoryInfo = () => {
        // Load current category info
        setIsLoading(true);
        categoriesListByIdService(id).then(response => {
            setIsLoading(false);
            if (response.data.items && response.data.items.length > 0) {
                setCategory(response.data.items[0]);
            }
        }).catch(error => {
            setIsLoading(false);
            handleApiError(error);
        });
    }

    const loadClients = () => {
        // Load all clients
        setIsLoading(true);

        usersGetLoginClientService().then(response => {
            if (response.data.items && response.data.items.length > 0) {
                var activeClient = response.data.items[0];
                clientsListService().then(response => {
                    setIsLoading(false);
                    if (response.data.items && response.data.items.length > 0) {
                        var clientsList = response.data.items.filter(client => client._id !== activeClient._id);
                        setClients(clientsList);
                    }
                }).catch(error => {
                    setIsLoading(false);
                    handleApiError(error);
                });
            }
        }).catch(error => {
            handleApiError(error);
        });


    }

    const addSharing = (value, node, extra) => {
        // if no children means it is branch
        if (!node.children) {
            categoriesShareSettingsService("ADD", clientId, category._id, "share_with_branches", value).then(response => {
                console.log("RESPONSE", response);
            });
        } else {
            // if has children it means company

            //first remove all children
            // remove all children as well (children are branches)
            node.children.map((item, index) => {
                categoriesShareSettingsService("REMOVE", clientId, category._id, "share_with_branches", item.value).then(response => {
                    //done
                });
            });

            // Then add company
            categoriesShareSettingsService("ADD", clientId, category._id, "share_with_companies", value).then(response => {
                console.log("RESPONSE", response);
            });
        }
    }

    const removeSharing = (value, node, extra) => {
        // if no children means it is branch
        console.log(node);
        if (!node.children) {
            categoriesShareSettingsService("REMOVE", clientId, category._id, "share_with_branches", value).then(response => {
                //done
            });
        } else {
            // if has children it means company
            categoriesShareSettingsService("REMOVE", clientId, category._id, "share_with_companies", value).then(response => {
                //done
                // remove all children as well (children are branches)
                node.children.map((item, index) => {
                    categoriesShareSettingsService("REMOVE", clientId, category._id, "share_with_branches", item.value).then(response => {
                        //done
                    });
                });
            });
        }
    }

    const openPanel = (clientId) => {
        const refinedArr = [];
        setIsLoading(true)
        setClientId(clientId);
        // LOAD COMAPANY AND BRANCHES LIST FOR PARTICULAR CLIENT
        branchesListByClientIdService(clientId).then(response => {
            var dataItems = response.data.items;
            setIsLoading(false);
            if (dataItems.length > 0) {
                for (var i = 0; i < dataItems.length; i++) {

                    if (dataItems[i].company) {
                        var companyId = dataItems[i].company._id;

                        if (!refinedArr[companyId]) {
                            refinedArr[companyId] = {
                                title: dataItems[i].company.company_name,
                                value: companyId,
                                key: companyId,
                                children: []
                            };
                        }

                        refinedArr[companyId].children.push({
                            title: dataItems[i].branch_name,
                            value: dataItems[i]._id,
                            key: dataItems[i]._id,
                        });
                    } else {
                        setTreeData([]);
                    }
                } // loop

                var result = Object.keys(refinedArr).map(function (key) {
                    return refinedArr[key];
                });
                setTreeData(result);
            } else {
                setTreeData([]);
            }
        }).catch(error => {
            setTreeData([]);
            setIsLoading(false);
        });

        // SET STORED COMAPANY AND BRANCHES FOR PARTICULAR CLIENT, TO FILL VALUES BY DEFAULT
        var _treeDataStore = [];
        for (var i = 0; i < category.share_with_company_and_branches.length; i++) {
            var client = category.share_with_company_and_branches[i].client;
            if (client === clientId) {
                if (category.share_with_company_and_branches[i].company) {
                    var company = category.share_with_company_and_branches[i].company;
                    _treeDataStore.push(company);
                } else {
                    var branch = category.share_with_company_and_branches[i].branch;
                    _treeDataStore.push(branch);
                }
            }
        }
        setTreeDataStore(_treeDataStore);

        // SET CHECKBOX VALUE
        setAllShare(false);
        for (var i = 0; i < category.share_with_clients.length; i++) {
            if (category.share_with_clients[i] === clientId) {
                setAllShare(true);
            }
        }


    }

    const handleAllShareCheckbox = () => {
        if (allShare) {
            setAllShare(false);
            categoriesShareSettingsService("REMOVE", clientId, category._id, "share_with_clients", false).then(response => {
            });
        } else {
            setAllShare(true);
            categoriesShareSettingsService("ADD", clientId, category._id, "share_with_clients", true).then(response => {
            });
        }
    }

    const catName = (category.name) ? "\"" + category.name + "\"" : "";

    return (
        <div>
            <MyBreadcrumb items={[{
                label: "Categories", path: "/knowledge-base/categories"
            }, { label: "Share", path: "" }]} />
            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title={"Share category " + catName + " with following resellers"}
                subTitle=""
            >

                <Collapse accordion={true} onChange={openPanel}>

                    {clients.map((item, i) =>
                        <Panel header={item.client_name} key={item._id}>
                            <Space style={{ width: "100%" }} direction="vertical" size="middle">
                                <Checkbox checked={allShare} onChange={handleAllShareCheckbox}>Share this <strong>{catName}</strong> category with <strong>All company and branches</strong> of client <strong>"{item.client_name}"</strong> </Checkbox>
                                {!allShare &&
                                    <Space style={{ width: "100%" }} direction="vertical" size="middle">
                                        <Typography>Or</Typography>
                                        <Typography>Select individuals Company or Branches of <strong>"{item.client_name}"</strong> Reseller</Typography>
                                        <TreeSelect
                                            defaultValue={treeDataStore}
                                            treeData={treeData}
                                            title="Select"
                                            treeCheckable={true}
                                            showCheckedStrategy={SHOW_PARENT}
                                            placeholder='Please select companies and branches to share'
                                            style={{ width: '100%' }}
                                            onSelect={addSharing}
                                            onDeselect={removeSharing}
                                        />
                                    </Space>
                                }
                            </Space>
                        </Panel>
                    )}

                </Collapse>

            </PageHeader>
        </div >
    );
}