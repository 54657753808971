import React, { useState } from "react";
import { PageHeader } from "antd";
import { MyBreadcrumb } from "utils";
import IspForm from "./form";
export default function IspUpdate(props) {
  const { id } = props.match.params;

  const [pageTitle, setPageTitle] = useState(null);

  const handlePageTitleCallback = (title) => setPageTitle(title);

  return (
    <div>
      <MyBreadcrumb
        items={[
          { label: "ISP", path: "/Isp" },
          { label: "Update", path: "" },
        ]}
      />
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={`Update ${pageTitle}`}
        subTitle=""
      >
        <IspForm id={id} pageTitleCallback={handlePageTitleCallback} />
      </PageHeader>
    </div>
  );
}
