import { axiosBase } from 'utils';

export function categoriesListService() {
    const axios = axiosBase.create();
    return axios({
        method: 'GET',
        url: '/categories',
    });
}

export function categoriesParentListService() {
    const axios = axiosBase.create();
    return axios({
        method: 'GET',
        url: '/categories?onlyParent=true',
    });
}

export function categoriesViewListService() {
    const axios = axiosBase.create();
    return axios({
        method: 'GET',
        url: '/categories?master=true',
    });
}

export function categoriesListByIdService(id) {
    const axios = axiosBase.create();
    return axios({
        method: 'GET',
        url: '/categories/' + id,
    });
}

export function categoriesViewListByIdService(id) {
    const axios = axiosBase.create();
    return axios({
        method: 'GET',
        url: '/categories/' + id + '?master=true',
    });
}

export function categoriesCreateService(values) {
    const axios = axiosBase.create();
    return axios({
        method: 'POST',
        url: '/categories',
        data: {
            company: values.company,
            branch: values.branch,
            is_this_parent_category: (values.is_this_parent_category) ? 'Y' : 'N',
            parent: values.parent,
            type: values.type,
            name: values.name,
            slug: values.slug,
            description: values.description
        }
    });
}

export function categoriesShareSettingsService(action, clientId, categoryId, key, value) {
    const axios = axiosBase.create();
    return axios({
        method: 'POST',
        url: '/categories/share-settings',
        data: {
            action: action, // ADD | REMOVE
            clientId: clientId,
            categoryId: categoryId,
            key: key, // share_with_clients| share_with_companies_all | share_with_companies | share_with_branches
            value: value, // bool | []
        }
    });
}

export function categoriesUpdateService(id, values) {
    const axios = axiosBase.create();
    return axios({
        method: 'PATCH',
        url: '/categories/' + id,
        data: {
            company: values.company,
            branch: values.branch,
            is_this_parent_category: (values.is_this_parent_category) ? 'Y' : 'N',
            parent: values.parent,
            name: values.name,
            slug: values.slug,
            description: values.description
        }
    });
}

export function categoriesDeleteService(id) {
    const axios = axiosBase.create();
    return axios({
        method: 'DELETE',
        url: '/categories/' + id,
    });
}

export function categoriesSearchService(q) {
    const axios = axiosBase.create();
    if (q === "") {
        return axios({
            method: 'GET',
            url: '/categories',
        });
    } else {
        return axios({
            method: 'GET',
            url: '/categories/search?q=' + q,
        });
    }
}

