import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Space,
  Button,
  Form,
  Input,
  Divider,
  Select,
  Upload,
  message,
  Row,
  Col,
} from 'antd';
import {
  usersRoleService,
  usersCreateService,
  usersListByIdService,
  usersUpdateService,
} from 'services/user';
import { companiesListService } from 'services/company';
import { branchesListByCompanyIdService } from 'services/branch';

import { handleApiSuccess, handleApiError } from 'utils';
import Auth from 'auth';

import InputMask from 'react-input-mask';

import {
  LockOutlined,
  TeamOutlined,
  MobileOutlined,
  KeyOutlined,
  PhoneOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import Grid from 'antd/lib/card/Grid';

const layout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 8 },
};
const tailLayout = {
  wrapperCol: { offset: 3, span: 8 },
};

const { Option } = Select;

const _auth = Auth;

export default function UsersForm(props) {
  const { id } = props;

  const [photoUploading, setPhotoUploading] = useState(false);
  const [photoUrl, setPhotoUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();
  const [roles, setRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);
  const firstInput = useRef(null);

  const watchCompany = form.getFieldValue('company');

  useEffect(() => {
    firstInput.current.focus();
  }, []);

  useEffect(() => {
    // IF id exist the initialize form data
    if (id) {
      usersListByIdService(id)
        .then((response) => {
          setIsLoading(false);
          if (response.data.items && response.data.items.length > 0) {
            setPhotoUrl(response.data.items[0].photo);
            form.setFieldsValue(response.data.items[0]);
            // If company was set for category then pull list of branches
            if (response.data.items[0].company) {
              branchesListByCompanyIdService(response.data.items[0].company)
                .then((response) => {
                  setBranches(response.data.items);
                })
                .catch((error) => {
                  handleApiError(error);
                });
              //Load roles dropdown
              usersRoleService(response.data.items[0].company).then(
                (response) => {
                  if (response.data.items && response.data.items.length > 0) {
                    setRoles(response.data.items);
                  }
                },
              );
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }

    //Load companies dropdown
    companiesListService().then((response) => {
      if (response.data.items && response.data.items.length > 0) {
        setCompanies(response.data.items);
      }
    });
  }, [id, form]);

  useEffect(() => {
    //Load roles dropdown
    usersRoleService().then((response) => {
      if (response.data.items && response.data.items.length > 0) {
        setRoles(response.data.items);
      }
    });
  }, []);

  const onFinish = (values) => {
    setIsLoading(true);

    values.photo = photoUrl;

    // Update
    if (id) {
      usersUpdateService(id, values)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          history.push('/users');
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    } else {
      // Create
      usersCreateService(values)
        .then((response) => {
          setIsLoading(false);
          handleApiSuccess(response);
          history.push('/users');
        })
        .catch((error) => {
          setIsLoading(false);
          handleApiError(error);
        });
    }
  };

  const handleRoleChange = (value) => {
    form.setFieldsValue({ role: value });
  };

  const onCompanyChange = (value) => {
    usersRoleService(value).then((response) => {
      if (response.data.items && response.data.items.length > 0) {
        setRoles(response.data.items);
      }
    });
    branchesListByCompanyIdService(value)
      .then((response) => {
        setBranches(response.data.items);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const CellPhoneInput = (props) => (
    <InputMask
      mask="999-999-9999"
      value={props.value}
      onChange={props.onChange}
    >
      {(inputProps) => (
        <Input
          {...inputProps}
          style={{ width: '50%' }}
          prefix={<MobileOutlined />}
        />
      )}
    </InputMask>
  );

  const PhoneInput = (props) => (
    <InputMask
      mask="999-999-9999"
      value={props.value}
      onChange={props.onChange}
    >
      {(inputProps) => <Input {...inputProps} prefix={<PhoneOutlined />} />}
    </InputMask>
  );

  const handlePhotoChange = (info) => {
    if (info.file.status === 'uploading') {
      setPhotoUploading(true);
      return;
    }
    if (info.file.status === 'done') {
      setPhotoUploading(false);
      setPhotoUrl(info.file.response.location);
    }
  };

  const uploadButton = (
    <div>
      {photoUploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <Form name="myForm" form={form} {...layout} onFinish={onFinish}>
      <div>
        <div
          style={{
            backgroundColor: '#FAFAFA',
            border: '1px solid #EAEAEA',
            padding: '30px 5px 5px 5px',
            marginBottom: 30,
          }}
        >
          <Form.Item label="Company" name="company">
            <Select
              autoComplete="nope"
              showSearch
              placeholder="Select a company"
              optionFilterProp="children"
              onChange={(value) => onCompanyChange(value)}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">Select a company</Option>
              {companies.map((item, i) => (
                <Option value={item._id}>{item.company_name}</Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Branch" name="branch">
            <Select
              autoComplete="nope"
              showSearch
              placeholder="Select a branch"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">Select a branch</Option>
              {branches.map((item, i) => (
                <Option value={item._id}>{item.branch_name}</Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <Divider orientation="left">
          <LockOutlined /> Account Settings
        </Divider>

        <Space>&nbsp;</Space>

        <Form.Item
          label="Email/Username"
          name="email"
          rules={[
            { required: true, message: 'Please input email address' },
            { type: 'email', message: 'Please input valid email address' },
          ]}
        >
          <Input ref={firstInput} />
        </Form.Item>

        {!id && (
          <>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please input Password' }]}
            >
              <Input type="password" />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirm_password"
              dependencies={['password']}
              rules={[
                { required: true, message: 'Please input Confirm password' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      'The two passwords that you entered do not match!',
                    );
                  },
                }),
              ]}
            >
              <Input type="password" />
            </Form.Item>
          </>
        )}
      </div>

      <Divider orientation="left">
        <KeyOutlined /> Account Role Settings
      </Divider>
      <Form.Item label="Role" name="role">
        <Select onChange={handleRoleChange} listHeight={500}>
          {roles.map((item, i) => {
            return (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item label="Is AP Manager?" name="is_ap_manager">
        <Select
          autoComplete="nope"
          showSearch
          style={{ width: 200 }}
          placeholder="Select"
          optionFilterProp="children"
          defaultValue={'NO'}
        >
          <Option value="YES">Yes</Option>
          <Option value="NO">No</Option>
        </Select>
      </Form.Item>

      <Divider orientation="left">
        <TeamOutlined /> Profile Settings
      </Divider>

      <Space>&nbsp;</Space>

      <Row>
        <Col span="3" className="ant-form-item-label">
          <label>Photo</label>
        </Col>
        <Col span="8">
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={
              process.env.REACT_APP_API_BASE_URL +
              '/users/upload-photo?token=' +
              _auth.getToken()
            }
            beforeUpload={beforeUpload}
            onChange={handlePhotoChange}
          >
            {photoUrl && !photoUploading ? (
              <img src={photoUrl} alt="avatar" style={{ width: '100%' }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Col>
      </Row>
      <Space>&nbsp;</Space>

      <Form.Item label="Title" name="title">
        <Input />
      </Form.Item>

      {/*
            <Form.Item label="Title" name="title">
                <Select
                    autoComplete="title"
                    showSearch
                    style={{ width: 200 }}
                    placeholder="Select a title"
                    onChange={(value) => {
                        form.setFieldsValue({ title: value })
                    }}
                >
                    <Option value="">Select a title</Option>
                    <Option value="Owner">Owner</Option>
                    <Option value="Developer">Application Developer</Option>
                    <Option value="Designer">Designer</Option>
                    <Option value="Marketer">Internet Marketer</Option>
                    <Option value="Consultant">Consultant</Option>
                    <Option value="Other">Other</Option>
                </Select>
            </Form.Item>
            */}

      <Form.Item label="Notes" name="notes">
        <Input.TextArea />
      </Form.Item>

      <Form.Item
        label="Display Name"
        name="display_name"
        rules={[{ required: true, message: 'Please input display name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="First Name"
        name="first_name"
        rules={[{ required: true, message: 'Please input first name' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Last Name"
        name="last_name"
        rules={[{ required: true, message: 'Please input last name' }]}
      >
        <Input />
      </Form.Item>

      <Divider orientation="left">
        <MobileOutlined /> Contact settings
      </Divider>
      <Space>&nbsp;</Space>

      <Form.Item label="Cell Phone" extra="Cell Phone number">
        <Form.Item name={['cell_phone']} noStyle>
          <CellPhoneInput placeholder="Phone" />
        </Form.Item>
      </Form.Item>

      <Form.Item label="Office Phone" name="phone">
        <PhoneInput />
      </Form.Item>

      <Form.Item label="Ext" name="ext">
        <Input />
      </Form.Item>

      <Form.Item label="Fax" name="fax">
        <PhoneInput />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" loading={isLoading} htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt8M = file.size / 1024 / 1024 < 8;
  if (!isLt8M) {
    message.error('Image must smaller than 8MB!');
  }
  return isJpgOrPng && isLt8M;
}
