import { axiosBase } from 'utils';

export function workspaceUploadListService(id, object_type) {
  const axios = axiosBase.create();
  return axios({
    method: 'GET',
    url: `/workspace-upload/${id}?object_type=${object_type}`,
  });
}

export function workspaceUploadSaveService(values) {
  const axios = axiosBase.create();
  return axios({
    method: 'POST',
    url: '/workspace-upload',
    data: {
      _id: values._id,
      title: values.title,
      object_type: values.object_type,
      description: values.description,
    },
  });
}

export function workspaceUploadDeleteService(id) {
  const axios = axiosBase.create();
  return axios({
    method: 'DELETE',
    url: '/workspace-upload/' + id,
  });
}

export function workspaceUploadGetOneService(id) {
  const axios = axiosBase.create();
  return axios({
    method: 'GET',
    url: '/workspace-upload/read/' + id,
  });
}
